import { Chip } from '@mui/material'
export interface IBoolChipProps {
  boolCheck: boolean
  labelSuccess: any
  labelError: any
  size?: 'medium' | 'small'
}
export const BoolChip = ({
  boolCheck,
  labelSuccess,
  labelError,
  size = 'small'
}: IBoolChipProps) => {
  return boolCheck ? (
    <Chip size={size} label={labelSuccess} color='success' />
  ) : (
    <Chip size={size} label={labelError} color='error' />
  )
}
