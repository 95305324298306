import Grid from '@mui/material/Unstable_Grid2'
import { PriceTrendCharts } from './PriceTrendCharts'
import { PriceTrendTable } from './PriceTrendTable'
import { useProductPriceTrend } from '../../useProductPriceTrend'

interface IProductOverviewPriceTrendProps {
  stalId: string
}
export const ProductOverviewPriceTrend = ({ stalId }: IProductOverviewPriceTrendProps) => {
  const { loading, priceTrend } = useProductPriceTrend(stalId)
  return (
    <Grid container>
      <Grid xs={12}>
        <PriceTrendTable data={priceTrend} loading={loading} />
      </Grid>
      <Grid xs={12}>
        <PriceTrendCharts />
      </Grid>
    </Grid>
  )
}
