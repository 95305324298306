import { TextFieldProps } from '@mui/material'

export const fieldProps = (label: string, ref: any, field: any): TextFieldProps => ({
  fullWidth: true,
  margin: 'normal',
  size: 'small',
  label: label,
  inputRef: ref,
  ...field
})
