import { GridColDef } from '@mui/x-data-grid'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MiDatagrid } from '../../components/material/MiDatagrid'
import { useData } from '../../services/useData'
import { ProductCategoryPriceSuggestion } from '../../models/product/ProductCategoryPriceSuggestions'

export interface IProductProfitSuggerstionsTableProps {
  columns: GridColDef[]
  setSelectedEntity: any
}

export const ProductProfitSuggerstionsTable = ({
  columns,
  setSelectedEntity
}: IProductProfitSuggerstionsTableProps) => {
  const { loading, data } = useData<ProductCategoryPriceSuggestion>('ProductCategoryPriceSuggestion')
  const { t } = useTranslation()

  return (
    <>
      <MiDatagrid
        title={t('labels.suggestedSellingPrice')}
        actions={
          <Button
            color={'primary'}
            size='small'
            variant='outlined'
            onClick={() => setSelectedEntity(new ProductCategoryPriceSuggestion())}
          >
            {t('buttons.add')}
          </Button>
        }
        data={data}
        columns={columns}
        loading={loading}
      />
    </>
  )
}
