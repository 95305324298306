import { Control, Controller, FieldErrors } from 'react-hook-form'
import { Stack, Switch, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { fieldProps } from '../../../../utils/FormExtensions'
import { UserDto } from '../../../models/User'

export interface IUserDetailsProps {
  user?: UserDto
  errors: FieldErrors<UserDto>
  control: Control<UserDto, any>
}
export const UserDetails = ({ control, user, errors }: IUserDetailsProps) => {
  const { t } = useTranslation()

  return (
    <Stack padding={'1rem'} spacing={2}>
      <Controller
        control={control}
        defaultValue=''
        name='lastname'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.lastname'), ref, field)} error={!!errors.lastname} />
        )}
      />
      <Controller
        control={control}
        defaultValue=''
        name='name'
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.name'), ref, field)} error={!!errors.name} />
        )}
      />

      <Controller
        control={control}
        defaultValue=''
        name='email'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.email'), ref, field)} error={!!errors.email} />
        )}
      />
      <Controller
        control={control}
        defaultValue={false}
        name='isActive'
        render={({ field: { ref, onChange, value } }) => (
          <Stack flex={1} direction='row' spacing={1} alignItems='center'>
            <Typography>{t('labels.accountStatus')}</Typography>
            <Switch
              checked={value}
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Stack>
        )}
      />
    </Stack>
  )
}
