import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import { ProductEditDto } from '../../models/product/Product'
import { useConfig } from '../../services/useConfig'
import { BaseResult } from '../../models/BaseResult'
import { ProductImageDto } from '../../models/product/ProductImage'
import axios from 'axios'
import { useValidator } from '../../../utils/useValidator'

export interface IUseProductProps {
  productLoader: boolean
  product?: ProductEditDto
  createProduct: any
  updateProduct: any
  uploadImage: (image: Blob, id: string) => void
  removeImage: (imageId: number) => void
  deleteProduct: (id: string) => Promise<boolean>
}

export function useProduct(id?: string): IUseProductProps {
  const { config } = useConfig()
  const [productLoader, setLoading] = useState(false)
  const [product, setProduct] = useState<ProductEditDto>()
  const service = useBaseService('product')
  const { isGuid } = useValidator()
  const navigate = useNavigate()

  const removeImage = useCallback(
    async (imageId: number) => {
      setLoading(true)
      const images = product?.images.filter((x) => x.id !== imageId)
      setProduct({ ...product, images: images } as any)
      setLoading(false)
    },
    [product]
  )

  const updateProduct = useCallback(
    async (product: ProductEditDto) => {
      setLoading(true)
      const response = await service.update<ProductEditDto>(product, product.id)
      if (response) setProduct(response)
      setLoading(false)
    },
    [service]
  )

  const deleteProduct = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await service.remove(id)
      setLoading(false)
      return response === true
    },
    [service]
  )

  const uploadImage = useCallback(
    async (image: Blob, id: string) => {
      if (!image) return

      const formData = new FormData()
      formData.append('file', image)

      try {
        var response = await axios.post<BaseResult<ProductImageDto>>(
          `${config.apiUrl}product/upload/${id}`,
          formData
        )
        if (response.data.data) {
          setProduct({
            ...product,
            images: [...(product?.images as any), response.data.data]
          } as any)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [config.apiUrl, product]
  )

  useEffect(() => {
    if (id !== 'new' && !isGuid(id as string)) {
      navigate('/manager/products')
      return
    }
    if (!id || id === 'new') {
      setProduct(new ProductEditDto())
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<ProductEditDto>(id)
      if (response) setProduct(response)
      else navigate('/manager/products')
      setLoading(false)
    }
    getById(id)
  }, [id, isGuid, navigate, service, setLoading])

  const createProduct = useCallback(
    async (product: ProductEditDto) => {
      setLoading(true)
      const response = await service.create(product)
      if (response) {
        setProduct(response)
        navigate(`/manager/products/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    productLoader,
    product,
    uploadImage,
    updateProduct,
    createProduct,
    deleteProduct,
    removeImage
  }
}
