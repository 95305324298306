import { useCallback, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { IProductFilters, ProductEditDto, ProductTableDto } from '../../models/product/Product'

export interface IUseProducts {
  loading: boolean
  products: ProductTableDto[]
  searchWithFilters: (filters: IProductFilters) => void
  awaitSearchByCode: (code: string, resultType?: string) => Promise<ProductEditDto[] | null>
  removeProduct: (id: string) => void
}

export function useProducts(): IUseProducts {
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<ProductTableDto[]>([])
  const service = useBaseService('product')

  const removeProduct = (id: string) => {
    setProducts(products?.filter((x) => x.id !== id))
  }

  const searchWithFilters = useCallback(
    async (filters: IProductFilters) => {
      setLoading(true)
      const response = await service.post<ProductTableDto[]>(filters, `/search/filters`)
      if (response) setProducts(response)
      setLoading(false)
    },
    [service]
  )

  const awaitSearchByCode = useCallback(
    async (code: string, resultType: string = 'details') => {
      setLoading(true)
      const response = await service.getAll<ProductEditDto>(`search/${resultType}/${code}`)
      setLoading(false)
      return response ?? null
    },
    [service]
  )

  return {
    loading,
    products,
    removeProduct,
    awaitSearchByCode,
    searchWithFilters
  }
}
