import { SupplierDto } from '../../../../../models/Supplier'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { SimpleSelect } from '../../../../../components/lookupSelect/SimpleSelect'
import { InvoicePurchase } from '../../../../../models/invoice/InvoicePurchase'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'

export interface IInvoicePurchaseSupplierDetailsProps {
  control: Control<InvoicePurchase, any>
  errors: any
  loading: boolean
  data: SupplierDto[]
  isDisabled: boolean
}
export const InvoicePurchaseSupplierDetails = ({
  control,
  errors,
  data,
  loading,
  isDisabled
}: IInvoicePurchaseSupplierDetailsProps) => {
  const { t } = useTranslation()

  return (
    <Grid2 container alignItems={'flex-start'} padding={2} paddingTop={0}   spacing={2}>
      <Grid2 container xs={6} spacing={2}>
        <Grid2 xs={12}>
          <Controller
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            name='invoiceNumber'
            render={({ field: { ref, ...field } }) => (
              <TextField
                inputRef={ref}
                id='invoiceNumber'
                {...field}
                fullWidth
                disabled={isDisabled}
                size='small'
                error={!!errors.invoiceNumber}
                label={t('labels.invoiceNumber')}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12}>
          <SimpleSelect
            name='supplierId'
            control={control}
            data={data.map((x) => ({ id: x.id, valueEn: x.name, valueGr: x.name }))}
            errors={errors}
            disabled={isDisabled}
            label={t('labels.supplier')}
            loading={loading}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Controller
            control={control}
            defaultValue={0}
            rules={{ required: true }}
            name='shippingCosts'
            render={({ field: { ref, ...field } }) => (
              <TextField
                inputRef={ref}
                id='shippingCosts'
                type='number'
                {...field}
                fullWidth
                disabled={isDisabled}
                size='small'
                label={t('labels.shippingCosts')}
              />
            )}
          />
        </Grid2>
      </Grid2>
      <Grid2 container xs={6} spacing={2}>
        <Grid2 xs={12}>
          <Controller
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            name='invoiceDate'
            render={({ field: { onChange, value } }) => (
              <DatePicker
                label={t('labels.date')}
                value={dayjs(value)}
                disabled={isDisabled}
                format={'DD/MM/YYYY'}
                onChange={(newValue: any) => {
                  onChange(newValue.$d instanceof Date && !isNaN(newValue.$d) ? newValue.$d : null)
                }}
                slotProps={{
                  textField: { size: 'small', fullWidth: true, error: !!errors.invoiceDate }
                }}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Controller
            control={control}
            defaultValue={new Date()}
            name='created'
            render={({ field: { value } }) => (
              <DateTimeField
                disabled={true}
                label={t('labels.createdAt')}
                value={dayjs(value)}
                format={'DD/MM/YYYY: HH:mm'}
                fullWidth
                size='small'
                style={{ flex: '0 1 30%' }}
              />
            )}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  )
}
