export class ProductCategoryPriceSuggestion {
  id: string
  created: Date
  lastUpdated: Date
  profitMarginToBeAdded: number
  isSoftDeleted: boolean
  productTypeId: string
  productTypeNameEn: string
  productTypeNameGr: string
}
