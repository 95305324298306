import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Switch,
  Typography
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { fieldProps } from '../../../utils/FormExtensions'
import { Loader } from '../../components/loader/loader'
import { ProductCategoryPriceSuggestion } from '../../models/product/ProductCategoryPriceSuggestions'
import { useProductCategoryPriceSuggestion } from './useProductCategoryPriceSuggestion'
import { SimpleSelect } from '../../components/lookupSelect/SimpleSelect'
import { useLookup } from '../../services/useLookup'

export interface IProductProfitSuggerstionsModalProps {
  entity: ProductCategoryPriceSuggestion
  onConfirm: any
  onCancel: any
}
export const ProductProfitSuggerstionsModal = ({
  onCancel,
  onConfirm,
  entity
}: IProductProfitSuggerstionsModalProps) => {
  const { t } = useTranslation()
  const title = entity?.id ? 'editRecord' : 'newRecord'
  const { loading, lookup } = useLookup('producttype', 'lookup/view')
  const { create, loader, update } = useProductCategoryPriceSuggestion()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ProductCategoryPriceSuggestion>({
    defaultValues: entity
  })

  const sumbitForm = async (data: ProductCategoryPriceSuggestion) => {
    if (entity.id) {
      await update(data)
    } else {
      await create(data)
    }
    onConfirm()
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle id='alert-dialog-title'>{t('labels.' + title)}</DialogTitle>
      <DialogContent style={{ paddingTop: '10px' }}>
        {loader && <Loader type='circles' />}
        {!loader && (
          <>
            <SimpleSelect
              name='productTypeId'
              control={control}
              data={lookup}
              errors={errors}
              label={t('labels.productType')}
              loading={loading}
            />
            <Controller
              control={control}
              defaultValue={0}
              name='profitMarginToBeAdded'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.profitMargin'), ref, field)}
                  error={!!errors.profitMarginToBeAdded}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={false}
              name='isSoftDeleted'
              render={({ field: { onChange, value } }) => (
                <Stack direction='row' spacing={1} flex={1} alignItems='center'>
                  <Typography>{t('labels.isSoftDeleted')}</Typography>
                  <Switch
                    checked={value}
                    onChange={onChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Stack>
              )}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        {!loader && (
          <Button color='success' onClick={handleSubmit(sumbitForm)} autoFocus>
            {t('buttons.insert')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
