import Grid from '@mui/material/Unstable_Grid2'
import { IInvoicePurchasePagination } from '../../../models/invoice/InvoiceSale'
import { useInvoicesPurchasesPaginated } from '../useInvoicesPurchasesPaginated'
import { useEffect } from 'react'
import { InvoicePurchaseTableFilters } from './InvoicePurchaseTableFilters'
import { InvoicePurchaseTable } from './InvoicePurchaseTable'
import { useForm } from 'react-hook-form'

const defaultReq: IInvoicePurchasePagination = {
  page: 1,
  size: 10,
  supplierId: '',
  invoiceNumber: '',
  from: undefined,
  to: undefined
}

export const InvoicePurchasesContainer = () => {
  const { loading, searchInvoices, invoiceState } = useInvoicesPurchasesPaginated()

  const {
    control,
    watch,
    reset,
    getValues,
    formState: { errors }
  } = useForm<IInvoicePurchasePagination>({
    defaultValues: defaultReq,
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (['supplierId', 'from', 'to'].some((x) => x === name)) {
        searchInvoices(getValues())
      }
    })
    return () => subscription.unsubscribe()
  }, [getValues, searchInvoices, watch])

  useEffect(() => {
    searchInvoices(getValues())
  }, [getValues, searchInvoices])

  const clearFilters = () => {
    reset(defaultReq)
    searchInvoices(getValues())
  }

  const onEnterPushed = (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.value.length < 4) return
      searchInvoices(getValues())
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <InvoicePurchaseTableFilters
          control={control}
          errors={errors}
          onEnterPushed={onEnterPushed}
          clearFilters={clearFilters}
        />
      </Grid>
      <Grid xs={12}>
        <InvoicePurchaseTable loading={loading} invoiceState={invoiceState} />
      </Grid>
    </Grid>
  )
}
