import { Stack, TextField } from '@mui/material'
import { DateTimeField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { FieldErrors, Control, UseFormWatch, UseFormSetValue, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LookupSelect } from '../../../../../components/lookupSelect/LookupSelect'
import { SimpleSelect } from '../../../../../components/lookupSelect/SimpleSelect'
import { InvoiceEditDto } from '../../../../../models/invoice/InvoiceSale'
import { useLookup, ISimpleLookup } from '../../../../../services/useLookup'

export interface IInvoiceParastatikoDetailsProps {
  errors: FieldErrors<InvoiceEditDto>
  control: Control<InvoiceEditDto, any>
  watch: UseFormWatch<InvoiceEditDto>
  setValue: UseFormSetValue<InvoiceEditDto>
  disableForm: boolean
  paymentMethods: any[]
}

export const InvoiceParastatikoDetails = ({
  errors,
  control,
  watch,
  setValue,
  disableForm,
  paymentMethods
}: IInvoiceParastatikoDetailsProps) => {
  const { t } = useTranslation()
  const { loading, lookup } = useLookup<ISimpleLookup>('protocol', 'lookup/view')

  useEffect(() => {
    if (!lookup) return
    const subscription = watch((value, { name }) => {
      if (name === 'protocolId') {
        const protocol: any = lookup.find((x) => x.id === value.protocolId)
        setValue('protocolNumber', protocol.number)
      }
    })
    return () => subscription.unsubscribe()
  }, [lookup, setValue, watch])

  return (
    <Stack
      padding={'1rem'}
      direction='column'
      justifyContent='space-between'
      alignItems='start'
      spacing={2}
    >
      <LookupSelect
        lookupUrl='lookup/invoices'
        disabled={disableForm}
        control={control}
        path={'invoice'}
        label={t('labels.invoiceType')}
        name='typeId'
        errors={errors}
      />
      <SimpleSelect
        control={control}
        disabled={disableForm}
        data={lookup}
        loading={loading}       
        label={t('labels.invoicePublishLine')}
        name='protocolId'
        errors={errors}
      />
      <Controller
        control={control}
        defaultValue={''}
        name='invoiceDate'
        render={({ field: { onChange, value } }) => (
          <DateTimeField
            disabled={disableForm}
            label={t('labels.date')}
            value={dayjs(value)}
            onChange={(date) => {
              onChange(date ? date : '')
            }}
            format={'MM/DD/YYYY: HH:mm'}
            fullWidth
            size='small'
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={''}
        name='protocolNumber'
        render={({ field: { ref, ...field } }) => (
          <TextField
            type='number'
            inputRef={ref}
            {...field}
            disabled
            fullWidth
            size='small'
            error={!!errors.protocolNumber}
            label={t('labels.invoiceNumber')}
          />
        )}
      />
      <SimpleSelect
        label={t('labels.paymentMethod')}
        control={control}
        disabled={disableForm}
        data={paymentMethods}
        name='paymentMethod'
        loading={loading}
        errors={errors}
      />
    </Stack>
  )
}
