import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import { useBaseService } from '../../../services/useBaseService'
import { HighlightOff, HideImage } from '@mui/icons-material'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'
import { useState } from 'react'

const ProductImages = ({ productId, images, removeImage }: any) => {
  const service = useBaseService('product')
  const [loader, setLoader] = useState(false)
  const [selectedEntity, setSelectedEntity] = useState<any | null>(null)

  const deleteImage = async () => {
    setLoader(true)
    await service.remove(selectedEntity.id, `product/image/${productId}/${selectedEntity.id}`)
    setLoader(false)
    removeImage(selectedEntity.id)
    setSelectedEntity(null)
  }

  if (productId === 'new') return null
  return (
    <Paper elevation={1} style={{ marginTop: 20 }}>
      <ConfirmationModal
        open={selectedEntity != null}
        title={'Διαγραφή Φωτογραφίας'}
        description={`Θέλετε σίγουρα να διαγράψετε την φωτογραφία;`}
        onCancel={() => setSelectedEntity(null)}
        onConfirm={deleteImage}
        loading={loader}
      />
      {images?.length === 0 && (
        <Stack alignItems={'center'} padding={3}>
          <HideImage style={{ fontSize: '64px' }} />
          <Typography variant='body2'>No Images</Typography>
        </Stack>
      )}
      {images?.length > 0 && (
        <ImageList
          cols={1}
          rowHeight={164}
          style={{ paddingTop: 10, paddingBottom: 30, overflow: 'hidden' }}
        >
          {images?.map((item: any, index: number) => (
            <ImageListItem key={index}>
              <img
                style={{ height: '100%', objectFit: 'contain' }}
                src={`data:image/jpeg;base64,${item.imageData}`}
                alt={'noimage'}
              />
              <ImageListItemBar
                sx={{ background: 'unset' }}
                title={item.title}
                position='top'
                actionIcon={
                  <IconButton
                    sx={{ color: '#c94141' }}
                    aria-label={`star ${item.title}`}
                    onClick={() => setSelectedEntity(item)}
                  >
                    <HighlightOff />
                  </IconButton>
                }
                actionPosition='right'
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Paper>
  )
}

export default ProductImages
