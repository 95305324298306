import { Navigate } from 'react-router-dom'
import { useToken } from './useToken'

interface PrivateRouteProps {
  component: React.ComponentType<any>
}

export const PrivateRoute = ({ component: Component, ...props }: PrivateRouteProps) => {
  var isTokenExpired = useToken().isTokenExpired()
  if (isTokenExpired) return <Navigate to='/manager/login' replace />
  return <Component {...props} />
}
