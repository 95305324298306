import {
  List,
  ListItem,
  IconButton,
  ListItemText,
  ListItemIcon,
  Stack,
  Typography,
  ButtonGroup
} from '@mui/material'
import { AddressDto, AddressType, CustomerDetailsDto } from '../../../models/Customer'
import {
  ModeEdit,
  Home,
  Work,
  AccountBalance,
  OtherHouses,
  Add,
  DeleteOutline
} from '@mui/icons-material'
import { t } from 'i18next'
import { AddressModal } from './AddressModal'
import { useState } from 'react'
import { Control, useFieldArray } from 'react-hook-form'
import { useLookup } from '../../../services/useLookup'
import { ICountryLookup } from '../../../models/Country'
import { Loader } from '../../../components/loader/loader'

export interface IAddressListProps {
  control: Control<CustomerDetailsDto, any>
}

export const addressTypeIcon = (type: number) => {
  switch (type) {
    case AddressType.Home:
      return <Home fontSize={'small'} />
    case AddressType.Work:
      return <Work fontSize={'small'} />
    case AddressType.Logistics:
      return <AccountBalance fontSize={'small'} />
    default:
      return <OtherHouses fontSize={'small'} />
  }
}

export const AddressList = ({ control }: IAddressListProps) => {
  const [address, setAddress] = useState<AddressDto | null>(null)
  const { loading, lookup: countries } = useLookup<ICountryLookup>('lookup', 'countries')
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'addresses'
  })

  const addOrUpdateEntity = (add: AddressDto) => {
    setAddress(null)
    var matchedCountry = countries.find((c) => c.id === add.countryId)
    add.countryGr = matchedCountry?.nameGr || ''
    add.countryEn = matchedCountry?.nameEn || ''
    add.countryCode = matchedCountry?.code || ''
    if (add.id) {
      const i = fields.findIndex((f) => f.id === add.id)
      update(i, add)
    } else append(add)
  }

  const removeEntity = (id: string) => {
    const i = fields.findIndex((f) => f.id === id)
    remove(i)
  }
  if (loading) return <Loader type={'circles'} />

  return (
    <>
      {address != null && (
        <AddressModal
          entity={address}
          onConfirm={addOrUpdateEntity}
          onCancel={() => setAddress(null)}
        />
      )}
      <Stack direction={'row'} alignItems={'center'}>
        <Typography flex={1} variant='body1' color={'grey'}>
          {t('labels.addresses')}
        </Typography>
        <Stack>
          <IconButton color='primary' onClick={() => setAddress(new AddressDto())}>
            <Add />
          </IconButton>
        </Stack>
      </Stack>
      <List dense sx={{ MuiListItemSecondaryAction: '0' }}>
        {fields?.map((value, i) => {
          const labelId = `checkbox-list-secondary-label-${i}`
          return (
            <ListItem
              sx={{
                '.MuiListItemSecondaryAction-root': {
                  right: '0px'
                },
                '.MuiListItemIcon-root': {
                  minWidth: '30px!important'
                }
              }}
              key={i}
              secondaryAction={
                <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
                  <IconButton color='primary' onClick={() => setAddress(value)}>
                    <ModeEdit fontSize={'small'} />
                  </IconButton>
                  <IconButton color='error' onClick={() => removeEntity(value.id)}>
                    <DeleteOutline fontSize={'small'} />
                  </IconButton>
                </ButtonGroup>
              }
              disablePadding
            >
              <ListItemIcon>{addressTypeIcon(value.type)}</ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.countryGr} - ${value.region} - ${value.city} `}
                secondary={`${value.street}, ${value.postalCode}`}
              />
            </ListItem>
          )
        })}
      </List>
    </>
  )
}
