import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SimpleSelect } from '../../../components/lookupSelect/SimpleSelect'
import { ISimpleLookup, useLookup } from '../../../services/useLookup'

export interface IProductPropertiesModalProps {
  entity?: any
  onConfirm: any
  onCancel: any
}
export const ProductPropertiesModal = ({
  onCancel,
  onConfirm,
  entity
}: IProductPropertiesModalProps) => {
  const { t } = useTranslation()
  const title = entity?.id ? 'productKey' : 'productKey'
  const { loading, lookup } = useLookup<ISimpleLookup>('productKey', 'lookup/view')
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    defaultValues: entity
  })
  const sumbitForm = (data: any) => {
    let label = lookup.find((x) => x.id === data.productKeyId)
    onConfirm({ ...data, labelEn: label?.valueEn, labelGr: label?.valueGr })
  }
  return (
    <Dialog open={true} fullWidth>
      <DialogTitle id='alert-dialog-title'>{t('labels.' + title)}</DialogTitle>
      <DialogContent style={{ paddingTop: '0.5rem' }}>
        {control && (
          <SimpleSelect
            control={control}
            data={lookup}
            loading={loading}
            margin='normal'
            label={t('labels.productKey')}
            name='productKeyId'
            errors={errors}
          />
        )}
        <Controller
          control={control}
          defaultValue=''
          name='productKeyValue'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField
              inputRef={ref}
              margin='normal'
              {...field}
              size='small'
              fullWidth
              error={!!errors.productKeyValue}
              label={t('labels.value')}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        <Button color='success' onClick={handleSubmit(sumbitForm)} autoFocus>
          {t('buttons.insert')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
