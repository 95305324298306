import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import { UserDto } from '../../models/User'

export interface IUserProps {
  userLoader: boolean
  user?: UserDto
  createUser: any
  updateUser: any
  deleteUser: (id: string) => Promise<boolean>
}

export function useUser(id?: string): IUserProps {
  const [userLoader, setLoading] = useState(false)
  const [user, setUser] = useState<UserDto>()
  const service = useBaseService('user')
  const navigate = useNavigate()

  const updateUser = useCallback(
    async (warehouse: UserDto) => {
      setLoading(true)
      const response = await service.update<UserDto>(warehouse, warehouse.id)
      if (response) setUser(response)
      setLoading(false)
    },
    [service]
  )

  const deleteUser = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await service.remove(id)
      setLoading(false)
      return response === true
    },
    [service]
  )

  useEffect(() => {
    if (!id || id === 'new') {
      setUser({} as any)
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<UserDto>(id)
      if (response) setUser(response)
      setLoading(false)
    }
    getById(id)
  }, [id, service, setLoading])

  const createUser = useCallback(
    async (user: UserDto) => {
      setLoading(true)
      const response = await service.create(user)
      if (response) {
        setUser(response)
        navigate(`/manager/users/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    userLoader,
    user,
    updateUser,
    createUser,
    deleteUser
  }
}
