import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInvoiceItem } from '../../../useInvoiceItem'
import { ProductStatusChip } from '../../../../../../utils/ProductStatus'
import { UseFormGetValues } from 'react-hook-form'
import { InvoiceEditDto, InvoiceItemDto } from '../../../../../models/invoice/InvoiceSale'
import { generateGuid } from '../../../../../../utils/useValidator'
import { makeStyles } from 'tss-react/mui'

export interface InvoiceItemSearchProps {
  append: any
  getValues: UseFormGetValues<InvoiceEditDto>
}

const useStyles = makeStyles()(() => {
  return {
    item: {
      width: '100px',
      display: 'inline-block'
    },
    itemlg: {
      width: '180px',
      display: 'inline-block'
    },
    selected: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& span': {
        marginRight: 10
      }
    }
  }
})

export const InvoiceItemSearch = ({ append, getValues }: InvoiceItemSearchProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { loader, searchProductToAdd, productItems, clearItems } = useInvoiceItem()

  const [selectedInvetory, setSelectedInvetory] = useState('')

  const clearSearchedItems = () => {
    clearItems()
  }

  const addItem = () => {
    const invoice = getValues()
    const item = productItems?.find((x) => x.inventoryId === selectedInvetory) as InvoiceItemDto
    if (!!item) clearSearch()

    if (item.quantity > 0) {
      item.quantity = 1
    }
    item.discountAmount = 0
    item.discountPercentage = 0
    item.priceAfterDiscount = item.unitSellingPrice
    item.taxValue = invoice.taxApplied ?? 24
    item.id = generateGuid()
    append(item)
  }

  const clearSearch = () => {
    setSelectedInvetory('')
    clearSearchedItems()
  }

  const onKeyPushed = (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.value.length === 0) {
        return
      }
      searchProductToAdd(e.target.value)
    }
  }

  return productItems == null || productItems?.length === 0 ? (
    <Stack direction={'row'} alignContent={'center'} alignItems={'center'}>
      <Stack>
        <TextField
          label={t('labels.search')}
          margin='none'
          fullWidth
          name='stalSearch'
          size='small'
          onKeyDown={onKeyPushed}
          InputProps={{
            endAdornment: <>{loader ? <CircularProgress color='inherit' size={20} /> : null}</>
          }}
        />
      </Stack>
    </Stack>
  ) : (
    <Stack direction={'row'} alignContent={'center'} alignItems={'center'}>
      <FormControl fullWidth size='small' margin='none'>
        <InputLabel id='items-search-result'>{t('labels.products')}</InputLabel>
        <Select
          labelId='items-search-result'
          id='items-search-result'
          label={t('labels.products')}
          value={selectedInvetory}
          renderValue={(value: any) => {
            var item = productItems.find((x) => x.inventoryId === value) as InvoiceItemDto
            return (
              <div className={classes.selected}>
                <span>{item.stalCode}</span>
                <span>{item.productCode}</span>
                <span>
                  {item.quantity} ({t('labels.piece')})
                </span>
                <span>{item.unitSellingPrice} &euro; </span>
                <span>{item.productWarehouse}</span>
                <span>{item.productShelf}</span>
                <span>{item.descriptionEn}</span>
                <span>{item.productManufacturer}</span>

                <ProductStatusChip status={item.productStatus} size={'small'} />
              </div>
            )
          }}
          defaultValue={''}
          onChange={(e) => setSelectedInvetory(e.target.value)}
        >
          {productItems.map((item, i: number) => (
            <MenuItem key={i} value={item.inventoryId}>
              <span className={classes.item}>{item.stalCode}</span>
              <span className={classes.item}> {item.productCode}</span>
              <span className={classes.item}>
                {item.quantity} ({t('labels.piece')})
              </span>
              <span className={classes.item}>{item.unitSellingPrice} &euro; </span>
              <span className={classes.itemlg}>{item.productWarehouse}</span>
              <span className={classes.item}>{item.productShelf}</span>
              <span className={classes.itemlg}> {item.descriptionEn}</span>
              <span className={classes.itemlg}> {item.productManufacturer}</span>

              <ProductStatusChip status={item.productStatus} size={'small'} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack>
        <Button
          variant='outlined'
          color='secondary'
          style={{ height: '40px', marginLeft: 10 }}
          onClick={() => clearSearchedItems()}
        >
          {t('buttons.cancel')}
        </Button>
      </Stack>
      <Stack>
        <Button
          variant='contained'
          color='primary'
          style={{ height: '40px', marginLeft: 10 }}
          onClick={addItem}
        >
          {t('buttons.add')}
        </Button>
      </Stack>
    </Stack>
  )
}
