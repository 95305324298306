import { useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { ProductKeyDto } from '../../models/product/ProductKey'

export interface IUseProductKeys {
  loading: boolean
  productKeys: ProductKeyDto[]
  removeProductKey: (id: string) => void
}

export function useProductKeys(): IUseProductKeys {
  const [loading, setLoading] = useState(true)
  const [productKeys, setProductKeys] = useState<ProductKeyDto[]>([])
  const service = useBaseService('productKey')

  const removeProductKey = (id: string) => {
    setProductKeys(productKeys?.filter((x) => x.id !== id))
  }
  useEffect(() => {
    if (!service) return

    async function fetchProductKeys() {
      setLoading(true)

      const response = await service.getAll<ProductKeyDto>()
      if (response) setProductKeys(response)
      setLoading(false)
    }

    fetchProductKeys()
  }, [service])

  return {
    loading,
    productKeys,
    removeProductKey
  }
}
