import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { commonColors } from '../../../utils/useManagerTheme'
import { useTranslation } from 'react-i18next'

export interface ISimpleSelectProps {
  errors: FieldErrors<any>
  control: Control<any, any>
  label: string
  data: any[]
  loading?: boolean
  name: string
  margin?: string
  disabled?: boolean
}

export const SimpleSelect = ({
  control,
  errors,
  data,
  name,
  loading = false,
  margin = 'none',
  label,
  disabled = false
}: ISimpleSelectProps) => {
  const { i18n } = useTranslation()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={''}
      rules={{ required: true }}
      render={({ field }) => (
        <FormControl size='small' fullWidth style={{ margin: margin }}>
          <InputLabel id={label + '_id'}>{label}</InputLabel>
          {data && (
            <Select
              {...field}
              fullWidth
              error={!!errors[name]}
              label={label}
              disabled={loading || disabled}
            >
              {data.map((l: any, index: number) => (
                <MenuItem key={index} value={l.id}>
                  {i18n.language === 'en' ? l.valueEn : l.valueGr}
                </MenuItem>
              ))}
            </Select>
          )}
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: commonColors.bluesPrimaryBlue,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px'
              }}
            />
          )}
        </FormControl>
      )}
    />
  )
}
