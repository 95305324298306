import { Outlet } from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2'
import { ManagerSidebar } from '../../components/sidebar/ManagerSidebar'
import { Container, ThemeProvider } from '@mui/material'
import { useBaseTheme } from '../../../utils/useManagerTheme'
import { ManagerNavbar } from '../../components/navbar/ManagerNavbar'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      overflow: 'hidden',
      marginTop: '10px',
      paddingBottom: '10px'
    }
  }
})

const ManagerLayout = () => {
  const { classes } = useStyles()
  const theme = useBaseTheme()
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={0} columns={2}>
        <Grid xs='auto'>
          <ManagerSidebar />
        </Grid>
        <Grid xs>
          <Grid container columns={1}>
            <Grid height={'64px'} xs={12}>
              <ManagerNavbar />
            </Grid>
            <Grid xs={12}>
              <Container className={classes.root} maxWidth='xl'>
                <Outlet />
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default ManagerLayout
