import { Stack, Typography } from '@mui/material'
import PageviewIcon from '@mui/icons-material/Pageview'
import { useTranslation } from 'react-i18next'

export const EmptyTable = () => {
  const { t } = useTranslation()
  return (
    <Stack height={'100%'} direction='row' justifyContent='center' alignItems='center'>
      <Stack textAlign={'center'}  alignItems='center'>
        <PageviewIcon style={{ fontSize: '5rem' }} color='action' />
        <Typography variant='body2' color={'grey'}>{t('messages.emptyTable')}</Typography>
      </Stack>
    </Stack>
  )
}
