import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import { TaxesDto } from '../../models/Taxes'

export interface ITaxProps {
  taxLoader: boolean
  tax?: TaxesDto
  createTax: any
  updateTax: any
}

export function useTaxes(id?: string): ITaxProps {
  const [taxLoader, setLoading] = useState(false)
  const [tax, setTax] = useState<TaxesDto>()
  const service = useBaseService('tax')
  const navigate = useNavigate()

  const updateTax = useCallback(
    async (tax: TaxesDto) => {
      setLoading(true)
      const response = await service.update<TaxesDto>(tax, tax.id)
      if (response) setTax(response)
      setLoading(false)
    },
    [service]
  )

  useEffect(() => {
    if (!id || id === 'new') {
      setTax({} as any)
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<TaxesDto>(id)
      if (response) setTax(response)
      setLoading(false)
    }
    getById(id)
  }, [id, service, setLoading])

  const createTax = useCallback(
    async (tax: TaxesDto) => {
      setLoading(true)
      const response = await service.create(tax)
      if (response) {
        setTax(response)
        navigate(`/manager/settings/taxes/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    taxLoader,
    tax,
    updateTax,
    createTax
  }
}
