import { Stack, TextField } from '@mui/material'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InvoiceEditDto } from '../../../../../models/invoice/InvoiceSale'

interface InvoicePublisherDetailsProps {
  errors: FieldErrors<InvoiceEditDto>
  control: Control<InvoiceEditDto, any>
  isTimologioLianikhs: boolean
  disableForm: boolean
}

export const InvoicePublisherDetails = ({
  errors,
  control,
  disableForm,
  isTimologioLianikhs
}: InvoicePublisherDetailsProps) => {
  const { t } = useTranslation()

  return (
    <Stack
      padding={'1rem'}
      direction='column'
      justifyContent='space-between'
      alignItems='start'
      spacing={2}
    >
      <Controller
        control={control}
        defaultValue={''}
        rules={{ required: !isTimologioLianikhs }}
        name='purpose'
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled={disableForm}
            inputRef={ref}
            {...field}
            fullWidth
            size='small'
            error={!!errors.purpose}
            label={t('labels.invoicePurpose')}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={''}
        name='locationFrom'
        rules={{ required: !isTimologioLianikhs }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled={disableForm}
            inputRef={ref}
            {...field}
            fullWidth
            size='small'
            error={!!errors.locationFrom}
            label={t('labels.locationFrom')}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={''}
        name='locationTo'
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled={disableForm}
            inputRef={ref}
            {...field}
            fullWidth
            size='small'
            error={!!errors.locationTo}
            label={t('labels.locationTo')}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={''}
        name='deliveryMethod'
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled={disableForm}
            inputRef={ref}
            {...field}
            fullWidth
            size='small'
            error={!!errors.deliveryMethod}
            label={t('labels.deliveryMethod')}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={''}
        name='deliveryDetails'
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled={disableForm}
            inputRef={ref}
            {...field}
            fullWidth
            size='small'
            error={!!errors.deliveryDetails}
            label={t('labels.deliveryDetails')}
          />
        )}
      />
    </Stack>
  )
}
