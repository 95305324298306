import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import { ProductTypeDto } from '../../models/product/ProductType'

export interface IUseProductTypeProps {
  productTypeLoader: boolean
  productType?: ProductTypeDto
  createProductType: any
  updateProductType: any
  deleteProductType: (id: string) => Promise<boolean>
}

export function useProductType(id?: string): IUseProductTypeProps {
  const [productTypeLoader, setLoading] = useState(false)
  const [productType, setProductType] = useState<ProductTypeDto>()
  const service = useBaseService('productType')
  const navigate = useNavigate()

  const updateProductType = useCallback(
    async (productType: ProductTypeDto) => {
      setLoading(true)
      const response = await service.update<ProductTypeDto>(productType, productType.id)
      if (response) setProductType(response)
      setLoading(false)
    },
    [service]
  )

  const deleteProductType = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await service.remove(id)
      setLoading(false)
      return response === true
    },
    [service]
  )

  useEffect(() => {
    if (!id || id === 'new') {
      setProductType({
        TypeKey: '',
        labelEn: '',
        labelGr: ''
      } as any)
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<ProductTypeDto>(id)
      if (response) setProductType(response)
      setLoading(false)
    }
    getById(id)
  }, [id, service, setLoading])

  const createProductType = useCallback(
    async (product: ProductTypeDto) => {
      setLoading(true)
      const response = await service.create(product)
      if (response) {
        setProductType(response)
        navigate(`/manager/settings/productTypes/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    productTypeLoader,
    productType,
    updateProductType,
    createProductType,
    deleteProductType
  }
}
