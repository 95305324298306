import { FormControl, InputLabel, OutlinedInput, Paper, Stack } from '@mui/material'
import { mb } from '../../../../utils/cssExtensions'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
export interface IProductSearchProps {
  handleEnter: any
  isMinimal?: boolean
}

export const SearchCompatibleProducts = ({ handleEnter }: IProductSearchProps) => {
  const { t } = useTranslation()

  const onEnterPushed = (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.value.length < 4) return
      handleEnter(e.target.value)
    }
  }

  return (
    <Paper elevation={0} {...mb('20px')}>
      <Stack
        direction={'row'}
        justifyContent='space-between'
        alignItems='start'
        spacing={0}
        padding={0}
      >
        <Stack flex={1}>
          <FormControl sx={{ mt: 2 }} fullWidth variant='outlined'>
            <OutlinedInput
              id='outlined-adornment-password'
              type={'text'}
              onKeyDown={onEnterPushed}
              startAdornment={<SearchIcon />}
              label='Αναζήτηση με κωδικό Stal ή κατασκευαστή'
            />
            <InputLabel>{t('messages.searchProduct')}</InputLabel>
          </FormControl>
        </Stack>
      </Stack>
    </Paper>
  )
}
