import { InvoiceItemDto } from '../../../../../models/invoice/InvoiceSale'

export function updateInvetoryItem(
  newItem: InvoiceItemDto,
  oldItem: InvoiceItemDto
): InvoiceItemDto {
  oldItem.descriptionEn = newItem.descriptionEn ?? oldItem.descriptionEn
  oldItem.quantity = newItem.quantity ?? oldItem.quantity
  oldItem.discountPercentage = newItem.discountPercentage ?? oldItem.discountPercentage
  oldItem.unitSellingPrice = newItem.unitSellingPrice ?? oldItem.unitSellingPrice

  //CalculatedValues
  oldItem.discountAmount = +((+oldItem.unitSellingPrice * +oldItem.discountPercentage) / 100)
  oldItem.priceAfterDiscount = +(
    (+oldItem.unitSellingPrice - +oldItem.discountAmount) *
    +oldItem.quantity
  ).toFixed(2)

  return oldItem
}

export function getTotalQuantity(items: InvoiceItemDto[]): number {
  return items?.reduce((a, b) => a + b.quantity, 0) ?? 0
}

export function getTotalNoTaxNoDiscountmount(items: InvoiceItemDto[]): number {
  return items?.reduce((a, b) => a + b.unitSellingPrice * b.quantity, 0) ?? 0
}

export function getTotalDiscountAmount(items: InvoiceItemDto[]): number {
  return items?.reduce((a, b) => a + b.discountAmount * b.quantity, 0) ?? 0
}

export function getTotalTaxAmount(items: InvoiceItemDto[]): number {
  let total = 0
  items?.forEach((item) => {
    total += ((item.unitSellingPrice - item.discountAmount) * item.quantity * item.taxValue) / 100
  })
  return total
}
