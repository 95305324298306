import { useTranslation } from 'react-i18next'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Source } from '@mui/icons-material'
import { Button, ButtonGroup, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import moment from 'moment'
import { InvoicePurchase } from '../../../models/invoice/InvoicePurchase'
import { IPageState } from '../../../components/material/IMiPaginatedDataGridProps'

export interface IInvoiceTableProps {
  invoiceState: IPageState<InvoicePurchase>
  loading: boolean
}

export const InvoicePurchaseTable = ({ invoiceState, loading }: IInvoiceTableProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.date') as string,
      field: 'invoiceDate',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.invoiceDate).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.invoiceNumber') as string,
      field: 'invoiceNumber',
      flex: 1
    },
    {
      headerName: t('labels.supplier') as string,
      field: 'supplierName',
      flex: 1
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 80,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton
            color='primary'
            onClick={() => navigate(`/manager/invoices/purchases/${params.row.id}`)}
          >
            <Source fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]
  return (
    <MiDatagrid
      title={t('labels.invoices')}
      actions={
        <Button
          color={'primary'}
          size='small'
          variant='outlined'
          onClick={() => navigate('/manager/invoices/purchases/new')}
        >
          {t('buttons.add')}
        </Button>
      }
      data={invoiceState.data}
      columns={columns}
      loading={loading}
    />
  )
}
