import { Button, Stack, TextField } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InvoicePurchaseItem } from '../../../../../models/invoice/InvoicePurchase'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { InvoicePurchaseProductItemInvetory } from './InvoicePurchaseProductItemInvetory'
import { IPriceSuggestionLookup } from '../../../../../hooks/useSuggestingPricesConfig'

export interface IInvoicePurchaseProductListAddProps {
  append: any
  getValues: any
  fields: InvoicePurchaseItem[]
  suggestingPricesConfig: IPriceSuggestionLookup[]
}

export const InvoicePurchaseProductListAdd = ({
  append,
  getValues,
  fields,
  suggestingPricesConfig
}: IInvoicePurchaseProductListAddProps) => {
  const { t } = useTranslation()
  const [invoiceItem, setInvoiceItem] = useState<InvoicePurchaseItem | null>(null)
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<InvoicePurchaseItem>({
    defaultValues: {} as InvoicePurchaseItem,
    reValidateMode: 'onChange'
  })

  const addInvoiceItem = async (item: InvoicePurchaseItem) => {
    const supplierId = getValues('supplierId')
    if (!supplierId) {
      enqueueSnackbar(t('messages.invoiceSelectSupplier'), { variant: 'warning' })
      return
    }
    const exists = fields.find(
      (x) => x.productCode.toLowerCase() === item.productCode.toLowerCase()
    )
    if (exists !== undefined) {
      enqueueSnackbar(t('messages.invoiceItemExists'), { variant: 'info' })
      return
    }
    setInvoiceItem(item)
  }

  const addItem = (item: InvoicePurchaseItem) => {
    item.taxValue = getValues('supplierTax') ?? 0
    append(item)
    reset()
    setInvoiceItem(null)
    document?.getElementById('productCode')?.focus()
  }

  return (
    <>
      {invoiceItem && (
        <InvoicePurchaseProductItemInvetory
          suggestingPricesConfig={suggestingPricesConfig}
          item={invoiceItem}
          onCancel={setInvoiceItem}
          onConfirm={addItem}
        />
      )}
      <Stack padding={2} direction={'row'} spacing={2}>
        <Controller
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          name='productCode'
          render={({ field: { ref, ...field } }) => (
            <TextField
              inputRef={ref}
              id='productCode'
              {...field}
              fullWidth
              size='small'
              error={!!errors.productCode}
              label={t('labels.code')}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={1}
          rules={{ required: true, min: 1 }}
          name='quantity'
          render={({ field: { ref, ...field } }) => (
            <TextField
              inputRef={ref}
              {...field}
              type='number'
              size='small'
              error={!!errors.quantity}
              label={t('labels.quantity')}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={0}
          rules={{ required: true, min: 0.1 }}
          name='price'
          render={({ field: { ref, ...field } }) => (
            <TextField
              inputRef={ref}
              {...field}
              type='number'
              size='small'
              error={!!errors.price}
              label={t('labels.price')}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={0}
          rules={{ required: true }}
          name='sellingPrice'
          render={({ field: { ref, ...field } }) => (
            <TextField
              inputRef={ref}
              {...field}
              type='number'
              size='small'
              error={!!errors.sellingPrice}
              label={t('labels.sellingPrice')}
            />
          )}
        />

        <Button
          style={{ padding: '0 3rem' }}
          variant='contained'
          size='small'
          color='primary'
          onClick={handleSubmit(addInvoiceItem)}
        >
          {t('buttons.insert')}
        </Button>
      </Stack>
    </>
  )
}
