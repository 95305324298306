import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Loader } from '../../../components/loader/loader'
import { SideActions } from '../../../components/material/SideActions'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'
import { useUser } from '../useUser'
import { UserDto } from '../../../models/User'
import { UserDetails } from './UserDetails'
import { LabelCard } from '../../../components/material/LabelCard'
import { UserHeader } from './UserHeader'
import { UserPasswordManager } from './UserPasswardManager'

export const UserContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const { createUser, userLoader, deleteUser, updateUser, user } = useUser(id)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<UserDto>({
    values: { ...user } as UserDto,
    reValidateMode: 'onChange',
    resetOptions: {
      keepDirtyValues: true // keep dirty fields unchanged, but update defaultValues
    }
  })

  useEffect(() => {
    reset({ ...user })
  }, [reset, user])

  async function handleRemove() {
    if (user?.id) {
      setConfirmationModal(true)
    }
  }

  const confirmDeletion = async () => {
    var success = await deleteUser(user?.id as string)
    if (success) {
      navigation('/manager/users')
    }
  }
  function onSubmit(data: any) {
    if (data.id) updateUser(data)
    else createUser(data)
  }

  if (userLoader) return <Loader isFullscreen type={'circles'} />

  return (
    <>
      <ConfirmationModal
        open={confirmationModal}
        title={t('messages.deleteEntity', {
          entityName: user?.lastname
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={confirmDeletion}
      />
      <Grid container spacing={3}>
        <Grid xs={12}>
          <UserHeader
            handleSubmit={() => handleSubmit(onSubmit)}
            handleRemove={handleRemove}
            user={user}
          />
        </Grid>
        <Grid xs={6}>
          <LabelCard
            label={t('labels.details')}
            children={<UserDetails control={control} user={user} errors={errors} />}
          />
        </Grid>
        <Grid xs={6}></Grid>
        <Grid xs={6}>
          <LabelCard label={t('labels.details')} children={<UserPasswordManager id={id} />} />
        </Grid>
      </Grid>
    </>
  )
}
