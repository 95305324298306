import { Container, ThemeProvider } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useEshopTheme } from '../../utils/useEshopTheme'

export const EShopLayout = () => {
  const theme = useEshopTheme()

  return (
    <ThemeProvider theme={theme}>
      {/* <EShopNavbar /> */}
      {/* <Container maxWidth={'xl'}> */}
        <Outlet />
      {/* </Container> */}
    </ThemeProvider>
  )
}
