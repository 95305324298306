import { Divider, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProductLightDto } from '../../../models/product/Product'
import { ProductLightTableView } from './ProductLightTableView'

export interface IProductPartListProps {
  data: ProductLightDto[]
}

export const ProductPartList = ({ data }: IProductPartListProps) => {
  const { t } = useTranslation()

  return (
    <Stack>
      <Divider style={{ margin: '1em 0' }} />
      <Stack direction={'row'} alignItems={'center'}>
        <Typography flex={1} variant='body1' color={'grey'}>
          {t('labels.replacementParts')}
        </Typography>
        <Stack></Stack>
      </Stack>
      <ProductLightTableView data={data} />
    </Stack>
  )
}
