import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { ButtonGroup, Chip, IconButton } from '@mui/material'
import { BorderColor, DeleteOutline } from '@mui/icons-material'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'
import { useData } from '../../../services/useData'
import { useUser } from '../useUser'
import { UserTableDto } from '../../../models/User'
import { BoolChip } from '../../../components/commonViews/BoolChip'
import moment from 'moment'

export const UserTable = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loading, data, removeEntity } = useData<UserTableDto>('user')
  const { deleteUser, userLoader } = useUser()
  const [selectedEntity, setSelectedEntity] = useState<UserTableDto | null>(null)
  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.createdAt') as string,
      field: 'created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.created).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.email') as string,
      field: 'email',
      flex: 1
    },
    {
      headerName: t('labels.name') as string,
      field: 'lastname',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => `${params.row.lastname} ${params.row.name}`
    },
    {
      headerName: t('labels.status') as string,
      field: 'isActive',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        var activeChip = (
          <BoolChip
            boolCheck={params.row.isActive}
            labelError={'Ανενεργός'}
            labelSuccess={'Ενεργός'}
          />
        )
        var lockedOut = params.row.locked ? (
          <Chip size={'small'} label={'Κλειδωμένος'} style={{ color: 'white' }} color='warning' />
        ) : null

        return (
          <div>
            {activeChip} {lockedOut}
          </div>
        )
      }
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => navigate(params.row.id)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
          <IconButton color='error' onClick={() => openDialog(params.row)}>
            <DeleteOutline fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]

  const openDialog = (entity: UserTableDto) => {
    setSelectedEntity(entity)
  }

  const confirmDeletion = async () => {
    var success = await deleteUser(selectedEntity?.id as string)
    if (success) {
      removeEntity(selectedEntity?.id as string)
      setSelectedEntity(null)
    }
  }

  return (
    <>
      <ConfirmationModal
        open={selectedEntity != null}
        title={t('messages.deleteEntity', {
          entityName: selectedEntity?.lastname
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setSelectedEntity(null)}
        onConfirm={confirmDeletion}
        loading={userLoader}
      />
      <MiDatagrid title={t('labels.users')} data={data} columns={columns} loading={loading} />
    </>
  )
}
