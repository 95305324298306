import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import ManagerLayout from '../manager/views/layout/ManagerLayout'
import { WarehouseTable } from '../manager/views/warehouse/table/WarehouseTable'
import { ManagerLogin } from '../manager/views/layout/ManagerLogin'
import { PrivateRoute } from '../manager/services/auth/PrivateRoute'
import { ManagerLogout } from '../manager/views/layout/ManagerLogout'
import { WarehouseContainer } from '../manager/views/warehouse/details/WarehouseContainer'
import { ManufacturerTable } from '../manager/views/manufacturer/table/ManufacturerTable'
import { ManufacturerContainer } from '../manager/views/manufacturer/details/ManufacturerContainer'
import { ProductKeyTable } from '../manager/views/productKey/table/ProductKeyTable'
import { ProductContainer } from '../manager/views/product/details/ProductContainer'
import { ProductTable } from '../manager/views/product/table/ProductTable'
import { ProductKeyContainer } from '../manager/views/productKey/details/ProductKeyContainer'
import { ProductTypeTable } from '../manager/views/productType/table/ProductTypeTable'
import { ProductTypeContainer } from '../manager/views/productType/details/ProductTypeContainer'
import { CustomerContainer } from '../manager/views/customer/details/CustomerContainer'
import { CustomerOverviewContainer } from '../manager/views/customer/overview/CustomerOverviewContainer'
import { ProductOverviewContainer } from '../manager/views/product/overview/ProductOverviewContainer'
import { UserTable } from '../manager/views/users/table/UserTable'
import { UserContainer } from '../manager/views/users/details/UserContainer'
import { SupplierTable } from '../manager/views/supplier/table/SupplierTable'
import { SupplierContainer } from '../manager/views/supplier/details/SupplierContainer'
import NotFound from './NotFound'
import { SettingsContainer } from '../manager/views/settings/SettingsContainer'
import { TaxesTable } from '../manager/views/taxes/table/TaxesTable'
import { TaxContainer } from '../manager/views/taxes/details/TaxContainer'
import { ProtocolContainer } from '../manager/views/protocol/ProtocolContainer'
import { InvoicesContainer } from '../manager/views/invoice/table/InvoicesContainer'
import { CustomerTableContainer } from '../manager/views/customer/table/CustomerContainer'
import { InvoiceSalesContainer } from '../manager/views/invoice/details/InvoiceSalesContainer'
import { InvoicePurchaseContainer } from '../manager/views/invoice/details/InvoicePurchaseContainer'
import { EShopLayout } from '../eshop/views/layout/EShopLayout'
import { EHomeContainer } from '../eshop/views/home/EHomeContainer'
import { InvoicePurchasesContainer } from '../manager/views/invoice/table/InvoicePurchasesContainer'
import { XmlInvoicesContainer } from '../manager/views/xml-invoices/XmlInvoicesContainer'
import { ProductProfitSuggestionsContainer } from '../manager/views/productProfitSuggestions/ProductProfitSuggerstionsContainer'
import { XmlInvoiceContainer } from '../manager/views/xml-invoices/XmlInvoiceContainer'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<EShopLayout />}>
          <Route path='/' element={<EHomeContainer />} />
        </Route>
        <Route path='*' element={<NotFound />} />
        <Route path='/manager/login' element={<ManagerLogin />} />
        <Route path='/manager' element={<PrivateRoute component={ManagerLayout} />}>
          <Route path='/manager' element={<ProductTable />} />
          <Route path='login' element={<ManagerLogin />} />
          <Route path='logout' element={<ManagerLogout />} />
          <Route path='settings/warehouses' element={<PrivateRoute component={WarehouseTable} />} />
          <Route
            path='settings/warehouses/:id'
            element={<PrivateRoute component={WarehouseContainer} />}
          />
          <Route
            path='settings/manufacturers'
            element={<PrivateRoute component={ManufacturerTable} />}
          />
          <Route path='settings/aade' element={<PrivateRoute component={XmlInvoicesContainer} />} />
          <Route
            path='settings/aade/:id'
            element={<PrivateRoute component={XmlInvoiceContainer} />}
          />
          <Route
            path='settings/profitability'
            element={<PrivateRoute component={ProductProfitSuggestionsContainer} />}
          />
          <Route
            path='settings/protocol'
            element={<PrivateRoute component={ProtocolContainer} />}
          />
          <Route
            path='settings/manufacturers/:id'
            element={<PrivateRoute component={ManufacturerContainer} />}
          />
          <Route path='settings' element={<PrivateRoute component={SettingsContainer} />}></Route>

          <Route
            path='settings/productkeys'
            element={<PrivateRoute component={ProductKeyTable} />}
          />
          <Route
            path='settings/productkeys/:id'
            element={<PrivateRoute component={ProductKeyContainer} />}
          />

          <Route
            path='settings/productTypes'
            element={<PrivateRoute component={ProductTypeTable} />}
          />
          <Route
            path='settings/productTypes/:id'
            element={<PrivateRoute component={ProductTypeContainer} />}
          />
          <Route path='products' element={<PrivateRoute component={ProductTable} />} />
          <Route path='products/:id' element={<PrivateRoute component={ProductContainer} />} />
          <Route
            path='products/overview/:stalId/'
            element={<PrivateRoute component={ProductOverviewContainer} />}
          />
          <Route path='customers' element={<PrivateRoute component={CustomerTableContainer} />} />
          <Route path='customers/:id' element={<PrivateRoute component={CustomerContainer} />} />
          <Route path='users' element={<PrivateRoute component={UserTable} />} />
          <Route path='users/:id' element={<PrivateRoute component={UserContainer} />} />
          <Route
            path='customers/overview/:id'
            element={<PrivateRoute component={CustomerOverviewContainer} />}
          />
          <Route path='settings/taxes' element={<PrivateRoute component={TaxesTable} />} />
          <Route path='settings/taxes/:id' element={<PrivateRoute component={TaxContainer} />} />
          <Route path='settings/suppliers' element={<PrivateRoute component={SupplierTable} />} />
          <Route
            path='settings/suppliers/:id'
            element={<PrivateRoute component={SupplierContainer} />}
          />
          <Route path='invoices' element={<Navigate to='/manager/invoices/sales' />} />
          <Route path='invoices/sales' element={<PrivateRoute component={InvoicesContainer} />} />
          <Route
            path='invoices/purchases'
            element={<PrivateRoute component={InvoicePurchasesContainer} />}
          />
          <Route
            path='invoices/sales/:id'
            element={<PrivateRoute component={InvoiceSalesContainer} />}
          />
          <Route
            path='invoices/purchases/:id'
            element={<PrivateRoute component={InvoicePurchaseContainer} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
