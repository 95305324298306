import { IconButton, Stack } from '@mui/material'
import { Control, UseFormGetValues, useFieldArray } from 'react-hook-form'
import { updateInvetoryItem } from './InvoiceItemCalculations'
import { InvoiceEditDto, InvoiceItemDto } from '../../../../../models/invoice/InvoiceSale'
import { useTranslation } from 'react-i18next'
import { DeleteOutline } from '@mui/icons-material'
import { GridColDef, GridRenderCellParams, DataGrid, GridValidRowModel } from '@mui/x-data-grid'
import { InvoiceItemSearch } from './InvoiceItemSearch'
import { makeStyles } from 'tss-react/mui'
import ConstructionIcon from '@mui/icons-material/Construction'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      border: 'none'
    }
  }
})

const colProps: any = (field: string) => ({
  sortable: false,
  align: 'center',
  flex: 1,
  headerAlign: 'center',
  field: field
})
export interface IInvoiceItemProps {
  control: Control<InvoiceEditDto, any>
  getValues: UseFormGetValues<InvoiceEditDto>
  disableForm: boolean
}
export const InvoiceItems = ({ control, getValues, disableForm }: IInvoiceItemProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { fields, append, remove, update } = useFieldArray({
    control,
    keyName: 'propId',
    name: 'items'
  })

  let columns: GridColDef[] = [
    {
      headerName: t('labels.stalid') as string,
      ...colProps('stalCode')
    },
    {
      headerName: t('labels.code') as string,
      ...colProps('productCode')
    },
    {
      headerName: t('labels.description') as string,
      editable: !disableForm && true,
      ...colProps('descriptionEn')
    },
    {
      headerName: t('labels.shelf') as string,
      editable: false,
      ...colProps('productShelf')
    },
    {
      headerName: t('labels.quantity') as string,
      editable: !disableForm && true,
      ...colProps('quantity'),
      flex: 0,
      type: 'number'
    },
    {
      headerName: t('labels.price') as string,
      editable: false,
      ...colProps('unitNetPrice'),
      field: 'unitNetPrice',
      type: 'number',
      renderCell: (params: GridRenderCellParams) => `${params.row.unitNetPrice} €`
    },
    {
      headerName: t('labels.sellingPrice') as string,
      editable: !disableForm && true,
      ...colProps('unitSellingPrice'),
      type: 'number',
      renderCell: (params: GridRenderCellParams) => `${params.row.unitSellingPrice} €`
    },
    {
      headerName: t('labels.discountPercentage') as string,
      editable: !disableForm && true,
      ...colProps('discountPercentage'),
      type: 'number',
      renderCell: (params: GridRenderCellParams) => `${params.row.discountPercentage} %`
    },
    {
      headerName: t('labels.discount') as string,
      ...colProps('discountAmount'),
      renderCell: (params: GridRenderCellParams) => `${params.row.discountAmount} €`
    },
    {
      headerName: t('labels.priceAfterDiscount') as string,
      ...colProps('priceAfterDiscount'),
      flex: 0,
      renderCell: (params: GridRenderCellParams) => `${params.row.priceAfterDiscount} €`
    },
    {
      headerName: t('labels.taxValue') as string,
      ...colProps('taxValue'),
      flex: 0,
      renderCell: (params: GridRenderCellParams) => `${params.row.taxValue} %`
    },
    {
      headerName: 'actions',
      renderHeader: () => <ConstructionIcon fontSize={'small'} />,
      ...colProps('inventoryId'),
      field: 'inventoryId',
      width: 60,
      flex: 0,
      renderCell: (params: GridRenderCellParams) =>
        !disableForm && (
          <IconButton
            color='error'
            onClick={() => remove(params.api.getRowIndexRelativeToVisibleRows(params.row.id))}
          >
            <DeleteOutline fontSize={'small'} />
          </IconButton>
        )
    }
  ]

  if (disableForm) {
    columns = columns.filter((x: any) => x.field !== 'unitNetPrice' && x.field !== 'inventoryId')
  }
  const processRowUpdate = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
    const i = fields.findIndex((x) => x.id === oldRow.id)
    const updatedRow = updateInvetoryItem(newRow as InvoiceItemDto, oldRow as InvoiceItemDto)
    update(i, updateInvetoryItem(newRow as InvoiceItemDto, oldRow as InvoiceItemDto))
    return updatedRow
  }

  return (
    <Stack
      direction='column'
      justifyContent='flex-start'
      alignItems='stretch'
      paddingTop={'0px'}
      paddingX={2}
      paddingBottom={2}
      spacing={2}
    >
      <DataGrid
        className={classes.root}
        rows={fields ?? []}
        processRowUpdate={processRowUpdate}
        columns={columns}
        sx={{
          '.MuiDataGrid-overlayWrapperInner': { height: '20px!important' },
          '.MuiDataGrid-overlayWrapper': { height: '20px!important' }
        }}
        disableRowSelectionOnClick
        disableColumnFilter
        hideFooter={true}
        disableColumnMenu
      />
      <Stack>{!disableForm && <InvoiceItemSearch getValues={getValues} append={append} />}</Stack>
    </Stack>
  )
}
