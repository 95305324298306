import { Control, Controller, FieldErrors } from 'react-hook-form'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { WarehouseDto } from '../../../models/Warehouse'
import { fieldProps } from '../../../../utils/FormExtensions'
import { PaperHeader } from '../../../components/paperHeader/PaperHeader'

export interface IWarehouseDetailsProps {
  warehouse?: WarehouseDto
  errors: FieldErrors<WarehouseDto>
  control: Control<WarehouseDto, any>
}
export const WarehouseDetails = ({ control, warehouse, errors }: IWarehouseDetailsProps) => {
  const { t } = useTranslation()
  return (
    <Paper elevation={1}>
      <Stack padding={2}>
        <PaperHeader
          label={warehouse?.id ? t('labels.editRecord') : t('labels.newRecord')}
          rightChild={
            warehouse?.id ? (
              <Typography variant='body2' color={'grey'}>
                {t('labels.createdAt')}
                {moment(warehouse?.created).format('DD/MM/YYYY , HH:mm A')}
              </Typography>
            ) : null
          }
        />
        <Controller
          control={control}
          defaultValue=''
          name='name'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t(`labels.name`), ref, field)} error={!!errors.name} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='description'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...fieldProps(t(`labels.description`), ref, field)}
              error={!!errors.description}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='address'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t(`labels.address`), ref, field)} error={!!errors.address} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='floor'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t(`labels.floor`), ref, field)} error={!!errors.floor} />
          )}
        />
        <Controller
          control={control}
          name='wareHouseType'
          defaultValue={0}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl size='small' fullWidth margin='normal'>
              <InputLabel>{t('labels.wareHouseType')}</InputLabel>
              <Select {...field} fullWidth error={!!errors.floor} label='WareHouseType'>
                <MenuItem value={0}>{t('labels.mobileUnit')}</MenuItem>
                <MenuItem value={1}>{t('labels.storageUnit')}</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Stack>
    </Paper>
  )
}
