import { Print } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { InvoiceEditDto } from '../../../../../models/invoice/InvoiceSale'
import usePrint from '../../../../../hooks/usePrint'
import moment from 'moment'

interface IInvoiceHeaderProps {
  disableForm: boolean
  handleSubmit: any
  invoice: InvoiceEditDto | undefined
  invoiceLoader: boolean
  isNew: boolean
  publish: any
}

export const InvoiceHeader = ({
  disableForm,
  handleSubmit,
  invoice,
  publish,
  isNew,
  invoiceLoader
}: IInvoiceHeaderProps) => {
  const { t } = useTranslation()
  const { printDocument, printLoader } = usePrint()
  const navigation = useNavigate()
  let label = isNew ? t('labels.newInvoice') : t('labels.invoice')

  if (!isNew) {
    label += `: ${invoice?.protocolNumber} - ${moment(invoice?.invoiceDate).format(
      'DD/MM/YYYY , HH:mm A'
    )}`
  }

  return (
    <Paper elevation={1}>
      <Stack
        padding={'1rem'}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Stack flex={1}>
          <Typography fontSize={23} fontWeight={300}>
            {label}
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent={'right'} flex={1} spacing={1}>
          <Button
            key={1}
            variant='outlined'
            color='primary'
            onClick={() => navigation('/manager/invoices')}
          >
            {t('buttons.back')}
          </Button>
          {!disableForm && (
            <LoadingButton
              key={0}
              loading={invoiceLoader}
              variant='contained'
              color='primary'
              onClick={handleSubmit(publish)}
              disabled={disableForm}
            >
              {t('buttons.publish')}
            </LoadingButton>
          )}
          {disableForm && (
            <Button
              key={4}
              variant='contained'
              color='secondary'
              onClick={() => navigation(`/manager/customers/overview/${invoice?.customerId}`)}
            >
              {t('breacrumb.customerOverview')}
            </Button>
          )}
          {disableForm && (
            <LoadingButton
              key={2}
              loading={printLoader}
              variant='contained'
              startIcon={<Print />}
              color='primary'
              onClick={async () => await printDocument(`invoice/sales/print/${invoice?.id}`)}
            >
              {t('buttons.print')}
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}
