import { Paper } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { useTranslation } from 'react-i18next'
import { DisplayInfo } from '../../../../../components/commonViews/DisplayInfo'
import { getTotalNoTax, getTotalTaxAmount } from './InvoicePurchaseCalculations'
import { Sum } from '../../../../../../utils/commonExtesions'

interface IInvoicePurchaseSummaryProps {
  watch: any
}
export const InvoicePurchaseSummary = ({ watch }: IInvoicePurchaseSummaryProps) => {
  const { t } = useTranslation()
  const items = watch('invoiceItems')

  const shipping = watch('shippingCosts') ?? 0
  const totalNoTax = +getTotalNoTax(items).toFixed(2)
  const totalTaxAmount = +getTotalTaxAmount(items).toFixed(2)
  return (
    <Paper elevation={1}>
      <Grid2 container justifyContent={'flex-end'} padding={2}>
        <Grid2 xs={4}>
          <DisplayInfo
            labelFontSize={18}
            valueFontSize={18}
            justifyContent='space-between'
            label={t('labels.shippingCosts')}
            valueSuffix={'€'}
            value={shipping}
          />
          <DisplayInfo
            labelFontSize={18}
            valueFontSize={18}
            justifyContent='space-between'
            label={t('labels.totalPreTax')}
            valueSuffix={'€'}
            value={totalNoTax}
          />
          <DisplayInfo
            labelFontSize={18}
            valueFontSize={18}
            label={t('labels.totalTax')}
            valueSuffix={'€'}
            justifyContent='space-between'
            value={totalTaxAmount}
          />
          <DisplayInfo
            labelFontSize={18}
            valueFontSize={18}
            justifyContent='space-between'
            label={t('labels.total')}
            valueSuffix={'€'}
            value={Sum([totalNoTax, totalTaxAmount, shipping])}
          />
        </Grid2>
      </Grid2>
    </Paper>
  )
}
