import { IconButton, Paper } from '@mui/material'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { Control, useFieldArray } from 'react-hook-form'
import { DeleteOutline } from '@mui/icons-material'
import { InvoicePurchase, InvoicePurchaseItem } from '../../../../../models/invoice/InvoicePurchase'
import ConstructionIcon from '@mui/icons-material/Construction'
import { InvoicePurchaseProductListAdd } from './InvoicePurchaseProductListAdd'
import { ProductStatusChip } from '../../../../../../utils/ProductStatus'
import { IPriceSuggestionLookup } from '../../../../../hooks/useSuggestingPricesConfig'

export interface IInvoicePurchaseProductListProps {
  control: Control<InvoicePurchase, any>
  getValues: any
  isDisabled: boolean
  suggestingPricesConfig: IPriceSuggestionLookup[]
}

const colProps: any = (field: string, align = 'center') => ({
  sortable: false,
  align: align,
  headerAlign: 'center',
  field: field
})

export const InvoicePurchaseProductList = ({
  control,
  isDisabled,
  getValues,
  suggestingPricesConfig
}: IInvoicePurchaseProductListProps) => {
  const { t } = useTranslation()

  const { fields, append, remove, update } = useFieldArray({
    control,
    shouldUnregister: true,
    keyName: 'propId',
    name: 'invoiceItems'
  })

  const removeInvetoryItem = (productCode: string) => {
    const i = fields.findIndex((x) => x.productCode === productCode)
    remove(i)
  }

  const columns: GridColDef[] = [
    {
      headerName: t('labels.code') as string,
      ...colProps('productCode', 'left')
    },
    {
      headerName: t('labels.description') as string,
      flex: 1,
      ...colProps('description')
    },
    {
      headerName: t('labels.status') as string,
      flex: 1,
      ...colProps('status'),
      renderCell: (params: GridRenderCellParams) => <ProductStatusChip status={params.row.status} />
    },
    {
      headerName: t('labels.quantity') as string,
      editable: true,
      ...colProps('quantity'),
      flex: 1,
      type: 'number'
    },
    {
      headerName: t('labels.price') as string,
      editable: true,
      flex: 1,
      ...colProps('price'),
      type: 'number',
      renderCell: (params: GridRenderCellParams) => `${params.row.price} €`
    },
    {
      headerName: t('labels.sellingPrice') as string,
      editable: true,
      flex: 1,
      ...colProps('sellingPrice'),
      type: 'number',
      renderCell: (params: GridRenderCellParams) => `${params.row.sellingPrice} €`
    },
    {
      headerName: t('labels.taxValue') as string,
      flex: 1,
      ...colProps('taxValue'),
      renderCell: (params: GridRenderCellParams) => `${params.row.taxValue} %`
    },
    {
      headerName: t('labels.warehouse') as string,
      flex: 1,
      ...colProps('warehouseName')
    },
    {
      headerName: t('labels.shelf') as string,
      flex: 1,
      editable: true,
      ...colProps('shelf')
    },
    {
      headerName: 'actions',
      renderHeader: () => <ConstructionIcon fontSize={'small'} />,
      ...colProps('id'),
      width: 60,
      renderCell: (params: GridRenderCellParams) =>
        !isDisabled && (
          <IconButton color='error' onClick={() => removeInvetoryItem(params.row.productCode)}>
            <DeleteOutline fontSize={'small'} />
          </IconButton>
        )
    }
  ]

  const processRowUpdate = (newRow: InvoicePurchaseItem, oldRow: InvoicePurchaseItem) => {
    const i = fields.findIndex((x) => x.productCode === oldRow.productCode)
    update(i, newRow)
    return newRow
  }

  return (
    <Paper elevation={1}>
      {!isDisabled && (
        <InvoicePurchaseProductListAdd
          suggestingPricesConfig={suggestingPricesConfig}
          getValues={getValues}
          append={append}
          fields={fields}
        />
      )}
      <DataGrid
        rows={fields ?? []}
        processRowUpdate={processRowUpdate}
        getRowId={(row) => row.productCode}
        columns={columns}
        sx={{
          '.MuiDataGrid-overlayWrapperInner': { height: '80px!important' },
          '.MuiDataGrid-overlayWrapper': { height: '80px!important' }
        }}
        disableRowSelectionOnClick
        disableColumnFilter
        hideFooter={true}
        disableColumnMenu
      />
    </Paper>
  )
}
