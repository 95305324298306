import { Control, Controller, FieldErrors } from 'react-hook-form'
import { ProductKeyDto } from '../../../models/product/ProductKey'
import { useTranslation } from 'react-i18next'
import { Paper, Stack, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { PaperHeader } from '../../../components/paperHeader/PaperHeader'
import { fieldProps } from '../../../../utils/FormExtensions'

export interface IProductKeyDetailsProps {
  productKey?: ProductKeyDto
  errors: FieldErrors<ProductKeyDto>
  control: Control<ProductKeyDto, any>
}

export const ProductKeyDetails = ({ productKey, errors, control }: IProductKeyDetailsProps) => {
  const { t } = useTranslation()
  return (
    <Paper elevation={1}>
      <Stack padding={2}>
        <PaperHeader
          label={productKey?.id ? t('labels.editRecord') : t('labels.newRecord')}
          rightChild={
            productKey?.id ? (
              <Typography variant='body2' color={'grey'}>
                {t('labels.lastUpdate')}
                {moment(productKey?.updated).format('DD/MM/YYYY , HH:mm A')}
              </Typography>
            ) : null
          }
        />

        <Controller
          control={control}
          defaultValue=''
          name='keyName'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps('Όνομα', ref, field)} error={!!errors.keyName} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='labelGr'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps('Τίτλος Ελληνικά', ref, field)} error={!!errors.labelGr} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='labelEn'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps('Τίτλος Αγγλικά', ref, field)} error={!!errors.labelEn} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='notes'
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps('Σημειώσεις', ref, field)} error={!!errors.notes} />
          )}
        />
      </Stack>
    </Paper>
  )
}
