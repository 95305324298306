import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AddressDto } from '../../../models/Customer'
import { fieldProps } from '../../../../utils/FormExtensions'
import { CountrySelect } from '../../../components/lookupSelect/CountrySelect'

export interface IAddressModalProps {
  entity?: AddressDto
  onConfirm: any
  onCancel: any
}
export const AddressModal = ({ onCancel, onConfirm, entity }: IAddressModalProps) => {
  const { t } = useTranslation()
  const title = entity?.id ? 'editRecord' : 'newRecord'
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<AddressDto>({
    defaultValues: entity
  })

  const sumbitForm = (data: AddressDto) => onConfirm(data)

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle id='alert-dialog-title'>{t('labels.' + title)}</DialogTitle>
      <DialogContent>
        <Controller
          control={control}
          name='type'
          defaultValue={0}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl size='small' fullWidth margin='normal'>
              <InputLabel id='addtype22'> {t('labels.type')}</InputLabel>
              <Select {...field} fullWidth error={!!errors.type} label={t('labels.type')}>
                <MenuItem key={1} value={0}>
                  Home
                </MenuItem>
                <MenuItem key={2} value={1}>
                  Work
                </MenuItem>
                <MenuItem key={3} value={2}>
                  Logistics
                </MenuItem>
                <MenuItem key={4} value={3}>
                  Other
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='street'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t('labels.street'), ref, field)} error={!!errors.street} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='city'
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t('labels.city'), ref, field)} error={!!errors.city} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='region'
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t('labels.region'), ref, field)} error={!!errors.region} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='postalCode'
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...fieldProps(t('labels.postalCode'), ref, field)}
              error={!!errors.postalCode}
            />
          )}
        />
        <CountrySelect
          margin='normal'
          control={control}
          label={t('labels.country')}
          fieldName='countryId'
          errors={errors}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        <Button color='success' onClick={handleSubmit(sumbitForm)} autoFocus>
          {t('buttons.insert')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
