import {
  ButtonGroup,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton
} from '@mui/material'
import { Control, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Add, BorderColor, DeleteOutline } from '@mui/icons-material'
import { useState } from 'react'
import { ProductEditDto } from '../../../models/product/Product'
import { ProductInvetoryModal } from './ProductInvetoryModal'
import TableContainer from '@mui/material/TableContainer'
import { InventoryDto } from '../../../models/product/Inventory'
import ConstructionIcon from '@mui/icons-material/Construction'
import { ProductStatusField } from '../productUtilities'

export interface IProductInvetoryProps {
  control: Control<ProductEditDto, any>
}

export const ProductInvetory = ({ control }: IProductInvetoryProps) => {
  const { t } = useTranslation()
  const [productBatch, setProductBatch] = useState<InventoryDto | null>(null)
  const { fields, append, remove, update } = useFieldArray({
    control,
    keyName: 'propId',
    name: 'inventory'
  })

  const addOrUpdateEntity = (manufacturer: any) => {
    setProductBatch(null)
    if (manufacturer.id) {
      const i = fields.findIndex((f) => f.id === manufacturer.id)
      update(i, manufacturer)
    } else append(manufacturer)
  }

  const removeEntity = (id: string) => {
    const i = fields.findIndex((f) => f.id === id)
    remove(i)
  }

  return (
    <Stack>
      {productBatch != null && (
        <ProductInvetoryModal
          onConfirm={addOrUpdateEntity}
          entity={productBatch}
          onCancel={() => setProductBatch(null)}
        />
      )}
      <Divider style={{ margin: '1em 0' }} />
      <Stack direction={'row'} alignItems={'center'}>
        <Typography flex={1} variant='body1' color={'grey'}>
          {t('labels.availability')}
        </Typography>
        <Stack>
          <IconButton color='primary' onClick={() => setProductBatch(new InventoryDto())}>
            <Add />
          </IconButton>
        </Stack>
      </Stack>
      {fields?.length > 0 && (
        <TableContainer component={'div'}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>{t('labels.warehouse')}</TableCell>
                <TableCell align='right'>{t('labels.shelf')}</TableCell>
                <TableCell align='right'>{t('labels.quantity')}</TableCell>
                <TableCell align='right'>{t('labels.price')} &nbsp; (&euro;)</TableCell>
                <TableCell align='right'>{t('labels.salesPrice')} &nbsp; (&euro;)</TableCell>
                <TableCell align='right'>{t('labels.status')}</TableCell>
                <TableCell align='center' width={60}>
                  <ConstructionIcon fontSize={'small'} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((row: any, i: number) => (
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    {row.warehouseName}
                  </TableCell>
                  <TableCell align='right'>{row.self}</TableCell>
                  <TableCell align='right'>{row.quantity}</TableCell>
                  <TableCell align='right'>{row.price}</TableCell>
                  <TableCell align='right'>{row.suggestedSellingPrice}</TableCell>
                  <TableCell align='right'>{ProductStatusField(row.status, t)}</TableCell>
                  <TableCell>
                    <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
                      <IconButton color='default' onClick={() => setProductBatch(row)}>
                        <BorderColor fontSize={'small'} />
                      </IconButton>
                      <IconButton color='error' onClick={() => removeEntity(row.id)}>
                        <DeleteOutline fontSize={'small'} />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  )
}
