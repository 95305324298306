import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  InputBase,
  Stack,
  Typography,
  IconButton
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import QrCode from '@mui/icons-material/QrCode'
import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import { ml } from '../../../../utils/cssExtensions'
import { useManufacturerCode } from '../useManufacturerCode'
import { useState } from 'react'

export interface IManufacturerCodeDetailsProps {
  id: string | undefined
}
export const ManufacturerCodeDetails = ({ id }: IManufacturerCodeDetailsProps) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const {
    createManufacturerCode,
    response,
    clear,
    deleteManufacturerCode,
    manufacturerCodeLoader,
    searchManufacturerCode,
    manufacturerCode
  } = useManufacturerCode()

  const handleOnChange = async (event: any) => {
    if (event.key === 'Enter') {
      await handleSearch()
      return
    }
    setSearch(event.target.value)
  }

  const onKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      await handleSearch()
    }
  }

  const handleSearch = async () => {
    if (search.length < 4) return
    await searchManufacturerCode(id as string, search as any)
  }

  const handleDelete = async () => {
    await deleteManufacturerCode(id as string, manufacturerCode?.code as string)
  }

  const handleAddCode = async () => {
    await createManufacturerCode(id as string, search as any)
    setSearch('')
  }

  const handleClear = () => {
    setSearch('')
    clear()
  }

  if (!id) return null
  return (
    <Stack>
      <Stack>
        <Divider style={{ margin: '1em 0' }} />
        <Typography flex={1} variant='body1' color={'grey'}>
          {t('labels.editCodes')}
        </Typography>
        <Stack direction={'row'}>
          <IconButton sx={{ p: '10px' }} aria-label='menu'>
            <QrCode />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            value={search}
            onChange={handleOnChange}
            onKeyDown={onKeyDown}
            placeholder='Search for codes'
            inputProps={{ 'aria-label': 'search google maps' }}
          />
          <IconButton type='button' sx={{ p: '10px' }} aria-label='search' onClick={handleSearch}>
            {manufacturerCodeLoader ? <CircularProgress size={12} /> : <SearchIcon />}
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
          <IconButton type='button' sx={{ p: '10px' }} aria-label='search' onClick={handleClear}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction={'row'}>
        {manufacturerCode?.code ? (
          <Chip
            label={manufacturerCode.code}
            onDelete={handleDelete}
            deleteIcon={<DeleteIcon />}
            variant='outlined'
          />
        ) : (
          response && (
            <div>
              {response}
              <Button
                {...ml('1em')}
                variant='outlined'
                size='small'
                color='primary'
                onClick={handleAddCode}
              >
                Προσθήκη
              </Button>
            </div>
          )
        )}
      </Stack>
    </Stack>
  )
}
