import { Control, Controller, FieldErrors } from 'react-hook-form'
import { Paper, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { PaperHeader } from '../../../components/paperHeader/PaperHeader'
import { fieldProps } from '../../../../utils/FormExtensions'
import { SupplierDto } from '../../../models/Supplier'
import { ContactList } from '../../customer/contact/ContactList'

export interface ISupplierDetailsProps {
  supplier?: SupplierDto
  errors: FieldErrors<SupplierDto>
  control: Control<SupplierDto, any>
}
export const SupplierDetails = ({ control, supplier, errors }: ISupplierDetailsProps) => {
  const { t } = useTranslation()
  const supplierFields: Array<keyof SupplierDto> = [
    'name',
    'taxId',
    'taxValue',
    'doy',
    'proffesion',
    'accountingAddress',
    'accountingPostalCode',
    'accountingTown',
    'country',
    'senderTown',
    'senderAddress',
    'webpage',
    'senderPostalCode',
    'contanctPerson'
  ]
  const supplierFieldsRequired = ['name', 'country']
  const supplierFieldsTypes = (field: string) => {
    if (field === 'taxValue') return 'number'
    return 'text'
  }
  return (
    <Paper elevation={1}>
      <Stack padding={2}>
        <PaperHeader
          label={supplier?.id ? t('labels.editRecord') : t('labels.newRecord')}
          rightChild={
            supplier?.id ? (
              <Typography variant='body2' color={'grey'}>
                {t('labels.createdAt')}
                {moment(supplier?.created).format('DD/MM/YYYY , HH:mm A')}
              </Typography>
            ) : null
          }
        />
        {supplierFields.map((fieldName: keyof SupplierDto) => (
          <Controller
            key={fieldName}
            control={control}
            defaultValue=''
            name={fieldName}
            rules={{ required: supplierFieldsRequired.includes(fieldName) }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                type={supplierFieldsTypes(fieldName)}
                {...fieldProps(t(`labels.${fieldName}`), ref, field)}
                error={!!errors[fieldName]}
              />
            )}
          />
        ))}
        <ContactList control={control as any} disableDetails={true} />
      </Stack>
    </Paper>
  )
}
