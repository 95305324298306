import { Button, FormControl, Paper, Stack, TextField, Typography } from '@mui/material'
import { pad } from '../../../../utils/cssExtensions'
import { RangeDatePicker } from '../../../components/rangeDatePicker/RangeDatePicker'
import { LookupSelect } from '../../../components/lookupSelect/LookupSelect'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface InvoiceTableFiltersProps {
  control: Control<any, any>
  errors: FieldErrors<any>
  onEnterPushed: any
  clearFilters: any
}

export const InvoiceTableFilters = ({
  control,
  errors,
  onEnterPushed,
  clearFilters
}: InvoiceTableFiltersProps) => {
  const { t } = useTranslation()
  return (
    <Paper elevation={1} {...pad('20px')}>
      <Typography padding={'8px'} variant='h5'>
        {t('labels.search')}
      </Typography>
      <Stack direction={'row'} justifyContent='start' alignItems='start' spacing={2}>
        <Stack flex={1}>
          <LookupSelect
            lookupUrl='lookup/invoices'
            control={control}
            path={'invoice'}
            label={t('labels.invoiceType')}
            name='typeId'
            errors={errors}
          />
        </Stack>
        <Stack>
          <Controller
            control={control}
            defaultValue={undefined}
            name='protocolNumber'
            rules={{ required: true }}
            render={({ field: { ref, onChange, ...field } }) => (
              <FormControl fullWidth variant='outlined'>
                <TextField
                  size='small'
                  inputRef={ref}
                  error={!!errors.invoiceNumber}
                  onChange={onChange}
                  onKeyDown={onEnterPushed}
                  {...field}
                  label={t('labels.invoiceNumber2')}
                />
              </FormControl>
            )}
          />
        </Stack>
        <Stack>
          <RangeDatePicker control={{ control }} size={'small'} />
        </Stack>
        <Stack>
          <Button color={'primary'} variant='contained' onClick={clearFilters}>
            {t('buttons.clear')}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  )
}
