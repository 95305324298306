import { Control, Controller, FieldErrors } from 'react-hook-form'
import { Button, Paper, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { CustomerDetailsDto } from '../../../models/Customer'
import { PaperHeader } from '../../../components/paperHeader/PaperHeader'
import { fieldProps } from '../../../../utils/FormExtensions'
import { AddressList } from '../address/AddressList'
import { ContactList } from '../contact/ContactList'
import { LookupSelect } from '../../../components/lookupSelect/LookupSelect'
import { SimpleSelect } from '../../../components/lookupSelect/SimpleSelect'

export interface ICustomerDetailsProps {
  customer?: CustomerDetailsDto
  errors: FieldErrors<CustomerDetailsDto>
  control: Control<CustomerDetailsDto, any>
  getExternalCompanyDetails: any
}
export const CustomerDetails = ({
  control,
  customer,
  errors,
  getExternalCompanyDetails
}: ICustomerDetailsProps) => {
  const { t } = useTranslation()

  return (
    <Paper>
      <Stack padding={2}>
        <PaperHeader
          label={customer?.id ? t('labels.editRecord') : t('labels.newRecord')}
          rightChild={
            customer?.id ? (
              <Typography variant='body2' color={'grey'}>
                {t('labels.lastUpdate')}&nbsp;
                {moment(customer?.updated).format('DD/MM/YYYY , HH:mm A')}
              </Typography>
            ) : null
          }
        />
        <Controller
          control={control}
          defaultValue=''
          name='fullname'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...fieldProps(t('labels.fullname'), ref, field)}
              error={!!errors.fullname}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='occupation'
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...fieldProps(t('labels.occupation'), ref, field)}
              error={!!errors.occupation}
            />
          )}
        />        
        <Stack direction={'row'} marginTop={2} marginBottom={1} spacing={2}>
          <Controller
            control={control}
            defaultValue={0}
            name='creditLimit'
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...fieldProps(t('labels.creditLimit'), ref, field)}
                error={!!errors.creditLimit}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={0}
            name='creditBalance'
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...fieldProps(t('labels.debt'), ref, field)}
                error={!!errors.creditBalance}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={0}
            name='creditDays'
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...fieldProps(t('labels.creditDays'), ref, field)}
                error={!!errors.creditDays}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={0}
            name='discount'
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...fieldProps(t('labels.discount'), ref, field)}
                error={!!errors.discount}
              />
            )}
          />
        </Stack>
        <Controller
          control={control}
          defaultValue={''}
          name='personInCharge'
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...fieldProps(t('labels.contanctPerson'), ref, field)}
              error={!!errors.personInCharge}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={''}
          name='comments'
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...fieldProps(t('labels.comments'), ref, field)}
              error={!!errors.comments}
            />
          )}
        />
        <SimpleSelect
          label={t('labels.customerType')}
          control={control}
          margin='1rem 0'
          data={[
            {
              id: 0,
              valueEn: 'Company',
              valueGr: 'Εταιρία'
            },
            {
              id: 1,
              valueEn: 'Retail',
              valueGr: 'Φ.Π'
            }
          ]}
          name='customerType'
          errors={errors}
        />
        <Typography variant='body1' color={'grey'}>
          {t('labels.taxInformation')}
        </Typography>
        <Stack direction={'row'} alignContent={'center'} alignItems={'center'}>
          <Stack flex={1}>
            <Controller
              control={control}
              defaultValue=''
              name='tin'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField {...fieldProps(t('labels.tin'), ref, field)} error={!!errors.tin} />
              )}
            />
          </Stack>
          <Stack height={30}>
            <Button
              variant='contained'
              color='primary'
              style={{ height: '40px', marginLeft: 10 }}
              onClick={getExternalCompanyDetails}
            >
              {t('buttons.search')}
            </Button>
          </Stack>
        </Stack>
        <Controller
          control={control}
          defaultValue={''}
          name='mifOBligation'
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...fieldProps(t('labels.mifOBligation'), ref, field)}
              error={!!errors.mifOBligation}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='doy'
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t('labels.doy'), ref, field)} error={!!errors.doy} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='gemh'
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t('labels.gemh'), ref, field)} error={!!errors.gemh} />
          )}
        />
        <LookupSelect
          margin='normal'
          control={control}
          path={'tax'}
          lookupUrl={'lookup/view'}
          label={t('labels.taxSystem')}
          name='taxSystemId'
          errors={errors}
        />
        <AddressList control={control} />
        <ContactList control={control as any} />
      </Stack>
    </Paper>
  )
}
