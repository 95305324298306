import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { InvoicePurchase } from '../../models/invoice/InvoicePurchase'

export interface IuseInvoicePurchaseProps {
  invoiceLoader: boolean
  invoice?: InvoicePurchase
  createInvoice: any
  resetInvoice: any
  disableForm: boolean
}

export function useInvoicePurchase(id?: string): IuseInvoicePurchaseProps {
  const [invoiceLoader, setLoading] = useState(false)
  const [invoice, setInvoice] = useState<InvoicePurchase>()
  const [disableForm, setDisableForm] = useState<boolean>(false)
  const service = useBaseService('invoice')
  const navigate = useNavigate()

  useEffect(() => {
    if (!id || id === 'new') {
      setInvoice({
        invoiceDate: dayjs(Date.now()),
        items: []
      } as any)
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<InvoicePurchase>(id, `purchase`)
      if (response) {
        setInvoice(response)
        setDisableForm(true)
      }
      setLoading(false)
    }
    getById(id)
  }, [id, service, setLoading])

  const resetInvoice = () => {
    setInvoice({
      invoiceDate: dayjs(Date.now()),
      items: []
    } as any)
  }

  const createInvoice = useCallback(
    async (entity: InvoicePurchase) => {
      setLoading(true)
      const response = await service.create(entity, `/purchase`)
      if (response) {
        navigate(`/manager/invoices/purchases/${response.id}`)
        setInvoice(response)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    invoiceLoader,
    disableForm,
    invoice,
    resetInvoice,
    createInvoice
  }
}
