import { ProductPropertyDto } from './ProductProperty'
import { ProductImageDto } from './ProductImage'
import { InventoryDto } from './Inventory'

export class ProductOverviewDto {
  stalId: string
  lowerPrice: number
  higherPrice: number
  selectedDescriptionEn: string
  selectedDescriptionGr: string
  productTypeId: string
  productTypeKey: string
  productTypeLabelEn: string
  productTypeLabelGr: string
  isReplacementPart: boolean
  totalInventory: InventoryDto[]
  properties: ProductPropertyDto[]
  selectedImages: ProductImageDto[]
}

export class ProductEditDto {
  id: string
  created: Date
  lastUpdated: Date
  stalId: string
  code: string
  productTypeId: string
  descriptionEn: string
  descriptionGr: string
  isReplacementPart: boolean
  visibleToEshop: boolean
  replacementFor: ProductLightDto[]
  replacementParts: ProductLightDto[]
  outOfProduction: boolean
  inventory: InventoryDto[]
  properties: ProductPropertyDto[]
  images: ProductImageDto[]
}

export class ProductTableDto {
  id: string
  created: Date
  productTypeEn: string
  productTypeGr: string
  descriptionEn: string
  descriptionGr: string
  totalItems: number
  stalId: string
  initialPrice: number
  code: string
  manufacturerName: string
  visibleToEshop: boolean
  outOfProduction: boolean
}

export class ProductLightDto {
  id: string
  code: string
  description: string
  stalId: string
  productTypeEn: string
  productTypeGr: string
  manufacturerName: string
  selected: boolean

  constructor(dto: ProductTableDto) {
    this.id = dto.id
    this.code = dto.code
    this.stalId = dto.stalId
    this.manufacturerName = dto.manufacturerName
    this.productTypeGr = dto.productTypeGr
    this.productTypeEn = dto.productTypeEn
    this.selected = false
  }

  public isSelected = () => (this.selected = true)

  public isUnSelected = () => (this.selected = false)
}

export interface IProductFilters {
  code: string
  description: string
  productTypeId: string
}
