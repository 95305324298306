import { useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useProductType } from '../useProductType'
import { ProductTypeDto } from '../../../models/product/ProductType'
import { Loader } from '../../../components/loader/loader'
import { useForm } from 'react-hook-form'
import { ProductTypeDetails } from './ProductTypeDetails'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'
import { SideActions } from '../../../components/material/SideActions'
import { Button } from '@mui/material'

export const ProductTypeContainer: React.FC<any> = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const {
    productTypeLoader,
    createProductType,
    updateProductType,
    productType,
    deleteProductType
  } = useProductType(id)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ProductTypeDto>({
    values: { ...productType } as ProductTypeDto,
    reValidateMode: 'onChange',
    resetOptions: {
      keepDirtyValues: true // keep dirty fields unchanged, but update defaultValues
    }
  })

  useEffect(() => {
    reset({ ...productType })
  }, [reset, productType])

  async function handleRemove() {
    if (productType?.id) {
      setConfirmationModal(true)
    }
  }

  const confirmDeletion = async () => {
    var success = await deleteProductType(productType?.id as string)
    if (success) {
      navigation('/manager/productkeys')
    }
  }
  function onSubmit(data: any) {
    if (data.id) updateProductType(data)
    else createProductType(data)
  }

  if (productTypeLoader) return <Loader isFullscreen type={'circles'} />
  return (
    <>
      <ConfirmationModal
        open={confirmationModal}
        title={t('messages.deleteEntity', {
          entityName: productType?.typeKey
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={confirmDeletion}
      />
      <Grid container spacing={3}>
        <Grid xs={9}>
          <ProductTypeDetails control={control} productType={productType} errors={errors} />
        </Grid>
        <Grid xs={3}>
          <SideActions
            isTransparent
            elevation={0}
            buttons={[
              <Button
                key={0}
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                {t('buttons.update')}
              </Button>,
              <Button
                key={1}
                variant='outlined'
                fullWidth
                color='primary'
                onClick={() => navigation('/manager/settings/productTypes')}
              >
                {t('buttons.back')}
              </Button>,
              <Button key={2} fullWidth variant='contained' color='error' onClick={handleRemove}>
                {t('buttons.remove')}
              </Button>
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
