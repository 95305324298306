import { Typography } from '@mui/material'
import { Stack } from '@mui/system'

export interface IDisplayInfoProps {
  label: string | any
  value: string | number | JSX.Element
  labelFontSize?: string | number
  valueFontSize?: string | number
  justifyContent?: string
  margintop?: number
  marginBottom?: number
  valueSuffix?: string | null
}
export const DisplayInfo = ({
  label,
  value,
  marginBottom = 1,
  margintop = 0,
  labelFontSize = '1rem',
  justifyContent = 'flex-start',
  valueFontSize = '1rem',
  valueSuffix = null
}: IDisplayInfoProps) => {
  if (valueSuffix) {
    value = `${value} ${valueSuffix}`
  }

  return (
    <Stack
      direction={'row'}
      justifyContent={justifyContent}
      alignItems='center'
      spacing={1}
      marginBottom={marginBottom}
      marginTop={margintop}
    >
      <Stack>
        <Typography fontSize={labelFontSize} color={'#696969'} variant='body1' fontWeight={500}>
          {label}:
        </Typography>
      </Stack>
      <Stack>
        {value instanceof String || typeof value === 'string' ? (
          <Typography
            fontSize={valueFontSize}
            variant='body2'
            textOverflow={'ellipsis'}
            flexWrap={'wrap'}
            whiteSpace={'nowrap'}
            fontWeight={300}
          >
            {value}
          </Typography>
        ) : (
          value
        )}
      </Stack>
    </Stack>
  )
}
