import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Loader } from '../../../components/loader/loader'
import { SideActions } from '../../../components/material/SideActions'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useManufacturer } from '../useManufacturer'
import { ManufacturerDto } from '../../../models/Manufacturer'
import { ManufacturerDetails } from './ManufacturerDetails'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'

export const ManufacturerContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const {
    manufacturerLoader,
    createManufacturer,
    updateManufacturer,
    manufacturer,
    deleteManufacturer
  } = useManufacturer(id)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ManufacturerDto>({
    values: { ...manufacturer } as ManufacturerDto,
    reValidateMode: 'onChange',
    resetOptions: {
      keepDirtyValues: true // keep dirty fields unchanged, but update defaultValues
    }
  })
  useEffect(() => {
    reset({ ...manufacturer })
  }, [reset, manufacturer])

  async function handleRemove() {
    if (manufacturer?.id) {
      setConfirmationModal(true)
    }
  }

  const confirmDeletion = async () => {
    var success = await deleteManufacturer(manufacturer?.id as string)
    if (success) {
      navigation('/manager/manufacturers')
    }
  }
  function onSubmit(data: any) {
    if (data.id) updateManufacturer(data)
    else createManufacturer(data)
  }

  if (manufacturerLoader) return <Loader isFullscreen type={'circles'} />
  return (
    <>
      <ConfirmationModal
        open={confirmationModal}
        title={t('messages.deleteEntity', {
          entityName: manufacturer?.name
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={confirmDeletion}
      />
      <Grid container spacing={3}>
        <Grid xs={9}>
          <ManufacturerDetails control={control} entity={manufacturer} errors={errors} />
        </Grid>
        <Grid xs={3}>
          <SideActions
            isTransparent
            elevation={0}
            buttons={[
              <Button
                key={0}
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                {t('buttons.update')}
              </Button>,
              <Button
                key={1}
                variant='outlined'
                fullWidth
                color='primary'
                onClick={() => navigation('/manager/settings/manufacturers')}
              >
                {t('buttons.back')}
              </Button>,
              <Button key={2} fullWidth variant='contained' color='error' onClick={handleRemove}>
                {t('buttons.remove')}
              </Button>
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
