import { Chip, Paper } from '@mui/material'

interface ILabelCardProps {
  children: any
  label?: any
  color?: 'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning'
  variant?: 'filled' | 'outlined'
  icon?: any
  size?: 'small' | 'medium'
}
export const LabelCard = ({
  children,
  color = 'primary',
  variant = 'filled',
  label,
  size = 'small',
  icon = null
}: ILabelCardProps) => {
  return (
    <Paper elevation={1} sx={{ height: '100%' }}>
      <Chip
        style={{ marginLeft: '10px', marginTop: '-10px' }}
        size={size}
        label={label}
        color={color}
        variant={variant}
        icon={icon}
      />
      {children}
    </Paper>
  )
}
