import { ContactDto, IContactInfo } from './Common'

export class CustomerTableDto {
  id: string
  fullname: string
  address: string
  occupation: string
  city: string
  doy: string
  postalCode: string
  tin: string
  country: string
  countryCodeNo: number
  taxRate: number
  currentDebt: number
  mobiles: string[]
  emails: string[]
}

export class CustomerDetailsDto implements IContactInfo {
  id: string
  fullname: string
  occupation: string
  taxInfoId: string
  creditLimit: number
  creditDays: number
  comments: string
  personInCharge: string
  creditBalance: number
  discount: number
  customerType: CustomerType
  created: Date
  updated: Date
  taxSystemId: string
  taxSystem: string
  tin: string
  gemh: string
  doy: string
  mifOBligation: string
  addresses: AddressDto[]
  contactInfo: ContactDto[]
}

export class TaxInformationDetailsDto {
  taxSystemId: string
  tin: string
  doy: string
  taxSystem: string
}

export class CustomerTransactionDto {
  manualProtocolNumber: string
  amount: number
  customerId: string
  comments: string
  id: string
  created: Date
}

export enum TransactionCategory {
  Transaction,
  Sale
}

export enum TransactionType {
  Credit,
  Cash
}

export class CustomerTranInvoiceDto {
  id: string
  protocolNumber: string
  descriptionEn: string
  descriptionGr: string
  transactionCategory: TransactionCategory
  transactionType: TransactionType
  amount: number
  created: Date
}

export class AddressDto {
  id: string
  type: AddressType
  street: string
  city: string
  region: string
  postalCode: string
  countryId: string
  countryEn: string
  countryGr: string
  countryCode: string
  countryCodeNo: number
}

export enum AddressType {
  Home,
  Work,
  Logistics,
  Other
}

export enum CustomerType {
  Company,
  Individual
}
