import { useState, useEffect } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { CustomerTranInvoiceDto } from '../../models/Customer'

export const useCustomerInvoices = (id: string) => {
  const [loading, setLoading] = useState(0)
  const [invoices, setInvoices] = useState<CustomerTranInvoiceDto[]>([])
  const service = useBaseService('customer')

  useEffect(() => {
    if (!service) return

    async function fetchData() {
      setLoading((pr) => pr + 1)
      const response = await service.getAll<CustomerTranInvoiceDto>(`transactions/${id}`)

      if (response) setInvoices((history) => [...history, ...response])
      setLoading((pr) => pr - 1)
    }

    fetchData()
  }, [service, id])

  useEffect(() => {
    if (!service) return

    async function fetchData() {
      setLoading((pr) => pr + 1)
      const response = await service.getAll<CustomerTranInvoiceDto>(`invoices/${id}`)
      if (response) setInvoices((history) => [...history, ...response])
      setLoading((pr) => pr - 1)
    }

    fetchData()
  }, [service, id])
  return {
    invoices,
    loading: loading !== 0,
    setInvoices
  }
}
