import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2'
import { ProductEditDto } from '../../../models/product/Product'
import { useForm } from 'react-hook-form'
import { SideActions } from '../../../components/material/SideActions'
import { Button } from '@mui/material'
import { ProductDetails } from './ProductDetails'
import { useProduct } from '../useProduct'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'
import { useEffect, useState } from 'react'
import { Loader } from '../../../components/loader/loader'
import ProductImages from './ProductImages'
import { ProductImageUpload } from './ProductImageUpload'

export const ProductContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const {
    product,
    productLoader,
    updateProduct,
    createProduct,
    deleteProduct,
    removeImage,
    uploadImage
  } = useProduct(id)
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<ProductEditDto>({
    defaultValues: { ...product } as ProductEditDto,
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (id === 'new') {
      reset({ properties: [], inventory: [] })
    }
  }, [id, reset])

  useEffect(() => {
    reset({ ...product })
  }, [reset, product])

  async function handleRemove() {
    if (product?.id) setConfirmationModal(true)
  }

  function onSubmit(data: any) {
    if (data.id) updateProduct(data)
    else createProduct(data)
  }

  const confirmDeletion = async () => {
    var success = await deleteProduct(product?.id as string)
    if (success) navigation('/manager/products')
  }
  if (productLoader) return <Loader isFullscreen type={'circles'} />

  return (
    <>
      <ConfirmationModal
        open={confirmationModal}
        title={t('messages.deleteEntity', {
          entityName: product?.descriptionEn
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={confirmDeletion}
      />
      <Grid container spacing={3}>
        <Grid xs={9}>
          <ProductDetails control={control} product={product} watch={watch} errors={errors} />
        </Grid>
        <Grid xs={3}>
          <SideActions
            isTransparent
            elevation={0}
            buttons={[
              <Button
                key={0}
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                {t('buttons.update')}
              </Button>,
              <Button
                key={1}
                variant='outlined'
                fullWidth
                color='primary'
                onClick={() => navigation('/manager/products')}
              >
                {t('buttons.back')}
              </Button>,
              <Button key={2} fullWidth variant='contained' color='error' onClick={handleRemove}>
                {t('buttons.remove')}
              </Button>,
              id !== 'new' ? <ProductImageUpload key={3} id={id} uploadImage={uploadImage} /> : null
            ]}
          />
          <ProductImages productId={id} images={product?.images} removeImage={removeImage} />
        </Grid>
      </Grid>
    </>
  )
}
