import {
  PersonOutline,
  PersonAddAlt,
  QrCode2,
  Add,
  LogoutOutlined,
  Settings,
  ReceiptRounded
} from '@mui/icons-material'

export const profileItems = [
  {
    label: 'sidebar.settings',
    icon: <Settings style={{ color: 'whitesmoke' }} />,
    path: 'settings'
  },
  {
    label: 'sidebar.logout',
    icon: <LogoutOutlined style={{ color: 'whitesmoke' }} />,
    path: '/manager/logout'
  }
]
export const sidebarItems = [
  {
    label: 'sidebar.customers',
    subMenu: [
      {
        label: 'sidebar.customer',
        icon: <PersonOutline />,
        path: 'customers'
      },
      {
        label: 'sidebar.add',
        icon: <PersonAddAlt />,
        path: 'customers/new'
      }
    ]
  },
  {
    label: 'sidebar.invoices',
    subMenu: [
      {
        label: 'sidebar.invoiceSales',
        icon: <ReceiptRounded />,
        path: 'invoices/sales'
      },
      {
        label: 'sidebar.invoicePurchases',
        icon: <ReceiptRounded />,
        path: 'invoices/purchases'
      },
      {
        label: 'sidebar.newSale',
        icon: <Add />,
        path: 'invoices/sales/new'
      },
      {
        label: 'sidebar.newPurchase',
        icon: <Add />,
        path: 'invoices/purchases/new'
      }
    ]
  },
  {
    label: 'sidebar.products',
    subMenu: [
      {
        label: 'sidebar.products',
        icon: <QrCode2 />,
        path: 'products'
      },
      {
        label: 'sidebar.add',
        icon: <Add />,
        path: 'products/new'
      }
    ]
  },
  {
    label: 'sidebar.users',
    subMenu: [
      {
        label: 'sidebar.user',
        icon: <PersonOutline />,
        path: 'users'
      },
      {
        label: 'sidebar.add',
        icon: <PersonAddAlt />,
        path: 'users/new'
      }
    ]
  }
]
