import { ColorRing, MagnifyingGlass, ThreeCircles } from 'react-loader-spinner'
import './loader.sass'
import { Stack } from '@mui/material'

const size = { width: '60', height: '60' }
const Circles = (
  <ThreeCircles
    height={size.height}
    width={size.width}
    wrapperClass={'is-block'}
    visible={true}
    ariaLabel='three-circles-rotating'
    outerCircleColor='#11192a'
    innerCircleColor='orange'
    middleCircleColor='#11192a'
  />
)

const Ring = (
  <ColorRing
    visible={true}
    height={size.height}
    width={size.width}
    ariaLabel='blocks-loading'
    wrapperStyle={{}}
    wrapperClass='blocks-wrapper'
    colors={['orange', '#11192a', 'orange', '#11192a', 'orange']}
  />
)

const Search = (
  <MagnifyingGlass
    visible={true}
    height={size.height}
    width={size.width}
    ariaLabel='MagnifyingGlass-loading'
    wrapperStyle={{}}
    wrapperClass='MagnifyingGlass-wrapper'
    glassColor='#ffdb2b'
    color='#11192a'
  />
)
export interface ILoaderProps {
  type: 'circles' | 'ring' | 'search'
  isFullscreen?: boolean
  noText?: boolean
  pad?: string
}

export const Loader = ({ type, pad = '0', isFullscreen = false, noText = false }: ILoaderProps) => {
  let loaderStyle: any = Circles
  switch (type) {
    case 'circles':
      loaderStyle = Circles
      break
    case 'ring':
      loaderStyle = Ring
      break
    case 'search':
      loaderStyle = Search
      break
  }
  let classess = 'has-text-centered my-5'
  if (isFullscreen) classess += ' loader-fullscreen-container'
  return (
    <Stack
      height={'100%'}
      direction='row'
      justifyContent='center'
      alignItems='center'
      className={classess}
      padding={pad}
      spacing={0}
    >
      <div style={{ flex: 1 }}>
        {loaderStyle}
        {!noText && <p>Παρακαλώ Περιμένετε</p>}
      </div>
    </Stack>
  )
}
