import { useNavigate } from 'react-router-dom'
import { useProductType } from '../useProductType'
import { useProductTypes } from '../useProductTypes'
import { useTranslation } from 'react-i18next'
import { ProductTypeDto } from '../../../models/product/ProductType'
import { useState } from 'react'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { BorderColor, DeleteOutline } from '@mui/icons-material'
import { Button, ButtonGroup, IconButton } from '@mui/material'

export const ProductTypeTable = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loading, productTypes, removeProductType } = useProductTypes()
  const { productTypeLoader, deleteProductType } = useProductType()
  const [selectedEntity, setSelectedEntity] = useState<ProductTypeDto | null>(null)

  const openDialog = (entity: ProductTypeDto) => {
    setSelectedEntity(entity)
  }

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: 'Name',
      field: 'typeKey',
      flex: 1
    },
    {
      headerName: 'Τίτλος El',
      field: 'labelGr',
      flex: 1,
      filterable: true
    },
    {
      headerName: 'Τίτλος En',
      field: 'labelEn',
      flex: 1
    },
    {
      headerName: 'Ενέργειες',
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => navigate(params.row.id)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
          <IconButton color='error' onClick={() => openDialog(params.row)}>
            <DeleteOutline fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]

  const confirmDeletion = async () => {
    var success = await deleteProductType(selectedEntity?.id as string)
    if (success) {
      removeProductType(selectedEntity?.id as string)
      setSelectedEntity(null)
    }
  }
  return (
    <>
      <ConfirmationModal
        open={selectedEntity != null}
        title={t('messages.deleteEntity', {
          entityName: selectedEntity?.typeKey
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setSelectedEntity(null)}
        onConfirm={confirmDeletion}
        loading={productTypeLoader}
      />
      <MiDatagrid
        title={t('labels.productTypes')}
        actions={
          <Button color={'primary'} size='small' variant='outlined' onClick={() => navigate('new')}>
            {t('buttons.add')}
          </Button>
        }
        data={productTypes}
        columns={columns}
        disableColumnMenu={false}
        loading={loading}
      />
    </>
  )
}
