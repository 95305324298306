import { useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { ProductTypeDto } from '../../models/product/ProductType'

export interface IUseProductTypes {
  loading: boolean
  productTypes: ProductTypeDto[]
  removeProductType: (id: string) => void
}

export function useProductTypes(): IUseProductTypes {
  const [loading, setLoading] = useState(true)
  const [productTypes, setProductTypes] = useState<ProductTypeDto[]>([])
  const service = useBaseService('productType')

  const removeProductType = (id: string) => {
    setProductTypes(productTypes?.filter((x) => x.id !== id))
  }
  useEffect(() => {
    if (!service) return

    async function fetchProductTypes() {
      setLoading(true)

      const response = await service.getAll<ProductTypeDto>()
      if (response) setProductTypes(response)
      setLoading(false)
    }

    fetchProductTypes()
  }, [service])

  return {
    loading,
    productTypes,
    removeProductType
  }
}
