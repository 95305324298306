import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import { ProductKeyDto } from '../../models/product/ProductKey'

export interface IUseProductKeyProps {
  productKeyLoader: boolean
  productKey?: ProductKeyDto
  createProductKey: any
  updateProductKey: any
  deleteProductKey: (id: string) => Promise<boolean>
}

export function useProductKey(id?: string): IUseProductKeyProps {
  const [productKeyLoader, setLoading] = useState(false)
  const [productKey, setProductKey] = useState<ProductKeyDto>()
  const service = useBaseService('productKey')
  const navigate = useNavigate()

  const updateProductKey = useCallback(
    async (productKey: ProductKeyDto) => {
      setLoading(true)
      const response = await service.update<ProductKeyDto>(productKey, productKey.id)
      if (response) setProductKey(response)
      setLoading(false)
    },
    [service]
  )

  const deleteProductKey = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await service.remove(id)
      setLoading(false)
      return response === true
    },
    [service]
  )

  useEffect(() => {
    if (!id || id === 'new') {
      setProductKey({
        keyName: '',
        labelEn: '',
        labelGr: '',
        notes: ''
      } as any)
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<ProductKeyDto>(id)
      if (response) setProductKey(response)
      setLoading(false)
    }
    getById(id)
  }, [id, service, setLoading])

  const createProductKey = useCallback(
    async (product: ProductKeyDto) => {
      setLoading(true)
      const response = await service.create(product)
      if (response) {
        setProductKey(response)
        navigate(`/manager/settings/productkeys/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    productKeyLoader,
    productKey,
    updateProductKey,
    createProductKey,
    deleteProductKey
  }
}
