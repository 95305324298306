import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SimpleSelect } from '../../../components/lookupSelect/SimpleSelect'
import { ISimpleLookup, useLookup } from '../../../services/useLookup'
import { InventoryDto } from '../../../models/product/Inventory'

export interface IProductInvetoryModalProps {
  entity?: any
  onConfirm: any
  onCancel: any
}
export const ProductInvetoryModal = ({
  onCancel,
  onConfirm,
  entity
}: IProductInvetoryModalProps) => {
  const { t } = useTranslation()
  const { loading: wLoader, lookup: wlookup } = useLookup<ISimpleLookup>('warehouse', 'lookup/view')
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<InventoryDto>({
    defaultValues: entity
  })
  const sumbitForm = (data: InventoryDto) => {
    let wlabel = wlookup.find((x) => x.id === data.warehouseId)
    onConfirm({ ...data, warehouseName: wlabel?.valueGr })
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle id='alert-dialog-title'>{t('labels.availability')}</DialogTitle>
      <DialogContent style={{ marginTop: '0.5rem' }}>
        <SimpleSelect
          control={control}
          data={wlookup}
          loading={wLoader}
          margin='1rem 0 0.5rem'
          label={t('labels.warehouse')}
          name='warehouseId'
          errors={errors}
        />
        <Controller
          control={control}
          defaultValue=''
          name='self'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField
              margin='normal'
              inputRef={ref}
              {...field}
              size='small'
              fullWidth
              error={!!errors.self}
              label={t('labels.shelf')}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={0}
          name='quantity'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField
              inputRef={ref}
              {...field}
              margin='normal'
              size='small'
              fullWidth
              error={!!errors.quantity}
              label={t('labels.quantity')}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={0}
          name='price'
          render={({ field: { ref, ...field } }) => (
            <TextField
              margin='normal'
              type='number'
              inputRef={ref}
              {...field}
              fullWidth
              size='small'
              error={!!errors.price}
              label={t('labels.price')}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={0}
          name='suggestedSellingPrice'
          render={({ field: { ref, ...field } }) => (
            <TextField
              margin='normal'
              type='number'
              inputRef={ref}
              {...field}
              fullWidth
              size='small'
              error={!!errors.suggestedSellingPrice}
              label={t('labels.suggestedSellingPrice')}
            />
          )}
        />
        <Controller
          control={control}
          name='status'
          defaultValue={0}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl fullWidth margin='normal' size='small'>
              <InputLabel id='demo-simple-select-label'>{t('labels.status')}</InputLabel>
              <Select {...field} fullWidth error={!!errors.status} label={t('labels.status')}>
                <MenuItem key={0} value={0}>
                  NEW
                </MenuItem>
                <MenuItem key={1} value={1}>
                  REPAIRED
                </MenuItem>
                <MenuItem key={2} value={2}>
                  TOBEREPAIRED
                </MenuItem>
                <MenuItem key={3} value={3}>
                  OTHER
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        <Button color='success' onClick={handleSubmit(sumbitForm)} autoFocus>
          {t('buttons.insert')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
