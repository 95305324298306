import { Control, Controller, FieldErrors } from 'react-hook-form'
import { ProductEditDto } from '../../../models/product/Product'
import { useTranslation } from 'react-i18next'
import { Paper, Stack, TextField, Switch, Typography } from '@mui/material'
import { LookupSelect } from '../../../components/lookupSelect/LookupSelect'
import { PaperHeader } from '../../../components/paperHeader/PaperHeader'
import { ProductProperties } from './ProductProperties'
import { ProductInvetory } from './ProductInvetory'
import { ProductsForList } from './ProductsForList'
import { ProductPartList } from './ProductsPartsList'

export interface IProductDetailsProps {
  product?: ProductEditDto
  errors: FieldErrors<ProductEditDto>
  control: Control<ProductEditDto, any>
  watch: any
}

export const ProductDetails = ({ product, errors, control, watch }: IProductDetailsProps) => {
  const { t } = useTranslation()
  const isReplacementPart = watch('isReplacementPart')
  return (
    <Paper elevation={1}>
      <Stack padding={2}>
        <PaperHeader label={product?.id ? t('labels.editRecord') : t('labels.newRecord')} />
        <Stack direction='row' spacing={2} marginBottom={2}>
          <LookupSelect
            control={control}
            path={'producttype'}
            lookupUrl={'lookup/view'}
            label={t('labels.productType')}
            name='productTypeId'
            errors={errors}
          />
          <LookupSelect
            control={control}
            path={'manufacturer'}
            lookupUrl={'lookup/view'}
            label={t('labels.manufacturer')}
            name='manufacturerId'
            errors={errors}
          />
        </Stack>
        <Stack direction='row' spacing={2} marginBottom={2}>
          <Controller
            control={control}
            defaultValue={''}
            name='descriptionEn'
            render={({ field: { ref, ...field } }) => (
              <TextField
                type='text'
                inputRef={ref}
                {...field}
                fullWidth
                size='small'
                error={!!errors.descriptionEn}
                label={t('labels.descriptionEn')}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={''}
            name='descriptionGr'
            render={({ field: { ref, ...field } }) => (
              <TextField
                type='text'
                inputRef={ref}
                {...field}
                fullWidth
                size='small'
                error={!!errors.descriptionGr}
                label={t('labels.descriptionGr')}
              />
            )}
          />
        </Stack>
        <Stack direction='row' spacing={2} marginBottom={2}>
          <Controller
            control={control}
            defaultValue={''}
            name='code'
            render={({ field: { ref, ...field } }) => (
              <TextField
                type='text'
                inputRef={ref}
                {...field}
                fullWidth
                size='small'
                error={!!errors.code}
                label={t('labels.code')}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue=''
            name='stalId'
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                inputRef={ref}
                {...field}
                fullWidth
                size='small'
                error={!!errors.stalId}
                label={t('labels.stalid')}
              />
            )}
          />
        </Stack>
        <Stack direction='row' spacing={2} marginBottom={2}>
          <Controller
            control={control}
            defaultValue={false}
            name='isReplacementPart'
            render={({ field: { onChange, value } }) => (
              <Stack direction='row' spacing={1} flex={1} alignItems='center'>
                <Typography>{t('labels.isReplacementPart')}</Typography>
                <Switch
                  checked={value}
                  onChange={onChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            )}
          />
        </Stack>
        <Stack direction='row' spacing={2} marginBottom={2}>
          <Controller
            control={control}
            defaultValue={false}
            name='visibleToEshop'
            render={({ field: { ref, onChange, value } }) => (
              <Stack direction='row' spacing={1} flex={1} alignItems='center'>
                <Typography>{t('labels.showEshop')}</Typography>
                <Switch
                  checked={value}
                  onChange={onChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            )}
          />
          <Controller
            control={control}
            defaultValue={false}
            name='outOfProduction'
            render={({ field: { onChange, value } }) => (
              <Stack flex={1} direction='row' spacing={1} alignItems='center'>
                <Typography>{t('labels.outOfProuction')}</Typography>
                <Switch
                  checked={value}
                  onChange={onChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            )}
          />
        </Stack>
        <ProductInvetory control={control} />
        {isReplacementPart && <ProductsForList control={control} />}
        <ProductPartList data={product?.replacementParts as any} />
        <ProductProperties control={control} />
      </Stack>
    </Paper>
  )
}
