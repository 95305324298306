import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
  Button
} from '@mui/material'
import { AddressDto, CustomerDetailsDto } from '../../../models/Customer'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { contactTypeIcon } from '../contact/ContactList'
import { addressTypeIcon } from '../address/AddressList'
import { useNavigate } from 'react-router-dom'
import { DisplayInfo } from '../../../components/commonViews/DisplayInfo'
import { ContactDto } from '../../../models/Common'
import { CustomerTypeChip } from '../../../components/chips/CustomerTypeChip'

export interface ICustomerOverviewDetailsProps {
  customer: CustomerDetailsDto
}

export const CustomerOverviewDetails = ({ customer }: ICustomerOverviewDetailsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Paper elevation={1}>
      <Stack padding={2} textAlign={'center'}>
        <Typography fontWeight={600} color={'primary'} variant='h6'>
          {customer?.fullname}
        </Typography>
        <Typography variant='body2'>
          {t('labels.memberFrom')} {moment(customer?.created).format('DD/MM/YYYY')}
        </Typography>
        <span style={{ marginBottom: '2em' }}>
          <CustomerTypeChip type={customer.customerType} />
        </span>
      </Stack>
      <Stack padding={2} textAlign={'left'}>
        <Typography fontWeight={600} variant='body1'>
          {t('labels.details')}
        </Typography>
        <Divider style={{ margin: '.5em 0' }} />
        <DisplayInfo
          labelFontSize={'.9rem'}
          valueFontSize={'.9rem'}
          label={t('labels.occupation')}
          value={customer.occupation}
        />
        <DisplayInfo
          labelFontSize={'.9rem'}
          valueFontSize={'.9rem'}
          label={t('labels.tin')}
          value={customer.tin}
        />
        <DisplayInfo
          labelFontSize={'.9rem'}
          valueFontSize={'.9rem'}
          label={t('labels.taxSystem')}
          value={customer.taxSystem}
        />
        <DisplayInfo
          labelFontSize={'.9rem'}
          valueFontSize={'.9rem'}
          label={t('labels.doy')}
          value={customer.doy}
        />
        <DisplayInfo
          labelFontSize={'.9rem'}
          valueFontSize={'.9rem'}
          label={t('labels.debt')}
          valueSuffix={'€'}
          value={customer.creditBalance}
        />
        <DisplayInfo
          labelFontSize={'.9rem'}
          valueFontSize={'.9rem'}
          label={t('labels.creditDays')}
          value={customer.creditDays}
        />
        <DisplayInfo
          labelFontSize={'.9rem'}
          valueFontSize={'.9rem'}
          label={t('labels.creditLimit')}
          valueSuffix={'€'}
          value={customer.creditLimit}
        />
        <DisplayInfo
          labelFontSize={'.9rem'}
          valueFontSize={'.9rem'}
          label={t('labels.discount')}
          valueSuffix={'%'}
          value={customer.discount}
        />
        <Typography fontSize={'.9rem'} color={'#696969'} variant='body1' fontWeight={500}>
          {t('labels.comments')}
        </Typography>
        <Typography fontSize={'.9rem'} variant='body2' fontWeight={300} textAlign={'justify'}>
          {customer.comments}
        </Typography>
        <List disablePadding>
          {customer.contactInfo?.map((info: ContactDto, i: number) => (
            <ListItem
              style={{ marginBottom: 2 }}
              key={i}
              disablePadding
              sx={{ '.MuiListItemIcon-root': { minWidth: '40px' } }}
            >
              <ListItemIcon>{contactTypeIcon(info.type)}</ListItemIcon>
              <ListItemText
                secondaryTypographyProps={{ fontSize: '.8rem' }}
                primaryTypographyProps={{ fontSize: '.8rem' }}
                id={`checkbox-list-secondary-label-${i}`}
                primary={`${info.contactValue} `}
                secondary={`${info.details}`}
              />
            </ListItem>
          ))}
        </List>
        <List disablePadding>
          {customer.addresses?.map((info: AddressDto, i: number) => (
            <ListItem sx={{ '.MuiListItemIcon-root': { minWidth: '40px' } }} key={i} disablePadding>
              <ListItemIcon>{addressTypeIcon(info.type)}</ListItemIcon>
              <ListItemText
                secondaryTypographyProps={{ fontSize: '.8rem' }}
                primaryTypographyProps={{ fontSize: '.8rem' }}
                id={`checkbox-list-secondary-label-${i}`}
                primary={`${info.countryGr} - ${info.region} - ${info.city} `}
                secondary={`${info.street}, ${info.postalCode}`}
              />
            </ListItem>
          ))}
        </List>
      </Stack>
      <Stack padding={2} direction={'row'} spacing={1} justifyContent='center'>
        <Button
          variant='contained'
          size='medium'
          color='warning'
          onClick={() => navigate(`/manager/customers/${customer.id}`)}
        >
          {t('buttons.edit')}
        </Button>
      </Stack>
    </Paper>
  )
}
