import Grid from '@mui/material/Unstable_Grid2'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InvoiceEditDto } from '../../../models/invoice/InvoiceSale'
import { useInvoice } from '../useInvoice'
import { enqueueSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { InvoiceItems } from './invoiceSections/sell/InvoiceItems'
import { InvoiceSummary } from './invoiceSections/sell/InvoiceSummary'
import { LabelCard } from '../../../components/material/LabelCard'
import { InvoiceCustomerDetails } from './invoiceSections/sell/InvoiceCustomerDetails'
import { InvoiceParastatikoDetails } from './invoiceSections/sell/InvoiceParastatikoDetails'
import { InvoicePublisherDetails } from './invoiceSections/sell/InvoicePublisherDetails'
import { PaymentMethods, TimologioLianikhs } from '../../../../utils/InvoiceExtensions'
import { InvoiceHeader } from './invoiceSections/sell/InvoiceHeader'
import { InvoiceAadeDetails } from './invoiceSections/sell/InvoiceAadeDetails'

export const InvoiceSalesContainer = () => {
  const { id } = useParams<{ id: string }>()
  const [isTimologioLianikhs, setIsTimologioLianikhs] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState(PaymentMethods)
  const { t } = useTranslation()

  const { invoice, createInvoice, disableForm, invoiceLoader } = useInvoice(id)
  const isNew = id === 'new'
  const {
    control,
    reset,
    watch,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors }
  } = useForm<InvoiceEditDto>({
    defaultValues: { ...invoice } as InvoiceEditDto,
    reValidateMode: 'onChange'
  })

  const publish = async (data: InvoiceEditDto) => {
    if (disableForm) return
    if (data.items.length === 0) {
      enqueueSnackbar(t('messages.noInvoicesItems'), { variant: 'info' })
      return
    }
    await createInvoice(data)
  }

  useEffect(() => {
    if (isNew) {
      reset({})
    }
  }, [isNew, reset])

  useEffect(() => {
    reset({ ...invoice })
  }, [reset, invoice])

  useEffect(() => {
    const subscription = watch(async (value, { name }) => {
      if (name === 'typeId' && value.typeId === TimologioLianikhs) {
        setIsTimologioLianikhs(true)
        // Set cash only for lianiki and filter credit option
        setValue('paymentMethod', 3)
        setPaymentMethods(PaymentMethods.filter((x) => x.id === 3))
      } else {
        setPaymentMethods(PaymentMethods)
      }
    })
    return () => subscription.unsubscribe()
  }, [setValue, watch])

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <InvoiceHeader
            isNew={isNew}
            disableForm={disableForm}
            invoiceLoader={invoiceLoader}
            handleSubmit={handleSubmit}
            publish={publish}
            invoice={invoice}
          />
        </Grid>
        <Grid xs={4}>
          <LabelCard
            label={t('labels.invoiceDetails')}
            children={
              <InvoiceParastatikoDetails
                disableForm={disableForm}
                control={control}
                errors={errors}
                watch={watch}
                paymentMethods={paymentMethods}
                setValue={setValue}
              />
            }
          />
        </Grid>
        <Grid xs={4}>
          <LabelCard
            label={t('labels.deliveriDetails')}
            children={
              <InvoicePublisherDetails
                isTimologioLianikhs={isTimologioLianikhs}
                disableForm={disableForm}
                control={control}
                errors={errors}
              />
            }
          />
        </Grid>
        <Grid xs={4}>
          <LabelCard
            label={t('labels.customerDetails')}
            children={
              <InvoiceCustomerDetails
                trigger={trigger}
                watch={watch}
                disableForm={disableForm}
                setValue={setValue}
                control={control}
                errors={errors}
              />
            }
          />
        </Grid>
        <Grid xs={12}>
          <LabelCard
            label={t('labels.products')}
            children={
              <InvoiceItems disableForm={disableForm} control={control} getValues={getValues} />
            }
          />
        </Grid>
        <Grid xs={12}>
          <LabelCard
            label={t('labels.summary')}
            children={
              <InvoiceSummary
                watch={watch}
                disableForm={disableForm}
                control={control}
                getValues={getValues}
              />
            }
          />
        </Grid>
        {!isNew && !invoiceLoader && (
          <Grid xs={4}>
            <LabelCard
              label={t('labels.aade')}
              children={<InvoiceAadeDetails invoice={invoice} />}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}
