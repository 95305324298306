import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import { SupplierDto } from '../../models/Supplier'

export interface ISupplierProps {
  supplierLoader: boolean
  supplier?: SupplierDto
  createSupplier: any
  updateSupplier: any
  deleteSupplier: (id: string) => Promise<boolean>
}

export function useSupplier(id?: string): ISupplierProps {
  const [supplierLoader, setLoading] = useState(false)
  const [supplier, setSupplier] = useState<SupplierDto>()
  const service = useBaseService('supplier')
  const navigate = useNavigate()

  const updateSupplier = useCallback(
    async (entity: SupplierDto) => {
      setLoading(true)
      const response = await service.update<SupplierDto>(entity, entity.id)
      if (response) setSupplier(response)
      setLoading(false)
    },
    [service]
  )

  const deleteSupplier = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await service.remove(id)
      setLoading(false)
      return response === true
    },
    [service]
  )

  useEffect(() => {
    if (!id || id === 'new') {
      setSupplier({} as any)
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<SupplierDto>(id)
      if (response) setSupplier(response)
      setLoading(false)
    }
    getById(id)
  }, [id, service, setLoading])

  const createSupplier = useCallback(
    async (entity: SupplierDto) => {
      setLoading(true)
      const response = await service.create(entity)
      if (response) {
        setSupplier(response)
        navigate(`/manager/settings/suppliers/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    supplierLoader,
    supplier,
    updateSupplier,
    createSupplier,
    deleteSupplier
  }
}
