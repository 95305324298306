import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Loader } from '../../../components/loader/loader'
import { SideActions } from '../../../components/material/SideActions'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'

import { useSupplier } from '../useSupplier'
import { SupplierDto } from '../../../models/Supplier'
import { SupplierDetails } from './SupplerDetails'

export const SupplierContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const { createSupplier, deleteSupplier, supplierLoader, updateSupplier, supplier } =
    useSupplier(id)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<SupplierDto>({
    values: { ...supplier } as SupplierDto,
    reValidateMode: 'onChange',
    resetOptions: {
      keepDirtyValues: true // keep dirty fields unchanged, but update defaultValues
    }
  })

  useEffect(() => {
    reset({ ...supplier })
  }, [reset, supplier])

  async function handleRemove() {
    if (supplier?.id) {
      setConfirmationModal(true)
    }
  }

  const confirmDeletion = async () => {
    var success = await deleteSupplier(supplier?.id as string)
    if (success) {
      navigation('/manager/settings/suppliers')
    }
  }
  function onSubmit(data: any) {
    if (data.id) updateSupplier(data)
    else createSupplier(data)
  }

  if (supplierLoader) return <Loader isFullscreen type={'circles'} />

  return (
    <>
      <ConfirmationModal
        open={confirmationModal}
        title={t('messages.deleteEntity', {
          entityName: supplier?.name
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={confirmDeletion}
      />
      <Grid container spacing={3}>
        <Grid xs={9}>
          <SupplierDetails control={control} supplier={supplier} errors={errors} />
        </Grid>
        <Grid xs={3}>
          <SideActions
            isTransparent
            elevation={0}
            buttons={[
              <Button
                key={0}
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                {t('buttons.update')}
              </Button>,
              <Button
                key={1}
                variant='outlined'
                fullWidth
                color='primary'
                onClick={() => navigation(-1)}
              >
                {t('buttons.back')}
              </Button>,
              <Button key={2} fullWidth variant='contained' color='error' onClick={handleRemove}>
                {t('buttons.remove')}
              </Button>
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
