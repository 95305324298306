import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/index.sass'
import reportWebVitals from './reportWebVitals'
import AppRouter from './app/AppRouter'
import './manager/services/requestInterceptor'
import { SnackbarProvider } from 'notistack'
// import i18n (needs to be bundled ;))
import './i18n/i18n'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <SnackbarProvider>
      <AppRouter />
    </SnackbarProvider>
  </LocalizationProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
