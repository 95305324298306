import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Button, ButtonGroup, Chip, IconButton } from '@mui/material'
import { BorderColor, DeleteOutline } from '@mui/icons-material'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import { useState } from 'react'
import { WarehouseDto } from '../../../models/Warehouse'
import { useWarehouse } from '../useWarehouse'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'
import { useData } from '../../../services/useData'

export const WarehouseTable = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loading, data, removeEntity } = useData<WarehouseDto>('warehouse')
  const { warehouseLoader = loading, deleteWarehouse } = useWarehouse()
  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseDto | null>(null)
  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Περιγραφη',
      field: 'description',
      flex: 1
    },
    {
      headerName: 'Διευθυνση',
      field: 'address',
      flex: 1
    },
    {
      headerName: 'Τυπος',
      field: 'wareHouseType',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          label={params.row.wareHouseType === 1 ? 'Σταθερή' : 'Κινητή'}
          color={params.row.wareHouseType === 1 ? 'primary' : 'success'}
        />
      )
    },
    {
      headerName: 'Ενέργειες',
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => navigate(params.row.id)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
          <IconButton color='error' onClick={() => openDialog(params.row)}>
            <DeleteOutline fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]

  const openDialog = (warehouse: WarehouseDto) => {
    setSelectedWarehouse(warehouse)
  }

  const confirmDeletion = async () => {
    var success = await deleteWarehouse(selectedWarehouse?.id as string)
    if (success) {
      removeEntity(selectedWarehouse?.id as string)
      setSelectedWarehouse(null)
    }
  }

  return (
    <>
      <ConfirmationModal
        open={selectedWarehouse != null}
        title={t('messages.deleteEntity', {
          entityName: selectedWarehouse?.name
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setSelectedWarehouse(null)}
        onConfirm={confirmDeletion}
        loading={warehouseLoader}
      />
      <MiDatagrid
        title={t('labels.warehouses')}
        actions={
          <Button color={'primary'} size='small' variant='outlined' onClick={() => navigate('new')}>
            {t('buttons.add')}
          </Button>
        }
        data={data}
        columns={columns}
        loading={loading}
      />
    </>
  )
}
