import { Control, Controller, FieldErrors } from 'react-hook-form'
import { Paper, TextField, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { ManufacturerDto } from '../../../models/Manufacturer'
import { PaperHeader } from '../../../components/paperHeader/PaperHeader'
import { ManufacturerCodeDetails } from './ManufacturerCodeDetails'
import { fieldProps } from '../../../../utils/FormExtensions'

export interface IManufacturerDetailsProps {
  entity?: ManufacturerDto
  errors: FieldErrors<ManufacturerDto>
  control: Control<ManufacturerDto, any>
}

export const ManufacturerDetails = ({ control, entity, errors }: IManufacturerDetailsProps) => {
  const { t } = useTranslation()

  return (
    <Paper elevation={1}>
      <Stack padding={2}>
        <PaperHeader
          label={entity?.id ? t('labels.editRecord') : t('labels.newRecord')}
          rightChild={
            entity?.id ? (
              <Typography variant='body2' color={'grey'}>
                {t('labels.createdAt')}
                {moment(entity?.created).format('DD/MM/YYYY , HH:mm A')}
              </Typography>
            ) : null
          }
        />
        <Controller
          control={control}
          defaultValue=''
          name='name'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t(`labels.name`), ref, field)} error={!!errors.name} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='description'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...fieldProps(t(`labels.description`), ref, field)}
              error={!!errors.description}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='site'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t(`labels.webpage`), ref, field)} error={!!errors.site} />
          )}
        />
        <ManufacturerCodeDetails id={entity?.id} />
      </Stack>
    </Paper>
  )
}
