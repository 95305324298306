import { useCallback, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { InvoiceItemDto } from '../../models/invoice/InvoiceSale'

export interface IInvoiceItemProps {
  loader: boolean
  productItems?: InvoiceItemDto[]
  searchProductToAdd: any
  clearItems: any
}

export function useInvoiceItem(): IInvoiceItemProps {
  const [loader, setLoading] = useState(false)
  const [items, setItems] = useState<InvoiceItemDto[]>()
  const service = useBaseService('product')

  const searchProductToAdd = useCallback(
    async (code: string) => {
      if (!code) return
      setLoading(true)
      const response = await service.getById<InvoiceItemDto[]>(code, `invoice`)
      if (response) {
        setItems(response.sort((a, b) => b.quantity - a.quantity))
      }
      setLoading(false)
    },
    [service]
  )

  const clearItems = () => setItems(undefined)

  return {
    loader,
    productItems: items,
    searchProductToAdd,
    clearItems
  }
}
