import {
  List,
  ListItem,
  IconButton,
  ListItemText,
  ListItemIcon,
  Stack,
  Typography,
  ButtonGroup
} from '@mui/material'
import {
  ModeEdit,
  Email,
  LocalPhone,
  Add,
  PhoneAndroid,
  Fax,
  DeleteOutline
} from '@mui/icons-material'
import { t } from 'i18next'
import { useState } from 'react'
import { Control, useFieldArray } from 'react-hook-form'
import { ContactModal } from './ContanctModal'
import { ContactDto, ContactType, IContactInfo } from '../../../models/Common'

export interface IContactListProps {
  control: Control<IContactInfo, any>
  disableDetails?: boolean
}

export const contactTypeIcon = (type: number) => {
  switch (type) {
    case ContactType.Email:
      return <Email fontSize={'small'} />
    case ContactType.Phone:
      return <LocalPhone fontSize={'small'} />
    case ContactType.Mobile:
      return <PhoneAndroid fontSize={'small'} />
    case ContactType.Fax:
      return <Fax fontSize={'small'} />
    default:
      return <LocalPhone fontSize={'small'} />
  }
}

export const ContactList = ({ control, disableDetails = false }: IContactListProps) => {
  const [contact, setContact] = useState<ContactDto | null>(null)
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'contactInfo'
  })

  const addOrUpdateEntity = (add: ContactDto) => {
    setContact(null)
    if (add.id) {
      const i = fields.findIndex((f) => f.id === add.id)
      update(i, add)
    } else append(add)
  }

  const removeEntity = (id: string) => {
    const i = fields.findIndex((f) => f.id === id)
    remove(i)
  }

  return (
    <>
      {contact != null && (
        <ContactModal
          onConfirm={addOrUpdateEntity}
          entity={contact}
          disableDetails={disableDetails}
          onCancel={() => setContact(null)}
        />
      )}
      <Stack direction={'row'} alignItems={'center'}>
        <Typography flex={1} variant='body1' color={'grey'}>
          {t('labels.contactInfo')}
        </Typography>
        <Stack>
          <IconButton color='primary' onClick={() => setContact(new ContactDto())}>
            <Add />
          </IconButton>
        </Stack>
      </Stack>
      <List dense sx={{ MuiListItemSecondaryAction: '0' }}>
        {fields?.map((value, i) => {
          const labelId = `checkbox-list-secondary-label-${i}`
          return (
            <ListItem
              sx={{
                '.MuiListItemSecondaryAction-root': {
                  right: '0px'
                },
                '.MuiListItemIcon-root': {
                  minWidth: '30px!important'
                }
              }}
              key={i}
              secondaryAction={
                <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
                  <IconButton color='primary' onClick={() => setContact(value)}>
                    <ModeEdit fontSize={'small'} />
                  </IconButton>
                  <IconButton color='error' onClick={() => removeEntity(value.id)}>
                    <DeleteOutline fontSize={'small'} />
                  </IconButton>
                </ButtonGroup>
              }
              disablePadding
            >
              <ListItemIcon>{contactTypeIcon(value.type)}</ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.contactValue} `}
                secondary={`${value.details ?? ''}`}
              />
            </ListItem>
          )
        })}
      </List>
    </>
  )
}
