import { Control, Controller, FieldErrors } from 'react-hook-form'
import { ProductTypeDto } from '../../../models/product/ProductType'
import { useTranslation } from 'react-i18next'
import { Paper, Stack, TextField, Typography } from '@mui/material'
import moment from 'moment'
import { PaperHeader } from '../../../components/paperHeader/PaperHeader'
import { fieldProps } from '../../../../utils/FormExtensions'

export interface IProductTypeDetailsProps {
  productType?: ProductTypeDto
  errors: FieldErrors<ProductTypeDto>
  control: Control<ProductTypeDto, any>
}

export const ProductTypeDetails: React.FC<IProductTypeDetailsProps> = ({
  productType,
  errors,
  control
}) => {
  const { t } = useTranslation()
  return (
    <Paper elevation={1}>
      <Stack padding={2}>
        <PaperHeader
          label={productType?.id ? t('labels.editRecord') : t('labels.newRecord')}
          rightChild={
            productType?.id ? (
              <Typography variant='body2' color={'grey'}>
                {t('labels.createdAt')}
                {moment(productType?.created).format('DD/MM/YYYY , HH:mm A')}
              </Typography>
            ) : null
          }
        />
        <Controller
          control={control}
          defaultValue=''
          name='typeKey'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t(`labels.name`), ref, field)} error={!!errors.typeKey} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='labelGr'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t(`labels.labelGr`), ref, field)} error={!!errors.labelGr} />
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='labelEn'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t(`labels.labelEn`), ref, field)} error={!!errors.labelEn} />
          )}
        />
      </Stack>
    </Paper>
  )
}
