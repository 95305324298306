import { ProductOverviewDto } from '../../../models/product/Product'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import { ProductStatusChip } from '../../../../utils/ProductStatus'
import { useTranslation } from 'react-i18next'
import { LinkCell } from '../../../../common/components/LinkCell'

interface IProductOverviewAvailabilityProps {
  product: ProductOverviewDto
}

export const ProductOverviewAvailability = ({ product }: IProductOverviewAvailabilityProps) => {
  const { t } = useTranslation()
  const columns: GridColDef[] = [
    {
      headerName: t('labels.code') as string,
      field: 'code',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <LinkCell id={params.row.code} path={`/manager/products/${params.row.productId}`} />
      )
    },
    {
      headerName: t('labels.warehouse') as string,
      field: 'warehouseName',
      flex: 1
    },
    {
      headerName: t('labels.shelf') as string,
      field: 'self',
      flex: 0
    },
    {
      headerName: t('labels.manufacturer') as string,
      field: 'manufacturerName',
      flex: 1
    },
    {
      headerName: t('labels.quantity') as string,
      field: 'quantity',
      flex: 1
    },
    {
      headerName: `${t('labels.price')} (€)` as string,
      field: 'price',
      flex: 1
    },
    {
      headerName: `${t('labels.suggestedSellingPrice')} (€)` as string,
      field: 'suggestedSellingPrice',
      flex: 1
    },
    {
      headerName: t('labels.status') as string,
      field: 'status',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <ProductStatusChip status={params.row.status} size='small' />
      )
    }
  ]
  return <MiDatagrid data={product.totalInventory} columns={columns} loading={false} />
}
