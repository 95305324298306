import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProductStatusField } from '../manager/views/product/productUtilities'
import { ProductStatus } from '../manager/models/product/Inventory'

const statusColor = (
  status: ProductStatus
): 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' => {
  if (status === ProductStatus.NEW) return 'success'
  if (status === ProductStatus.OTHER) return 'secondary'
  if (status === ProductStatus.REPAIRED) return 'warning'
  if (status === ProductStatus.TOBEREPAIRED) return 'error'
  return 'default'
}
interface IProductStatusChipProps {
  status: ProductStatus
  size?: 'small' | 'medium'
}

export const ProductStatusChip = ({ status, size = 'medium' }: IProductStatusChipProps) => {
  const { t } = useTranslation()
  return <Chip variant='filled' size={size} label={ProductStatusField(status, t)} color={statusColor(status)} />
}
