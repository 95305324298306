import { Button, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UserDto } from '../../../models/User'

interface IInvoiceHeaderProps {
  handleSubmit: any
  handleRemove: any
  user: UserDto | undefined
}
export const UserHeader = ({ handleSubmit, user, handleRemove }: IInvoiceHeaderProps) => {
  const { t } = useTranslation()
  const navigation = useNavigate()
  let label = user?.id ? t('labels.editRecord') : t('labels.newRecord')

  return (
    <Paper elevation={1}>
      <Stack
        padding={'1rem'}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Stack flex={1}>
          <Typography fontSize={23} fontWeight={300}>
            {label}
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent={'right'} flex={1} spacing={1}>
          <Button
            key={1}
            variant='outlined'
            fullWidth
            color='primary'
            onClick={() => navigation(-1)}
          >
            {t('buttons.back')}
          </Button>
          <Button
            key={2}
            fullWidth
            variant='contained'
            disabled={user?.locked == null}
            color='warning'
            onClick={handleRemove}
          >
            {t('buttons.unlock')}
          </Button>

          <Button key={2} fullWidth variant='contained' color='error' onClick={handleRemove}>
            {t('buttons.delete')}
          </Button>
          <Button key={0} fullWidth variant='contained' color='primary' onClick={handleSubmit}>
            {t('buttons.update')}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  )
}
