import TrendingUp from '@mui/icons-material/TrendingUp'
import TrendingDown from '@mui/icons-material/TrendingDown'
import TrendingFlat from '@mui/icons-material/TrendingFlat'
import { Typography } from '@mui/material'

interface IPriceTrendIconProps {
  valueBefore?: number
  valueAfter: number
}

export const PriceTrendIcon = ({ valueBefore = 0, valueAfter }: IPriceTrendIconProps) => {
  if (valueBefore === 0) {
    valueBefore = valueAfter
  }
  let icon, color, background
  const percentageIncrease = ((valueAfter - valueBefore) / valueBefore) * 100

  switch (true) {
    case percentageIncrease > 0:
      icon = <TrendingUp color='error' />
      color = 'error'
      background = '#ffc3c3'
      break
    case percentageIncrease < 0:
      icon = <TrendingDown color='success' />
      color = 'success'
      background = 'rgba(46, 125, 50, 0.3)'
      break
    default:
      icon = <TrendingFlat />
      color = undefined
      background = 'whitesmoke'
  }

  return (
    <div
      style={{
        background: background,
        width: '100%',
        height: '100%',
        lineHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
      }}
    >
      <Typography variant='subtitle1' color={color}>
        {percentageIncrease.toFixed(0)}%
      </Typography>
      {icon}
    </div>
  )
}
