import { BorderColor } from '@mui/icons-material'
import { ButtonGroup, IconButton } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { t } from 'i18next'
import { ProtocolTable } from './ProtocolTable'
import { useState } from 'react'
import { ProtocolDto } from '../../models/product/Protocol'
import { ProtocolModal } from './ProtocolModal'

export const ProtocolContainer = () => {
  const [selectedEntity, setSelectedEntity] = useState<ProtocolDto | null>(null)

  const onConfirm = () => {
    setSelectedEntity(null)
  }

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.name') as string,
      field: 'name',
      flex: 1
    },
    {
      headerName: t('labels.description') as string,
      field: 'description',
      flex: 1
    },
    {
      headerName: t('labels.number') as string,
      field: 'number',
      flex: 1
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => setSelectedEntity(params.row)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]

  return (
    <>
      {selectedEntity && (
        <ProtocolModal
          entity={selectedEntity as ProtocolDto}
          onConfirm={() => onConfirm()}
          onCancel={() => setSelectedEntity(null)}
        />
      )}
      <ProtocolTable columns={columns} setSelectedEntity={setSelectedEntity} />
    </>
  )
}
