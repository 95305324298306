import { useCallback, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { useConfig } from '../services/useConfig'
import { BaseResult } from '../models/BaseResult'

const usePrint = () => {
  const { config } = useConfig()
  const [printLoader, setPrintLoader] = useState(false)

  const printDocument = useCallback(
    async (url: string) => {
      setPrintLoader(true)
      const response: AxiosResponse<BaseResult<string>> = await axios.get(`${config.apiUrl}${url}`)
      const result = response.data

      if (response.status !== 200 || (result.errors && result.errors.length > 0)) {
        // Handle errors here
        console.error(result.errors)
        setPrintLoader(false)
        return
      }

      const base64String = result.data
      const binaryString = atob(base64String)
      const binaryLen = binaryString.length
      const bytes = new Uint8Array(binaryLen)
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i)
        bytes[i] = ascii
      }
      const blob = new Blob([bytes], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.target = '_blank'
      link.click()
      setPrintLoader(false)
    },
    [config.apiUrl]
  )

  return { printDocument, printLoader }
}

export default usePrint
