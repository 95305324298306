import { FormControl, InputLabel, OutlinedInput, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'

export interface ICustomerSearchProps {
  handleEnter: any
}

export const CustomerSearch = ({ handleEnter }: ICustomerSearchProps) => {
  const { t } = useTranslation()

  const onEnterPushed = (e: any, searchProp: string) => {
    if (e.key === 'Enter') {
      if (e.target.value.length === 0) {
        handleEnter('all', 'all')
        return
      }
      handleEnter(e.target.value, searchProp)
    }
  }

  return (
    <Paper elevation={1} style={{ padding: '20px' }}>
      <Typography variant='h5'>{t('labels.search')}</Typography>
      <Stack
        direction={'row'}
        justifyContent='space-between'
        alignItems='start'
        spacing={2}
        padding={0}
      >
        <Stack flex={1}>
          <FormControl sx={{ mt: 2 }} fullWidth variant='outlined'>
            <OutlinedInput
              id='outlined-adornment-fullname'
              type={'text'}
              onKeyDown={(e) => onEnterPushed(e, 'fullname')}
              startAdornment={<SearchIcon />}
              label={t('labels.searchWith', { propName: t('labels.fullname') })}
            />
            <InputLabel htmlFor='outlined-adornment-fullname'>
              {t('labels.searchWith', { propName: t('labels.fullname') })}
            </InputLabel>
          </FormControl>
        </Stack>
        <Stack flex={1}>
          <FormControl sx={{ mt: 2 }} fullWidth variant='outlined'>
            <OutlinedInput
              id='outlined-adornment-taxId'
              type={'text'}
              onKeyDown={(e) => onEnterPushed(e, 'tin')}
              startAdornment={<SearchIcon />}
              label={t('labels.searchWith', { propName: t('labels.taxId') })}
            />
            <InputLabel htmlFor='outlined-adornment-taxId'>
              {t('labels.searchWith', { propName: t('labels.taxId') })}
            </InputLabel>
          </FormControl>
        </Stack>
        <Stack flex={1}>
          <FormControl sx={{ mt: 2 }} fullWidth variant='outlined'>
            <OutlinedInput
              id='outlined-adornment-mobile'
              type={'text'}
              onKeyDown={(e) => onEnterPushed(e, 'mobile')}
              startAdornment={<SearchIcon />}
              label={t('labels.searchWith', { propName: t('labels.mobile') })}
            />
            <InputLabel htmlFor='outlined-adornment-mobile'>
              {t('labels.searchWith', { propName: t('labels.mobile') })}
            </InputLabel>
          </FormControl>
        </Stack>
        <Stack flex={1}>
          <FormControl sx={{ mt: 2 }} fullWidth variant='outlined'>
            <OutlinedInput
              id='outlined-adornment-mobile'
              type={'text'}
              onKeyDown={(e) => onEnterPushed(e, 'debt')}
              startAdornment={<SearchIcon />}
              label={t('labels.searchWith', { propName: t('labels.debt') })}
            />
            <InputLabel htmlFor='outlined-adornment-mobile'>
              {t('labels.searchWith', { propName: t('labels.debt') })}
            </InputLabel>
          </FormControl>
        </Stack>
      </Stack>
    </Paper>
  )
}
