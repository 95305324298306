export const ml = (value: string) => ({
  style: {
    marginLeft: value
  }
})

export const mr = (value: string) => ({
  style: {
    marginRight: value
  }
})

export const mt = (value: string) => ({
  style: {
    marginTop: value
  }
})

export const mb = (value: string) => ({
  style: {
    marginBottom: value
  }
})

export const mx = (value: string) => ({
  style: {
    marginRight: value,
    marginLeft: value
  }
})

export const my = (value: string) => ({
  style: {
    marginTop: value,
    marginBottom: value
  }
})

export const margin = (value: string) => ({
  style: {
    margin: value
  }
})

export const pad = (value: string) => ({
  style: {
    padding: value
  }
})
