import { useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import { ProductOverviewDto } from '../../models/product/Product'

export interface IUseProductViewProps {
  productLoader: boolean
  product?: ProductOverviewDto
}

export function useProductView(id: string): IUseProductViewProps {
  const [productLoader, setLoading] = useState(false)
  const [product, setProduct] = useState<ProductOverviewDto>()
  const service = useBaseService('product')
  const navigate = useNavigate()

  useEffect(() => {
    const getById = async (stalId: string) => {
      setLoading(true)
      const response = await service.getById<ProductOverviewDto>(stalId, `overview`)
      if (response) setProduct(response)
      setLoading(false)
    }
    getById(id)
  }, [id, navigate, service, setLoading])

  return {
    productLoader,
    product
  }
}
