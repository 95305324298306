import { useCallback, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { CustomerTransactionDto } from '../../models/Customer'

export interface ICustomerProps {
  transactionLoader: boolean
  createTransaction: (transaction: CustomerTransactionDto) => Promise<CustomerTransactionDto | null>
  deleteTransaction: (id: string) => Promise<string | null>
}

export function useCustomerTransaction(): ICustomerProps {
  const [transactionLoader, setLoading] = useState(false)
  const service = useBaseService('customer')

  const deleteTransaction = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await service.remove(id, `customer/transaction/${id}`)
      setLoading(false)
      return response === true ? id : null
    },
    [service]
  )

  const createTransaction = useCallback(
    async (transaction: CustomerTransactionDto) => {
      setLoading(true)
      const response = await service.create(transaction, '/transaction')
      setLoading(false)
      return response ?? null
    },
    [service]
  )

  return {
    transactionLoader,
    createTransaction,
    deleteTransaction
  }
}
