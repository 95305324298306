import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useProducts } from '../../../../product/useProducts'
import { InvoicePurchaseItem } from '../../../../../models/invoice/InvoicePurchase'
import { Loader } from '../../../../../components/loader/loader'
import { ProductEditDto } from '../../../../../models/product/Product'
import { InventoryDto } from '../../../../../models/product/Inventory'
import { ProductStatusChip } from '../../../../../../utils/ProductStatus'
import { enqueueSnackbar } from 'notistack'
import { IPriceSuggestionLookup } from '../../../../../hooks/useSuggestingPricesConfig'

interface IInvoicePurchaseProductItemInvetoryProps {
  onConfirm: any
  onCancel: any
  item: InvoicePurchaseItem
  suggestingPricesConfig: IPriceSuggestionLookup[]
}

const CalculateSellingPrice = (price: number, config?: IPriceSuggestionLookup) => {
  // Calculate selling price based on the profit margin
  // The profit margin is a percentage of the cost price
  // return 2 decimal places
  debugger
  if (config) {
    return (price + price * (config.profitMarginToBeAdded / 100)).toFixed(2)
  }
  // if config is null calculate selling price based on the default profit margin which is 75%
  return (price + price * 0.75).toFixed(2)
}

export const InvoicePurchaseProductItemInvetory = ({
  onCancel,
  onConfirm,
  item,
  suggestingPricesConfig
}: IInvoicePurchaseProductItemInvetoryProps) => {
  const { loading, awaitSearchByCode } = useProducts()
  const [showAll, setShowAll] = useState<boolean>(false)
  const [product, setProduct] = useState<ProductEditDto | null>(null)

  const selectWarehouseAndSubmit = (invetory: InventoryDto) => {
    item.description = product?.descriptionEn as string
    item.inventoryId = invetory.id
    item.warehouseName = invetory.warehouseName
    item.shelf = invetory.self
    item.status = invetory.status
    item.productCode = product?.code as string
    item.productStalId = product?.stalId as string
    if (item.sellingPrice === 0) {
      if (invetory.suggestedSellingPrice === 0) {
        const config = suggestingPricesConfig.find((x) => x.id === product?.productTypeId)
        item.sellingPrice = +CalculateSellingPrice(+item.price, config)
      } else {
        item.sellingPrice = invetory.suggestedSellingPrice
      }
    }
    onConfirm(item)
  }

  useEffect(() => {
    const searchByCode = async (item: InvoicePurchaseItem) => {
      const matchedProduct = await awaitSearchByCode(item.productCode.trim())
      if (matchedProduct != null) {
        setProduct(matchedProduct[0])
      } else {
        enqueueSnackbar(t('messages.productDoesNotExist'), { variant: 'info' })
        onCancel(null)
      }
    }

    searchByCode(item)
  }, [awaitSearchByCode, item, onCancel])

  const invetories =
    (showAll ? product?.inventory : (product?.inventory.filter((x) => x.quantity > 0) as any)) ?? []
  return (
    <Dialog open={true} maxWidth={'xl'}>
      <DialogTitle id='alert-dialog-title'>{t('labels.warehouses')}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loader type={'circles'} />
        ) : (
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>{t('labels.warehouse')}</TableCell>
                  <TableCell align='right'>{t('labels.quantity')}</TableCell>
                  <TableCell align='right'>{t('labels.shelf')}</TableCell>
                  <TableCell align='right'>{t('labels.price')}&nbsp;(&euro;)</TableCell>
                  <TableCell align='right'>{t('labels.sellingPrice')}&nbsp;(&euro;)</TableCell>
                  <TableCell align='right'>{t('labels.status')} </TableCell>
                  <TableCell align='right'>{t('labels.actions')} </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invetories.map((row: InventoryDto) => (
                  <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component='th' scope='row'>
                      {row.warehouseName}
                    </TableCell>
                    <TableCell align='right'>{row.quantity}</TableCell>
                    <TableCell align='right'>{row.self}</TableCell>
                    <TableCell align='right'>{row.price}</TableCell>
                    <TableCell align='right'>{row.suggestedSellingPrice}</TableCell>
                    <TableCell align='right'>
                      <ProductStatusChip status={row.status} />
                    </TableCell>
                    <TableCell align='right'>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={() => selectWarehouseAndSubmit(row)}
                      >
                        {t('buttons.select')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Button onClick={() => setShowAll((prev) => !prev)}>
              {' '}
              {showAll ? t('buttons.showLess') : t('buttons.showAll')}
            </Button>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel(null)}>{t('buttons.cancel')}</Button>
      </DialogActions>
    </Dialog>
  )
}
