export class InventoryDto {
  id: string
  created: Date
  lastUpdated: Date
  warehouseId: string
  warehouseName: string
  productId: string
  quantity: number
  price: number
  suggestedSellingPrice: number
  code: string
  manufacturerName: string
  self: string
  status: ProductStatus
}

export class ProductInventoryDto {
  inventoryId: string
  productId: string
  productTypeEn: string
  productTypeGr: string
  description: string
  warehouse: string
  manufacturer: string
  self: string
  quantity: number
  status: ProductStatus
  stalId: string
  code: string
  price: number

  taxValue: number
  discountPercentage: number
  discount: number
  priceAfterDiscount: number
}

export enum ProductStatus {
  NEW,
  REPAIRED,
  TOBEREPAIRED,
  OTHER
}
