import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CustomerTransactionDto } from '../../../models/Customer'
import { Loader } from '../../../components/loader/loader'
import { fieldProps } from '../../../../utils/FormExtensions'
export interface CustomerTransactionModalProps {
  onConfirm: any
  onCancel: any
  loader: boolean
}
export const CustomerTransactionModal = ({
  onCancel,
  loader,
  onConfirm
}: CustomerTransactionModalProps) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<CustomerTransactionDto>({
    defaultValues: new CustomerTransactionDto()
  })

  const sumbitForm = async (data: CustomerTransactionDto) => {
    onConfirm(data)
    onCancel()
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle id='alert-dialog-title'>{t('labels.newRecord')}</DialogTitle>
      <DialogContent>
        {loader && <Loader type='circles' />}
        {!loader && (
          <>
            <Controller
              control={control}
              defaultValue={0}
              name='amount'
              rules={{ required: true, min: 1 }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  type='number'
                  {...fieldProps(t('labels.amount'), ref, field)}
                  error={!!errors.amount}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={''}
              name='manualProtocolNumber'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.protocol'), ref, field)}
                  error={!!errors.manualProtocolNumber}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=''
              name='comments'
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.comments'), ref, field)}
                  error={!!errors.comments}
                />
              )}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        {!loader && (
          <Button color='success' onClick={handleSubmit(sumbitForm)} autoFocus>
            {t('buttons.insert')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
