import { Autocomplete, Skeleton, Stack, TextField, Typography } from '@mui/material'
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InvoiceEditDto } from '../../../../../models/invoice/InvoiceSale'
import { useInvoiceCustomer } from '../../../useInvoiceCustomer'
import { CustomerTableDto } from '../../../../../models/Customer'
import { useCallback, useEffect, useState } from 'react'
import { TimologioLianikhs } from '../../../../../../utils/InvoiceExtensions'

export interface IInvoiceCustomerDetailsProps {
  errors: FieldErrors<InvoiceEditDto>
  control: Control<InvoiceEditDto, any>
  watch: UseFormWatch<InvoiceEditDto>
  setValue: UseFormSetValue<InvoiceEditDto>
  trigger: any
  disableForm: boolean
}

export const InvoiceCustomerDetails = ({
  errors,
  control,
  trigger,
  disableForm,
  watch,
  setValue
}: IInvoiceCustomerDetailsProps) => {
  const { t } = useTranslation()
  const [isTimologioLianikhs, setIsTimologioLianikhs] = useState(false)
  const { loading, getCustomer, customers, setCustomers } = useInvoiceCustomer()
  const [autocompleteText, setAutocompleteText] = useState<string | undefined>()

  const clearCustomerDetails = useCallback(
    (clearAll = false) => {
      if (clearAll) {
        setValue('fullname', '')
      }
      setValue('occupation', '')
      setValue('contactNumber', '')
      setValue('city', '')
      setValue('doy', '')
      setValue('address', '')
      setValue('previousDebt', 0)
      setValue('currentDedt', 0)
      setValue('taxApplied', 24)
      setValue('customerId', '')
      setValue('postalCode', '')
      setValue('locationTo', '')
      setValue('taxId', '')
      trigger('taxApplied')
    },
    [setValue, trigger]
  )

  useEffect(() => {
    const subscription = watch(async (value, { name }) => {
      if (name === 'typeId' && value.typeId === TimologioLianikhs) {
        clearCustomerDetails(true)
        setIsTimologioLianikhs(true)
        await getCustomer('081640339', 'tin')
      }
    })
    return () => subscription.unsubscribe()
  }, [clearCustomerDetails, getCustomer, setValue, watch])

  const onEnterPushed = async (e: any) => {
    if (e.key === 'Enter') {
      clearCustomerDetails()
      if (e.target.value.length < 4) return
      setAutocompleteText(e.target.value)
      setTimeout(async () => {
        await getCustomer(e.target.value, 'name')
      }, 200)
    }
  }

  const onTaxSearch = async (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.value.length === 0) clearCustomerDetails(true)
      if (e.target.value.length < 7) return
      setTimeout(async () => {
        await getCustomer(e.target.value, 'tin')
      }, 200)
    }
  }

  useEffect(() => {
    if (customers.length === 1) {
      const cust = customers[0]
      setValue('fullname', cust.fullname)
      setValue('occupation', cust.occupation)
      setValue('contactNumber', cust.mobiles[0] ?? '')
      setValue('city', cust.city ?? '')
      setValue('doy', cust.doy ?? '')
      setValue('address', cust.address)
      setValue('previousDebt', cust.currentDebt)
      setValue('locationTo', cust.address)
      setValue('currentDedt', cust.currentDebt)
      setValue('taxApplied', cust.taxRate)
      setValue('country', cust.country)
      setValue('countryCode', cust.countryCodeNo)
      setValue('customerId', cust.id)
      setValue('postalCode', cust.postalCode)
      setValue('taxId', cust.tin)
      trigger('taxApplied')
    }
  }, [clearCustomerDetails, customers, setValue, trigger])

  const filterCustomers = (customers: CustomerTableDto[], state: any) => {
    if (customers.length === 0) return []
    return customers.filter((option) =>
      option.fullname.toLowerCase().trim().includes(state.inputValue.toLowerCase().trim())
    )
  }

  const onInputChange = (value: string, reason: string) => {
    if (reason === 'input') {
      setAutocompleteText(value)
    }
  }

  const selectCustomer = (customer: CustomerTableDto) => {
    console.log(customer)
    if (!customer) return
    setValue('occupation', customer.occupation)
    setValue('contactNumber', customer.mobiles[0] ?? '')
    setValue('fullname', customer.fullname)
    setValue('locationTo', customer.address)
    setValue('city', customer.city ?? '')
    setValue('doy', customer.doy ?? '')
    setValue('address', customer.address ?? '')
    setValue('previousDebt', customer.currentDebt ?? 0)
    setValue('taxApplied', customer.taxRate ?? 24)
    setValue('customerId', customer.id ?? '')
    setValue('postalCode', customer.postalCode ?? '')
    setValue('taxId', customer.tin ?? '')
    trigger('taxApplied')
    setCustomers([])
    setAutocompleteText(undefined)
  }

  if (loading) {
    return (
      <Stack flex={1} padding={'1rem'}>
        <Typography variant='body1' color={'grey'}>
          {t('labels.invoiceBillFrom')}
        </Typography>
        {Array.from(new Array(6)).map((_, i) => (
          <Skeleton height={50} key={i} animation='wave' />
        ))}
      </Stack>
    )
  }
  return (
    <Stack
      padding={'1rem'}
      direction='column'
      justifyContent='space-between'
      alignItems='start'
      spacing={2}
    >
      {customers.length > 1 && (
        <Autocomplete
          id='customer-search'
          noOptionsText='Δεν βρέθηκαν πελάτες'
          clearOnEscape
          fullWidth
          style={{ width: '100%' }}
          options={customers}
          filterOptions={filterCustomers}
          inputValue={autocompleteText}
          onInputChange={(_, value, reason) => onInputChange(value, reason)}
          getOptionLabel={(option: CustomerTableDto) => option.fullname}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.fullname}
              </li>
            )
          }}
          onChange={(_, value) => {
            selectCustomer(value as any)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              key={params.id}
              onKeyDown={(e: any) => onEnterPushed(e)}
              size='small'
              label={t('labels.fullname')}
            />
          )}
        />
      )}
      {customers.length <= 1 && (
        <Controller
          control={control}
          defaultValue={''}
          rules={{ required: true }}
          name='fullname'
          render={({ field: { ref, ...field } }) => (
            <TextField
              inputRef={ref}
              disabled={disableForm}
              {...field}
              onKeyDown={(e: any) => onEnterPushed(e)}
              fullWidth
              size='small'
              error={!!errors.fullname}
              label={t('labels.fullname')}
            />
          )}
        />
      )}
      <Controller
        control={control}
        defaultValue={''}
        rules={{ required: !isTimologioLianikhs }}
        name='occupation'
        render={({ field: { ref, ...field } }) => (
          <TextField
            inputRef={ref}
            disabled={disableForm}
            {...field}
            fullWidth
            size='small'
            error={!!errors.occupation}
            label={t('labels.occupation')}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue={''}
        rules={{ required: !isTimologioLianikhs }}
        name='contactNumber'
        render={({ field: { ref, ...field } }) => (
          <TextField
            inputRef={ref}
            disabled={disableForm}
            {...field}
            fullWidth
            size='small'
            error={!!errors.contactNumber}
            label={t('labels.phone')}
          />
        )}
      />
      <Stack
        direction='row'
        justifyContent='space-between'
        alignSelf={'stretch'}
        alignItems='start'
      >
        <Stack>
          <Controller
            control={control}
            defaultValue={''}
            name='city'
            rules={{ required: !isTimologioLianikhs }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled={disableForm}
                inputRef={ref}
                {...field}
                fullWidth
                size='small'
                error={!!errors.city}
                label={t('labels.city')}
              />
            )}
          />
        </Stack>
        <Stack>
          <Controller
            control={control}
            defaultValue={''}
            name='postalCode'
            rules={{ required: !isTimologioLianikhs }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled={disableForm}
                inputRef={ref}
                {...field}
                fullWidth
                size='small'
                error={!!errors.postalCode}
                label={t('labels.postalCode')}
              />
            )}
          />
        </Stack>
      </Stack>
      <Controller
        control={control}
        defaultValue={''}
        name='address'
        rules={{ required: !isTimologioLianikhs }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            disabled={disableForm}
            inputRef={ref}
            {...field}
            fullWidth
            size='small'
            error={!!errors.address}
            label={t('labels.address')}
          />
        )}
      />
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='start'
        alignSelf={'stretch'}
      >
        <Stack>
          <Controller
            control={control}
            defaultValue={''}
            name='taxId'
            rules={{ required: !isTimologioLianikhs }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled={disableForm}
                inputRef={ref}
                {...field}
                fullWidth
                onKeyDown={(e: any) => onTaxSearch(e)}
                size='small'
                error={!!errors.taxId}
                label={t('labels.taxId')}
              />
            )}
          />
        </Stack>
        <Stack>
          <Controller
            control={control}
            defaultValue={''}
            name='doy'
            rules={{ required: !isTimologioLianikhs }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                disabled={disableForm}
                inputRef={ref}
                {...field}
                fullWidth
                size='small'
                error={!!errors.doy}
                label={t('labels.doy')}
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
