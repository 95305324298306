export class ManufacturerDto {
  id: string
  created: Date
  name: string
  description: string
  site: string
  codes: ManufacturerCodeDto[] = []
}

export class ManufacturerTableDto {
  id: string
  created: Date
  name: string
  description: string
  site: string
}

export class ManufacturerCodeDto {
  id: string
  code: string
}
