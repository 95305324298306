import { useCallback, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { CustomerTableDto } from '../../models/Customer'
import { useSnackbar } from 'notistack'

export interface IUseInvoiceCustomer {
  loading: boolean
  customers: CustomerTableDto[]
  setCustomers: (customers: CustomerTableDto[]) => void
  getCustomer: (searchParam: string, property: string) => Promise<void>
}

export function useInvoiceCustomer(): IUseInvoiceCustomer {
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [customers, setCustomers] = useState<CustomerTableDto[]>([])
  const service = useBaseService('customer')

  const getCustomer = useCallback(
    async (searchParam: string, property: string) => {
      setLoading(true)
      const response = await service.getById<CustomerTableDto[]>(searchParam, `search/${property}`)
      setLoading(false)
      if (response) setCustomers(response)
      else {
        enqueueSnackbar('Ο Πελάτης Δεν βρέθηκε', {
          variant: 'warning',
          autoHideDuration: 3000
        })
      }
    },
    [enqueueSnackbar, service]
  )

  return {
    loading,
    getCustomer,
    customers,
    setCustomers
  }
}
