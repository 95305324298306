import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { MiDatagrid } from '../../../../components/material/MiDatagrid'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { ProductStatusChip } from '../../../../../utils/ProductStatus'
import { PriceTrendIcon } from './PriceTrendIcon'
import { LinkCell } from '../../../../../common/components/LinkCell'
import { IProductPriceTrendDto } from '../../useProductPriceTrend'

interface IPriceTrendTableProps {
  data: IProductPriceTrendDto[]
  loading: boolean
}
export const PriceTrendTable = ({ data, loading }: IPriceTrendTableProps) => {
  const { t } = useTranslation()

  const columns: GridColDef[] = [
    {
      headerName: t('labels.code') as string,
      field: 'productCode',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <LinkCell id={params.row.productCode} path={`/manager/products/${params.row.productId}`} />
      )
    },
    {
      headerName: t('labels.date') as string,
      field: 'created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => moment(params.row?.created).format('DD/MM/YYYY')
    },
    {
      headerName: t('labels.quantity') as string,
      field: 'quantity',
      flex: 1
    },
    {
      headerName: `${t('labels.priceBought')} (€)` as string,
      field: 'priceBought',
      align: 'right',
      width: 120
    },
    {
      headerName: `${t('labels.priceTrend')} (%)` as string,
      field: 'trend',
      align: 'left',
      width: 130,
      renderCell: (params: GridRenderCellParams) => (
        <PriceTrendIcon
          valueAfter={params.row.priceBought}
          valueBefore={params.row.previousPriceBought}
        />
      )
    },
    {
      headerName: `${t('labels.salesPrice')} (€)` as string,
      field: 'sellingPrice',
      flex: 1
    },
    {
      headerName: t('labels.supplier') as string,
      field: 'supplierName',
      flex: 1
    },
    {
      headerName: t('labels.status') as string,
      field: 'productStatus',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <ProductStatusChip status={params.row.productStatus} size='small' />
      )
    }
  ]
  return <MiDatagrid data={data} columns={columns} loading={loading} />
}
