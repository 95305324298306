import Grid from '@mui/material/Unstable_Grid2'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import { PaperHeader } from '../../../components/paperHeader/PaperHeader'
import { InvoicePurchaseSupplierDetails } from './invoiceSections/purchase/InvoicePurchaseSupplierDetails'
import { InvoicePurchaseProductList } from './invoiceSections/purchase/InvoicePurchaseProductList'
import { Button } from '@mui/material'
import { SupplierDto } from '../../../models/Supplier'
import { useData } from '../../../services/useData'
import { InvoicePurchase } from '../../../models/invoice/InvoicePurchase'
import { useEffect, useState } from 'react'
import { useInvoicePurchase } from '../useInvoicePurchase'
import { Loader } from '../../../components/loader/loader'
import { InvoicePurchaseSummary } from './invoiceSections/purchase/InvoicePurchaseSummary'
import { useSuggestingPricesConfig } from '../../../hooks/useSuggestingPricesConfig'

export const InvoicePurchaseContainer = () => {
  const { id } = useParams<{ id: string }>()
  const [isDisabled, setDisabled] = useState(id !== 'new')
  const { loading, data } = useData<SupplierDto>('supplier')
  const { createInvoice, invoice, invoiceLoader, resetInvoice } = useInvoicePurchase(id)
  const { loading: load, suggestingPricesConfig } = useSuggestingPricesConfig()
  const isLoading = invoiceLoader || load
  const { t } = useTranslation()
  const {
    control,
    getValues,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors }
  } = useForm<InvoicePurchase>({
    values: invoice,
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    reset(invoice)
  }, [reset, invoice])

  useEffect(() => {
    if (id === 'new') {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [id, resetInvoice])

  useEffect(() => {
    if (!data || isDisabled) return
    const subscription = watch((value, { name }) => {
      if (name === 'supplierId') {
        const supplier: SupplierDto | undefined = data.find((x) => x.id === value.supplierId)
        setValue('supplierTax', supplier?.taxValue ?? 0)
      }
    })
    return () => subscription.unsubscribe()
  }, [data, isDisabled, setValue, watch])

  const publish = async (data: InvoicePurchase) => {
    await createInvoice(data)
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Paper elevation={1}>
          {isLoading && <Loader pad='40px 0' type={'circles'} />}
          {!isLoading && (
            <PaperHeader
              padding={2}
              label={t('labels.newPurchaseInvoice')}
              rightChild={
                !isDisabled && (
                  <Button
                    key={0}
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit(publish)}
                  >
                    {t('buttons.publish')}
                  </Button>
                )
              }
            />
          )}
          {!isLoading && (
            <InvoicePurchaseSupplierDetails
              data={data}
              isDisabled={isDisabled}
              loading={loading}
              control={control}
              errors={errors}
            />
          )}
        </Paper>
      </Grid>
      <Grid xs={12}>
        {!isLoading && (
          <InvoicePurchaseProductList
            isDisabled={isDisabled}
            getValues={getValues}
            control={control}
            suggestingPricesConfig={suggestingPricesConfig}
          />
        )}
      </Grid>
      <Grid xs={12}>{!isLoading && <InvoicePurchaseSummary watch={watch} />}</Grid>
    </Grid>
  )
}
