import { Card, CardContent, Typography } from '@mui/material'
import { SettingCards } from './SettingsItems'
import { useTranslation } from 'react-i18next'

export const SettingsContainer = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Card sx={{ marginBottom: 2 }}>
        <CardContent>
          <Typography variant='h5' component='div'>
            {t('labels.appSettings')}
          </Typography>
          <Typography variant='body2'>{t('descr.appSettingsDescription')}</Typography>
        </CardContent>
      </Card>
      <SettingCards />
    </div>
  )
}
