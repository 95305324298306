import { useCallback, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { ProductCategoryPriceSuggestion } from '../../models/product/ProductCategoryPriceSuggestions'


export interface IuseProductCategoryPriceSuggestionProps {
  loader: boolean
  create: any
  update: any
}

export function useProductCategoryPriceSuggestion(): IuseProductCategoryPriceSuggestionProps {
  const [loader, setLoading] = useState(false)
  const service = useBaseService('ProductCategoryPriceSuggestion')

  const update = useCallback(
    async (entity: ProductCategoryPriceSuggestion) => {
      setLoading(true)
      const result = await service.update<ProductCategoryPriceSuggestion>(entity, entity.id)
      setLoading(false)
      return result
    },
    [service]
  )

  const create = useCallback(
    async (entity: ProductCategoryPriceSuggestion) => {
      setLoading(true)
      const result = await service.create(entity)
      setLoading(false)
      return result
    },
    [service]
  )

  return {
    loader,
    update,
    create
  }
}
