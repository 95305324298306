import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import XMLViewer from 'react-xml-viewer'
import { useBaseService } from '../../services/useBaseService'
import { Chip, Stack } from '@mui/material'
import { DisplayInfo } from '../../components/commonViews/DisplayInfo'

interface IXmlRecord {
  id: string
  aadeMark: string
  invoiceId: string
  transactionId: string
  type: number
  created: Date
  customer: string
  invoiceType: string
  xmlContent: any
}

export const XmlInvoiceContainer = () => {
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<IXmlRecord>()
  const { t } = useTranslation()
  const service = useBaseService('invoice')

  useEffect(() => {
    if (!service) return

    async function fetchData() {
      setLoading(true)
      const response = await service.getById<IXmlRecord>(id as string, `xml`)
      if (response) setData(response)
      setLoading(false)
    }
    fetchData()
  }, [id, service])

  return (
    <Stack>
      {!loading && (
        <Stack>
          <DisplayInfo label={t('labels.createdAt')} value={data?.created as any} />
          <DisplayInfo label={t('labels.invoiceType')} value={data?.invoiceType as any} />
          <DisplayInfo label={t('labels.name')} value={data?.customer as any} />
          <DisplayInfo
            label={t('labels.type')}
            value={
              data?.type === 0 ? (
                <Chip variant='outlined' color='info' label='Request' />
              ) : (
                <Chip variant='outlined' color='secondary' label='Response' />
              )
            }
          />
          <DisplayInfo label={t('labels.code')} value={data?.transactionId as any} />
          <DisplayInfo label={t('labels.aadeMark')} value={data?.aadeMark as any} />

          <XMLViewer xml={data?.xmlContent} />
        </Stack>
      )}
    </Stack>
  )
}
