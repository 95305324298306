import { useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { ProductStatus } from '../../models/product/Inventory'

export interface IProductPriceTrendDto {
  id: string
  invoiceId: string
  productCode: string
  productId: string
  created: Date
  supplierName: string
  priceBought: number
  previousPriceBought: number
  sellingPrice: number
  quantity: number
  productStatus: ProductStatus
}

export interface IuseProductPriceTrend {
  loading: boolean
  priceTrend: IProductPriceTrendDto[]
}

export function useProductPriceTrend(stalId: string): IuseProductPriceTrend {
  const [loading, setLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(false)
  const [priceTrend, setPriceTrend] = useState<IProductPriceTrendDto[]>([])
  const service = useBaseService('product')

  useEffect(() => {
    if (firstLoad) return
    const getTrend = async (stalId: string) => {
      setLoading(true)
      const response = await service.getById<IProductPriceTrendDto[]>(stalId, `price/trend`)
      if (response) {
        setFirstLoad(true)
        setPriceTrend(response)
      }
      setLoading(false)
    }
    getTrend(stalId)
  }, [firstLoad, service, stalId])

  return {
    loading,
    priceTrend
  }
}
