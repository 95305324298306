import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material'
import { Loader } from '../loader/loader'
import { useTranslation } from 'react-i18next'

export interface IConfirmationModalProps {
  open: boolean
  title: string
  description: any
  loading?: boolean
  onCancelText?: string
  onConfirmText?: string
  onConfirm: () => any
  onCancel: () => any
}
export const ConfirmationModal = ({
  open,
  title,
  description,
  onCancel,
  onConfirm,
  onConfirmText = 'buttons.delete',
  onCancelText = 'buttons.cancel',
  loading = false
}: IConfirmationModalProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      {loading && <Loader noText type={'circles'} />}
      {!loading && (
        <DialogContent>
          <DialogContentText color={'red'} id='alert-dialog-description'>
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      {!loading && (
        <DialogActions>
          <Button onClick={onCancel}> {t(onCancelText)}</Button>
          <Button color='error' onClick={onConfirm} autoFocus>
            {t(onConfirmText)}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
