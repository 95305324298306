import { createTheme, Theme } from '@mui/material'
import { CommonColors } from '@mui/material'

declare module '@mui/material/styles' {
  interface CommonColors {
    black: string
    white: string
    bluesHeavyBlue: string
    bluesLightBlue1: string
    bluesLightBlue2: string
    bluesMidBlue: string
    bluesPrimaryBlue: string
    bluesSecondaryBlue: string
    greysDarkGrey: string
    greysLightGrey: string
    greysLightGrey2: string
    greysMidGrey: string
    greysWhite: string
    inputSelectionStatesActive: string
    linksBlue: string
    statusChipsMessagesBlueNeutral: string
    statusChipsMessagesGreenSuccess: string
    statusChipsMessagesOrangeWarning: string
    statusChipsMessagesRedAlert: string
    statusChipsMessagesClient: string
    graphsBlue: string
    graphsGreen: string
    graphsBrown: string
    graphsPurple: string
    graphsOrange: string
    inputSelectionStatesError: string
  }
}

export const commonColors: CommonColors = {
  black: '#000000',
  white: '#ffffff',
  bluesHeavyBlue: '#2e4d6b',
  bluesLightBlue1: '#edf2f7',
  bluesLightBlue2: '#f8fafc',
  bluesMidBlue: '#bdcbd8',
  bluesPrimaryBlue: '#1d4464',
  bluesSecondaryBlue: '#26a69a',
  greysDarkGrey: '#4a5868',
  greysLightGrey: '#e4e8ec',
  greysLightGrey2: '#fafafa',
  greysMidGrey: '#8da2b7',
  greysWhite: '#edf1f6',
  inputSelectionStatesActive: '#eff8ff',
  linksBlue: '#0056b3',
  statusChipsMessagesBlueNeutral: '#03a9f4',
  statusChipsMessagesGreenSuccess: '#78d690',
  statusChipsMessagesOrangeWarning: '#fa9f1b',
  statusChipsMessagesRedAlert: '#df5645',
  statusChipsMessagesClient: '#e0be41',
  graphsBlue: '#6099d3',
  graphsGreen: '#709d83',
  graphsBrown: '#ae7474',
  graphsPurple: '#ac75c9',
  graphsOrange: '#cf8217',
  inputSelectionStatesError: '#ffeceb'
} as const

export type CommonColorsType = keyof typeof commonColors

export const useBaseTheme = (): Theme =>
  createTheme({
    palette: {
      background: {
        default: commonColors.greysWhite
      },
      text: {
        primary: commonColors.bluesPrimaryBlue,
        secondary: commonColors.bluesSecondaryBlue
      },
      primary: {
        main: commonColors.bluesPrimaryBlue
      },
      secondary: {
        main: commonColors.bluesSecondaryBlue
      },
      common: commonColors,
      warning: {
        main: commonColors.statusChipsMessagesOrangeWarning
      },
      error: {
        main: commonColors.statusChipsMessagesRedAlert
      },
      info: {
        main: commonColors.statusChipsMessagesBlueNeutral
      }
    },
    typography: {
      fontSize: 13,
      button: { textTransform: 'none' }
    },
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '5px',
            height: '28px'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '& input:disabled': {
              '-webkit-text-fill-color': '#595959'
            }
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            color: '#607383'
          }
        }
      },
      MuiCard: {
        styleOverrides: { root: { borderRadius: '.5rem' } }
      },
      MuiPaper: {
        styleOverrides: {
          root: { borderRadius: '.5rem' }
        }
      }
    }
  })
