import {
  ButtonGroup,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  IconButton
} from '@mui/material'
import { Control, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Add, DeleteOutline, ModeEdit } from '@mui/icons-material'
import { useState } from 'react'
import { ProductPropertiesModal } from './ProductPropertiesModal'
import { ProductPropertyDto } from '../../../models/product/ProductProperty'
import { ProductEditDto } from '../../../models/product/Product'

export interface IProductPropertiesProps {
  control: Control<ProductEditDto, any>
}

export const ProductProperties: React.FC<IProductPropertiesProps> = ({ control }) => {
  const { t } = useTranslation()
  const [property, setProperty] = useState<any | null>(null)
  const { fields, append, remove, update } = useFieldArray({
    control,
    keyName: 'propId',
    name: 'properties'
  })

  const addOrUpdateEntity = (property: any) => {
    setProperty(null)
    if (property.id) {
      const i = fields.findIndex((f) => f.id === property.id)
      update(i, property)
    } else append(property)
  }

  const removeEntity = (id: string) => {
    const i = fields.findIndex((f) => f.id === id)
    remove(i)
  }

  return (
    <Stack>
      {property != null && (
        <ProductPropertiesModal
          onConfirm={addOrUpdateEntity}
          entity={property}
          onCancel={() => setProperty(null)}
        />
      )}
      <Divider style={{ margin: '1em 0' }} />
      <Stack direction={'row'} alignItems={'center'}>
        <Typography flex={1} variant='body1' color={'grey'}>
          {t('labels.details')}
        </Typography>
        <Stack>
          <IconButton color='primary' onClick={() => setProperty({})}>
            <Add />
          </IconButton>
        </Stack>
      </Stack>
      <List dense sx={{ MuiListItemSecondaryAction: '0' }}>
        {fields?.map((item: ProductPropertyDto, i: number) => {
          const labelId = `checkbox-list-secondary-label-${i}`
          return (
            <ListItem
              sx={{
                '.MuiListItemSecondaryAction-root': {
                  right: '0px'
                }
              }}
              key={i}
              secondaryAction={
                <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
                  <IconButton color='primary' onClick={() => setProperty(item)}>
                    <ModeEdit fontSize={'small'} />
                  </IconButton>
                  <IconButton onClick={() => removeEntity(item.id)}>
                    <DeleteOutline fontSize={'small'} />
                  </IconButton>
                </ButtonGroup>
              }
              disablePadding
            >
              <ListItemText id={labelId} primary={item.labelEn} secondary={item.productKeyValue} />
            </ListItem>
          )
        })}
      </List>
    </Stack>
  )
}
