import { Button, Stack, TextField } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { fieldProps } from '../../../../utils/FormExtensions'
import { useTranslation } from 'react-i18next'

interface UserPasswordManagerProps {
  id: string | undefined
}

interface PasswordFormInputs {
  password: string
  confirmPassword: string
}

export const UserPasswordManager = ({ id }: UserPasswordManagerProps) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<PasswordFormInputs>({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  })

  const onSubmit = async (data: PasswordFormInputs) => {
    try {
      // Make API call to change the password
    } catch (error) {
      // Handle API errors here
      console.error('Error changing password:', error)
      alert('Failed to change password.')
    }
  }

  const password = watch('password')
  return (
    <Stack padding={'1rem'} spacing={2}>
      <Controller
        name='password'
        control={control}
        rules={{
          required: 'Password is required',
          minLength: {
            value: 8,
            message: 'Password must be at least 8 characters long'
          },
          pattern: {
            value: /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)(?=.*[A-Za-z0-9]).{8,}$/,
            message: 'Password must contain at least one symbol'
          }
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.password'), ref, field)} error={!!errors.password} />
        )}
      />
      <Controller
        name='confirmPassword'
        control={control}
        rules={{
          required: 'Please confirm your password',
          validate: (value) => value === password || 'Passwords do not match'
        }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...fieldProps(t('labels.confirmPassword'), ref, field)}
            error={!!errors.confirmPassword}
          />
        )}
      />
      <Button
        onClick={handleSubmit(onSubmit)}
        variant='contained'
        fullWidth
        sx={{ marginTop: '16px' }}
      >
        Change Password
      </Button>
    </Stack>
  )
}
