import Grid from '@mui/material/Unstable_Grid2'
import { CustomerOverviewDetails } from './CustomerOverviewDetails'
import { Loader } from '../../../components/loader/loader'
import { useParams } from 'react-router-dom'
import { useCustomer } from '../useCustomer'
import { CustomerInvoices } from './CustomerInvoices'
import { useCustomerInvoices } from '../useCustomerInvoices'
import { CustomerTransactionModal } from './CustomerTransactionModal'
import { useCustomerTransaction } from '../useCustomerTransaction'
import { useState } from 'react'
import { CustomerTransactionDto } from '../../../models/Customer'

export const CustomerOverviewContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { customerLoader, customer, getCustomer } = useCustomer(id)
  const { loading, invoices, setInvoices } = useCustomerInvoices(id as string)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { transactionLoader, createTransaction, deleteTransaction } = useCustomerTransaction()

  const addTransaction = async (transaction: CustomerTransactionDto) => {
    if (!customer) return
    transaction.customerId = customer.id
    const tran = await createTransaction(transaction)
    setOpenModal(false)
    if (!tran) return
    setInvoices((hist) => [...hist, tran as any])
    await getCustomer(id as string)
  }

  const removeTransaction = async (tranId: string) => {
    const deletedId = await deleteTransaction(tranId)
    if (deletedId) {
      setInvoices((hist) => hist.filter((x) => x.id !== tranId))
      await getCustomer(id as string)
    }
  }

  if (customerLoader) return <Loader isFullscreen type={'circles'} />
  return (
    <Grid container spacing={3}>
      {openModal && (
        <CustomerTransactionModal
          onCancel={() => setOpenModal(false)}
          onConfirm={addTransaction}
          loader={false}
        />
      )}
      <Grid xs={3}>{customer && <CustomerOverviewDetails customer={customer} />}</Grid>
      <Grid xs={9}>
        <CustomerInvoices
          loading={loading || transactionLoader}
          invoices={invoices}
          id={id as string}
          deleteTransaction={removeTransaction}
          addTransaction={() => setOpenModal(true)}
        />
      </Grid>
    </Grid>
  )
}
