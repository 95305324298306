import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { ButtonGroup, IconButton } from '@mui/material'
import { BorderColor, DeleteOutline, Source } from '@mui/icons-material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomerTableDto } from '../../../models/Customer'
import {
  IPageState,
  MiPaginatedDataGrid
} from '../../../components/material/IMiPaginatedDataGridProps'
import { useCustomer } from '../useCustomer'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'

export interface ICustomerTableProps {
  pageState: IPageState<CustomerTableDto>
  onPaginationModelChange: any
  removeEntity: any
}
export const CustomerTable = ({
  pageState,
  onPaginationModelChange,
  removeEntity
}: ICustomerTableProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { deleteCustomer, customerLoader } = useCustomer()
  const [selectedEntity, setSelectedEntity] = useState<CustomerTableDto | null>(null)
  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.fullname') as string,
      field: 'fullname',
      flex: 1
    },
    {
      headerName: t('labels.taxId') as string,
      field: 'tin',
      flex: 1
    },
    {
      headerName: t('labels.addresses') as string,
      field: 'address',
      flex: 1
    },
    {
      headerName: t('labels.city') as string,
      field: 'city',
      flex: 1
    },
    {
      headerName: t('labels.email') as string,
      field: 'emails',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <div>{params.row.emails.join(' ')}</div>
    },
    {
      headerName: t('labels.mobile') as string,
      field: 'mobile',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <div>{params.row.mobiles.join(' ')}</div>
    },
    {
      headerName: t('labels.debt') as string,
      field: 'currentDebt',
      flex: 1
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='primary' onClick={() => navigate(`overview/${params.row.id}`)}>
            <Source fontSize={'small'} />
          </IconButton>
          <IconButton color='default' onClick={() => navigate(params.row.id)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
          <IconButton color='error' onClick={() => openDialog(params.row)}>
            <DeleteOutline fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]

  const openDialog = (entity: CustomerTableDto) => {
    setSelectedEntity(entity)
  }

  const confirmDeletion = async () => {
    var success = await deleteCustomer(selectedEntity?.id as string)
    if (success) {
      removeEntity(selectedEntity?.id as string)
      setSelectedEntity(null)
    }
  }

  return (
    <>
      <ConfirmationModal
        open={selectedEntity != null}
        title={t('messages.deleteEntity', {
          entityName: selectedEntity?.fullname
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setSelectedEntity(null)}
        onConfirm={confirmDeletion}
        loading={customerLoader}
      />
      <MiPaginatedDataGrid
        title={t('labels.customers')}
        pageState={pageState}
        onPaginationModelChange={onPaginationModelChange}
        columns={columns}
      />
    </>
  )
}
