import { Link } from 'react-router-dom'
interface ILinkCellProps {
  id: string
  path: string
}
export const LinkCell = ({ id, path }: ILinkCellProps) => (
  <Link to={`${path}`} style={{ color: '#235977', cursor: 'pointer' }}>
    {id}
  </Link>
)
