import { useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { ManufacturerTableDto } from '../../models/Manufacturer'

export interface IUseManufacturers {
  loading: boolean
  manufacturers: any
  removeManufacturer: (id: string) => void
}

export function useManufacturers(): IUseManufacturers {
  const [loading, setLoading] = useState(true)
  const [manufacturers, setManufacturers] = useState<ManufacturerTableDto[]>()
  const service = useBaseService('manufacturer')

  const removeManufacturer = (id: string) => {
    setManufacturers(manufacturers?.filter((x) => x.id !== id))
  }
  useEffect(() => {
    if (!service) return

    async function fetchManufacturers() {
      setLoading(true)

      const response = await service.getAll<ManufacturerTableDto>()
      if (response) setManufacturers(response)
      setLoading(false)
    }

    fetchManufacturers()
  }, [service])

  return {
    loading,
    manufacturers,
    removeManufacturer
  }
}
