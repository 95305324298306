import { useCallback, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { IInvoicePurchasePagination } from '../../models/invoice/InvoiceSale'
import { IPageState } from '../../components/material/IMiPaginatedDataGridProps'
import { InvoicePurchase } from '../../models/invoice/InvoicePurchase'

export interface IUseInvoices {
  loading: boolean
  invoiceState: IPageState<InvoicePurchase>
  searchInvoices: (options: IInvoicePurchasePagination) => void
}

export function useInvoicesPurchasesPaginated(): IUseInvoices {
  const [loading, setLoading] = useState(false)
  const [invoiceState, setInvoiceState] = useState({
    data: [] as any,
    loading: false,
    page: 0,
    pageSize: 20,
    total: 0
  } as IPageState<InvoicePurchase>)
  const service = useBaseService('invoice')

  const searchInvoices = useCallback(
    async (options: IInvoicePurchasePagination) => {
      if (!options.supplierId) options.supplierId = null

      setInvoiceState((prev) => {
        return { ...prev, loading: true }
      })
      setLoading(true)
      const response: any = await service.post<any>(options, `/purchases/paginated`)

      if (response) {
        setInvoiceState({
          data: response.items,
          loading: false,
          page: response.currentPage,
          pageSize: options.size,
          total: response.totalItems
        })
      } else {
        setInvoiceState((prev) => {
          return { ...prev, loading: false }
        })
      }
      setLoading(false)
    },
    [service]
  )

  return {
    loading,
    searchInvoices,
    invoiceState
  }
}
