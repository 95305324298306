import { Divider, Stack, Typography, IconButton } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { ProductPartModal } from './ProductPartModal'
import { useState } from 'react'
import { ProductEditDto, ProductLightDto } from '../../../models/product/Product'
import { Control, useFieldArray } from 'react-hook-form'
import { ProductLightTableView } from './ProductLightTableView'

export interface IProductsForListProps {
  control: Control<ProductEditDto, any>
}

export const ProductsForList = ({ control }: IProductsForListProps) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { fields, append, remove } = useFieldArray({
    control,
    keyName: 'propId',
    name: 'replacementFor'
  })

  const addSuitableProducts = (selectedProducts: ProductLightDto[]) => {
    setOpenModal(false)
    let uniqueParts: any[] = []
    selectedProducts.forEach((part) => {
      if (!fields.some((s) => s.code === part.code)) {
        uniqueParts.push(part)
      }
    })
    append(uniqueParts)
  }
  return (
    <Stack>
      {openModal && (
        <ProductPartModal onConfirm={addSuitableProducts} onCancel={() => setOpenModal(false)} />
      )}
      <Divider style={{ margin: '1em 0' }} />
      <Stack direction={'row'} alignItems={'center'}>
        <Typography flex={1} variant='body1' color={'grey'}>
          {t('labels.suitableFor')}
        </Typography>
        <Stack>
          <IconButton color='primary' onClick={() => setOpenModal(true)}>
            <Add />
          </IconButton>
        </Stack>
      </Stack>
      <ProductLightTableView data={fields} remove={remove} />
    </Stack>
  )
}
