import { Chip, Divider, Paper, Stack, Typography } from '@mui/material'
import { ProductOverviewDto } from '../../../models/product/Product'
import { Carousel } from '../../../components/carousel/Carousel'
import { useTranslation } from 'react-i18next'
import { ProductPropertyDto } from '../../../models/product/ProductProperty'
import { DisplayInfo } from '../../../components/commonViews/DisplayInfo'
import { ml } from '../../../../utils/cssExtensions'
import { ContentCopy } from '@mui/icons-material'
import { CopyToClipboardButton } from '../../../../utils/copyToClipboardButton'
import { enqueueSnackbar } from 'notistack'

interface IProductOverviewDetailsProps {
  product: ProductOverviewDto
}

export const ProductOverviewDetails = ({ product }: IProductOverviewDetailsProps) => {
  const { t, i18n } = useTranslation()

  return (
    <Paper elevation={1}>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        padding={4}
        spacing={6}
        divider={<Divider orientation='vertical' flexItem />}
      >
        <Stack flex={1}>
          <Carousel images={product.selectedImages} height={200} />
        </Stack>
        <Stack flex={1} justifyContent='space-between' alignItems='stretch'>
          <Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack>
                <Typography variant='h4' fontWeight={500}>
                  {i18n.language === 'en' ? product.productTypeLabelEn : product.productTypeLabelGr}
                  <Chip
                    {...ml('1em')}
                    label={product.stalId}
                    color='primary'
                    deleteIcon={<ContentCopy style={{ fontSize: '18px' }} />}
                    onDelete={async () =>
                      await CopyToClipboardButton(product.stalId, enqueueSnackbar)
                    }
                  />
                </Typography>
                <Typography variant='h6' fontWeight={400} marginBottom={3}>
                  {i18n.language === 'en'
                    ? product.selectedDescriptionEn
                    : product.selectedDescriptionEn}
                </Typography>
              </Stack>
              <Stack>
                {/* <IconButton
                  color='primary'
                  onClick={() => navigate('/manager/products/' + product.selectedId)}
                >
                  <Create fontSize='medium' />
                </IconButton> */}
              </Stack>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack flex={1}>
                <Typography marginBottom={2} variant='body1' fontWeight={500}>
                  {t('labels.details')}
                </Typography>
                <DisplayInfo
                  marginBottom={1}
                  label={t('labels.priceFrom')}
                  value={product.lowerPrice + ' €'}
                />
                <DisplayInfo
                  marginBottom={1}
                  label={t('labels.priceTo')}
                  value={product.higherPrice + ' €'}
                />
              </Stack>
              <Stack flex={1}>
                <Typography marginBottom={2} variant='body1' fontWeight={500}>
                  {t('labels.specs')}
                </Typography>
                {product.properties.map((prop: ProductPropertyDto, i: number) => (
                  <DisplayInfo
                    key={i}
                    marginBottom={1}
                    label={i18n.language === 'en-US' ? prop.labelEn : prop.labelGr}
                    value={prop.productKeyValue}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}
