import { Divider, Stack, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Control, Controller, UseFormGetValues, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InvoiceEditDto } from '../../../../../models/invoice/InvoiceSale'
import { DisplayInfo } from '../../../../../components/commonViews/DisplayInfo'
import { my } from '../../../../../../utils/cssExtensions'
import {
  getTotalDiscountAmount,
  getTotalNoTaxNoDiscountmount,
  getTotalQuantity,
  getTotalTaxAmount
} from './InvoiceItemCalculations'

export interface IInvoiceSummaryProps {
  control: Control<InvoiceEditDto, any>
  getValues: UseFormGetValues<InvoiceEditDto>
  disableForm: boolean
  watch: UseFormWatch<InvoiceEditDto>
}
export const InvoiceSummary = ({
  control,
  getValues,
  watch,
  disableForm
}: IInvoiceSummaryProps) => {
  const { t } = useTranslation()
  const invoice = getValues()
  watch('paymentMethod')
  const totalQuantity = getTotalQuantity(invoice.items)
  const totalNoTaxNoDiscountmount = +getTotalNoTaxNoDiscountmount(invoice.items).toFixed(2)
  const totalDiscountAmount = +getTotalDiscountAmount(invoice.items).toFixed(2)
  const totalTaxAmount = +getTotalTaxAmount(invoice.items).toFixed(2)
  const total = totalNoTaxNoDiscountmount - totalDiscountAmount + totalTaxAmount

  const currentDebt = () => {
    if (disableForm) return invoice.currentDedt
    let debt = +(invoice.previousDebt ?? 0)
    if (invoice.paymentMethod === 5) {
      debt += total
    }
    return debt === 0 ? 0 : debt.toFixed(2)
  }

  return (
    <Stack padding={2}>
      <Grid container spacing={2}>
        <Grid container xs={8} spacing={2}>
          <Grid xs={8}>
            <Controller
              control={control}
              defaultValue={''}
              name='comments'
              render={({ field: { ref, ...field } }) => (
                <TextField
                  disabled={disableForm}
                  id='outlined-multiline-flexible'
                  label={t('labels.comments')}
                  fullWidth
                  inputRef={ref}
                  {...field}
                  multiline
                  rows={6}
                />
              )}
            />
          </Grid>
          <Grid xs={4}>
            <Typography variant='h6' component='div'>
              {t('labels.debtTotal')}
            </Typography>
            <DisplayInfo
              label={t('labels.lastDebt')}
              justifyContent='space-between'
              valueSuffix={'€'}
              value={invoice.previousDebt ?? 0}
            />
            <DisplayInfo
              label={t('labels.newDebt')}
              justifyContent='space-between'
              valueSuffix={'€'}
              value={currentDebt()}
            />
          </Grid>
        </Grid>
        <Grid xs={4}>
          <Typography variant='h6' component='div'>
            {t('labels.analysis')}
          </Typography>
          <DisplayInfo
            label={t('labels.totalQuantity')}
            justifyContent='space-between'
            value={totalQuantity.toString()}
          />
          <DisplayInfo
            label={t('labels.sumPreDiscount')}
            justifyContent='space-between'
            valueSuffix={'€'}
            value={totalNoTaxNoDiscountmount}
          />
          <Divider {...my('10px')} />
          <DisplayInfo
            label={t('labels.totalDiscount')}
            justifyContent='space-between'
            valueSuffix={'€'}
            value={totalDiscountAmount}
          />
          <DisplayInfo
            label={t('labels.sumAfterDiscount')}
            justifyContent='space-between'
            valueSuffix={'€'}
            value={totalNoTaxNoDiscountmount - totalDiscountAmount}
          />
          <DisplayInfo
            label={t('labels.totalTax')}
            justifyContent='space-between'
            valueSuffix={'€'}
            value={totalTaxAmount}
          />
          <Divider {...my('10px')} />
          <DisplayInfo
            labelFontSize={20}
            justifyContent='space-between'
            valueFontSize={20}
            label={t('labels.total')}
            valueSuffix={'€'}
            value={total.toFixed(2)}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}
