import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProtocolDto } from '../../models/product/Protocol'
import { fieldProps } from '../../../utils/FormExtensions'
import { useProtocol } from './useProtocol'
import { Loader } from '../../components/loader/loader'

export interface IProtocolModalProps {
  entity: ProtocolDto
  onConfirm: any
  onCancel: any
}
export const ProtocolModal = ({ onCancel, onConfirm, entity }: IProtocolModalProps) => {
  const { t } = useTranslation()
  const title = entity?.id ? 'editRecord' : 'newRecord'
  const { create, loader, update } = useProtocol()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ProtocolDto>({
    defaultValues: entity
  })

  const sumbitForm = async (data: ProtocolDto) => {
    if (entity.id) {
      await update(data)
    } else {
      await create(data)
    }
    onConfirm()
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle id='alert-dialog-title'>{t('labels.' + title)}</DialogTitle>
      <DialogContent>
        {loader && <Loader type='circles' />}
        {!loader && (
          <>
            <Controller
              control={control}
              defaultValue=''
              name='name'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField {...fieldProps(t('labels.name'), ref, field)} error={!!errors.name} />
              )}
            />
            <Controller
              control={control}
              defaultValue={0}
              name='number'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.number'), ref, field)}
                  type='number'
                  error={!!errors.number}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=''
              name='description'
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.description'), ref, field)}
                  error={!!errors.description}
                />
              )}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        {!loader && (
          <Button color='success' onClick={handleSubmit(sumbitForm)} autoFocus>
            {t('buttons.insert')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
