import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { useLookup } from '../../services/useLookup'
import { commonColors } from '../../../utils/useManagerTheme'
import { useTranslation } from 'react-i18next'
import { ICountryLookup } from '../../models/Country'

export interface ICountryLookupSelectProps {
  errors: FieldErrors<any>
  control: Control<any, any>
  fieldName: string
  label: string
  margin?: any
  flex?: string
  disabled?: boolean
  defaultValue?: string
}

const sortLookup = (data: ICountryLookup[], lang: string): ICountryLookup[] => {
  if (lang === 'en') return data.sort((a, b) => a.nameEn.localeCompare(b.nameEn))
  return data.sort((a, b) => a.nameGr.localeCompare(b.nameGr))
}

export const CountrySelect = ({
  control,
  fieldName,
  errors,
  margin = 'none',
  label,
  flex = 'unset',
  defaultValue = '9285a2ab-f8ef-44af-8324-717308e1609a',
  disabled = false
}: ICountryLookupSelectProps) => {
  const { loading, lookup } = useLookup<ICountryLookup>('lookup', 'countries')
  const { i18n } = useTranslation()
  let data = sortLookup(lookup, i18n.language)

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={defaultValue}
      rules={{ required: true }}
      render={({ field }) => (
        <FormControl size='small' style={{ flex: flex }} fullWidth margin={margin}>
          <InputLabel id={label + '_id'}>{label}</InputLabel>
          {data && data.length > 0 && (
            <Select
              {...field}
              fullWidth
              error={!!errors[fieldName]}
              label={label}
              disabled={loading || disabled}
            >
              {data.map((l, index) => (
                <MenuItem key={index} value={l.id}>
                  {i18n.language === 'en' ? l.nameEn : l.nameGr}
                </MenuItem>
              ))}
            </Select>
          )}
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: commonColors.bluesPrimaryBlue,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px'
              }}
            />
          )}
        </FormControl>
      )}
    />
  )
}
