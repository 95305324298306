import Chip from '@mui/material/Chip'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import PersonIcon from '@mui/icons-material/Person'
import { CustomerType } from '../../models/Customer'
import { useTranslation } from 'react-i18next'

const getChip = (t: any, type: number): any => {
  switch (type) {
    case 0:
      return (
        <Chip
          label={t('labels.company')}
          style={{ color: 'whitesmoke' }}
          color={'info'}
          icon={<AccountBalanceIcon fontSize={'small'} />}
        />
      )
    case 1:
      return (
        <Chip
          label={t('labels.retail')}
          color={'secondary'}
          icon={<PersonIcon fontSize={'small'} />}
        />
      )
    default:
      return (
        <Chip
          label={t('labels.retail')}
          color={'secondary'}
          icon={<PersonIcon fontSize={'small'} />}
        />
      )
  }
}

export const CustomerTypeChip = ({ type }: { type: CustomerType }) => {
  const { t } = useTranslation()
  return getChip(t, type)
}
