import Grid from '@mui/material/Unstable_Grid2'
import { InvoiceTableFilters } from './InvoiceTableFilters'
import { InvoiceTable } from './InvoiceTable'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { IInvoicePagination } from '../../../models/invoice/InvoiceSale'
import { useInvoicesPaginated } from '../useInvoicesPaginated'

const defaultReq: IInvoicePagination = {
  page: 1,
  size: 10,
  paymentMethod: undefined,
  protocolId: undefined,
  protocolNumber: undefined,
  taxId: undefined,
  typeId: '',
  from: undefined,
  to: undefined
}
const filterInputNames = ['typeId']
export const InvoicesContainer = () => {
  const { loading, searchInvoices, invoiceState } = useInvoicesPaginated()
  const {
    control,
    watch,
    reset,
    getValues,
    formState: { errors }
  } = useForm<IInvoicePagination>({
    defaultValues: defaultReq,
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (filterInputNames.some((x) => x === name)) {
        searchInvoices(getValues())
      }
    })
    return () => subscription.unsubscribe()
  }, [getValues, searchInvoices, watch])

  useEffect(() => {
    searchInvoices(getValues())
  }, [getValues, searchInvoices])

  const clearFilters = () => {
    reset(defaultReq)
    searchInvoices(getValues())
  }

  const onEnterPushed = (e: any) => {
    if (e.key === 'Enter') {
      if (e.target.value.length < 1) return
      searchInvoices(getValues())
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <InvoiceTableFilters
          control={control}
          onEnterPushed={onEnterPushed}
          errors={errors}
          clearFilters={clearFilters}
        />
      </Grid>
      <Grid xs={12}>
        <InvoiceTable loading={loading} invoiceState={invoiceState} />
      </Grid>
    </Grid>
  )
}
