import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import { CustomerDetailsDto } from '../../models/Customer'

export interface ICustomerProps {
  customerLoader: boolean
  customer?: CustomerDetailsDto
  createCustomer: any
  updateCustomer: any
  deleteCustomer: (id: string) => Promise<boolean>
  getCustomer: (id: string) => Promise<void>
}

export function useCustomer(id?: string): ICustomerProps {
  const [customerLoader, setLoading] = useState(false)
  const [customer, setCustomer] = useState<CustomerDetailsDto>()
  const service = useBaseService('customer')
  const navigate = useNavigate()

  const updateCustomer = useCallback(
    async (warehouse: CustomerDetailsDto) => {
      setLoading(true)
      const response = await service.update<CustomerDetailsDto>(warehouse, warehouse.id)
      if (response) setCustomer(response)
      setLoading(false)
    },
    [service]
  )

  const deleteCustomer = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await service.remove(id)
      setLoading(false)
      return response === true
    },
    [service]
  )

  const getCustomer = useCallback(
    async (id?: string) => {
      if (!id || id === 'new') return
      setLoading(true)
      const response = await service.getById<CustomerDetailsDto>(id, `edit`)
      if (response) setCustomer(response)
      setLoading(false)
    },
    [service]
  )

  useEffect(() => {
    if (!id || id === 'new') {
      setCustomer({} as CustomerDetailsDto)
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<CustomerDetailsDto>(id, `edit`)
      if (response) setCustomer(response)
      setLoading(false)
    }
    getById(id)
  }, [id, service, setLoading])

  const createCustomer = useCallback(
    async (customer: CustomerDetailsDto) => {
      setLoading(true)
      const response = await service.create(customer)
      if (response) {
        setCustomer(response)
        navigate(`/manager/customers/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    customerLoader,
    customer,
    updateCustomer,
    createCustomer,
    deleteCustomer,
    getCustomer
  }
}
