import { useCallback, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { CustomerTableDto } from '../../models/Customer'
import { IPageState } from '../../components/material/IMiPaginatedDataGridProps'

export interface ISearchProps {
  field: string
  search: string
  page: number
  size: number
}
export interface IUseCustomers {
  searchCustomers: (options: ISearchProps) => void
  customersState: IPageState<CustomerTableDto>
  removeEntity: (id: string) => void
}

export function usePaginatedCustomers(): IUseCustomers {
  const [customersState, setCustomersState] = useState({
    data: [] as any,
    loading: false,
    page: 0,
    pageSize: 20,
    total: 0
  } as IPageState<CustomerTableDto>)
  const service = useBaseService('customer')

  const removeEntity = (id: string) => {
    setCustomersState((prev) => {
      return {
        ...prev,
        data: prev.data?.filter((x) => x.id !== id)
      }
    })
  }

  const searchCustomers = useCallback(
    async (filters: ISearchProps) => {    
      setCustomersState((prev) => {
        return { ...prev, loading: true }
      })

      const response: any = await service.getAll<any>(
        `search/${filters.page}/${filters.size}/${filters.field}/${filters.search}`
      )

      if (response) {
        setCustomersState({
          data: response.items,
          loading: false,
          page: response.currentPage,
          pageSize: filters.size,
          total: response.totalItems
        })
      } else {
        setCustomersState((prev) => {
          return { ...prev, loading: false }
        })
      }
    },
    [service]
  )

  return {
    searchCustomers,
    customersState,
    removeEntity
  }
}
