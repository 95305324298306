import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Loader } from '../../../components/loader/loader'
import { SideActions } from '../../../components/material/SideActions'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'
import { useCustomer } from '../useCustomer'
import { CustomerDetails } from './CustomerDetails'
import { CustomerDetailsDto } from '../../../models/Customer'
import { useExternalCompanyDetails } from '../../../hooks/useExternalCompanyDetails'

export const CustomerContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { getCompanyDetails } = useExternalCompanyDetails()
  const navigation = useNavigate()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const { createCustomer, customerLoader, deleteCustomer, updateCustomer, customer } =
    useCustomer(id)
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors }
  } = useForm<CustomerDetailsDto>({
    values: { ...customer } as CustomerDetailsDto,
    reValidateMode: 'onChange',
    resetOptions: {
      keepDirtyValues: true // keep dirty fields unchanged, but update defaultValues
    }
  })

  useEffect(() => {
    reset({ ...customer })
  }, [reset, customer])

  async function handleRemove() {
    if (customer?.id) {
      setConfirmationModal(true)
    }
  }

  const confirmDeletion = async () => {
    var success = await deleteCustomer(customer?.id as string)
    if (success) {
      navigation('/manager/customers')
    }
  }
  function onSubmit(data: any) {
    if (data.id) updateCustomer(data)
    else createCustomer(data)
  }

  const getExternalCompanyDetails = async () => {
    const taxId = getValues('tin')
    await getCompanyDetails(taxId)
  }

  if (customerLoader) return <Loader isFullscreen type={'circles'} />

  return (
    <>
      <ConfirmationModal
        open={confirmationModal}
        title={t('messages.deleteEntity', {
          entityName: customer?.fullname
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={confirmDeletion}
      />
      <Grid container spacing={2}>
        <Grid xs={9}>
          <CustomerDetails
            getExternalCompanyDetails={getExternalCompanyDetails}
            control={control}
            customer={customer}
            errors={errors}
          />
        </Grid>
        <Grid xs={3}>
          <SideActions
            isTransparent
            elevation={0}
            buttons={[
              <Button
                key={0}
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                {t('buttons.update')}
              </Button>,
              <Button
                key={3}
                variant='contained'
                fullWidth
                color='secondary'
                onClick={() => navigation(`/manager/customers/overview/${customer?.id}`)}
              >
                {t('breacrumb.customerOverview')}
              </Button>,
              <Button
                key={1}
                variant='outlined'
                fullWidth
                color='primary'
                onClick={() => navigation(-1)}
              >
                {t('buttons.back')}
              </Button>,
              <Button key={2} fullWidth variant='contained' color='error' onClick={handleRemove}>
                {t('buttons.remove')}
              </Button>
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
