import { Stack } from '@mui/material'

interface IRangeDatePickerProps {
  labelFrom?: string
  control: any
  labelTo?: string
  size?: 'small' | 'medium'
}

export const RangeDatePicker = ({
  labelFrom,
  labelTo,
  control,
  size = 'small'
}: IRangeDatePickerProps) => {
  // const { t } = useTranslation()
  // const _labelFrom = labelFrom ?? t('labels.from')
  // const _labelTo = labelTo ?? t('labels.to')
  return (
    <Stack flex={1} direction={'row'} justifyContent='start' alignItems='start' spacing={2}>
      {/* <Controller
        control={control}
        name='from'
        render={({ field }) => (
          <DatePicker
            label={_labelFrom}
            {...field}
            format='DD/MM/YYYY'
            slotProps={{
              field: {
                clearable: true
              },
              textField: { size: size }
            }}
          />
        )}
      /> */}
      {/* <Controller
        control={control}
        name='to'
        render={({ field }) => (
          <DatePicker
            label={_labelTo}
            format='DD/MM/YYYY'
            {...field}
            slotProps={{
              field: {
                clearable: true
              },
              textField: { size: size }
            }}
          />
        )}
      /> */}
    </Stack>
  )
}
