import { Link, useLocation } from 'react-router-dom'
import {
  Breadcrumbs,
  ButtonGroup,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material'
import { LanguageOutlined } from '@mui/icons-material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { commonColors } from '../../../utils/useManagerTheme'

const breadcrumbNameMap: { [key: string]: string } = {
  '/manager': 'manager',
  '/manager/customers': 'customers',
  '/manager/products': 'products',
  '/manager/customers/overview': 'customerOverview',
  '/manager/products/overview': 'productOverview',
  '/manager/invoices': 'invoices',
  '/manager/invoices/sales': 'invoiceSales',
  '/manager/invoices/sales/new': 'createNew',
  '/manager/invoices/purchases': 'invoicePurchases',
  '/manager/invoices/purchases/new': 'createNew',
  '/manager/users': 'users',
  '/manager/settings': 'appSettings',
  '/manager/settings/warehouses': 'warehouses',
  '/manager/settings/warehouses/new': 'createNew',
  '/manager/settings/manufacturers': 'manufacturers',
  '/manager/settings/manufacturers/new': 'createNew',
  '/manager/settings/products/new': 'createNew',
  '/manager/settings/productkeys': 'productKeys',
  '/manager/settings/productkeys/new': 'createNew',
  '/manager/settings/producttypes': 'productTypes',
  '/manager/settings/producttypes/new': 'createNew',
  '/manager/settings/suppliers': 'suppliers',
  '/manager/settings/suppliers/new': 'createNew',
  '/manager/settings/taxes': 'taxes',
  '/manager/settings/taxes/new': 'createNew',
  '/manager/settings/profitability': 'suggestedSellingPrice',
  '/manager/settings/aade': 'aade'
}
const useStyles = makeStyles()((theme) => {
  return {
    root: {
      background: commonColors.bluesPrimaryBlue,
      color: commonColors.white,
      paddingLeft: '30px',
      paddingRight: '20px'
    }
  }
})
const findBreadCrumbOrDefault = (key: string, defaultValue: string, t: any) => {
  var breadcrumb = breadcrumbNameMap[key?.toLocaleLowerCase()]
  return breadcrumb ? t(`breacrumb.${breadcrumb}`) : defaultValue
}

export const ManagerNavbar = () => {
  const { t, i18n } = useTranslation()
  const { classes } = useStyles()
  const changeLanguage = (lan: string) => {
    i18n.changeLanguage(lan)
    handleClose()
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  return (
    <Stack
      className={classes.root}
      height={'100%'}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Stack>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link style={{ color: 'white', textDecoration: 'none' }} to='/'>
            {t('breacrumb.home')}
          </Link>
          {pathnames.map((_, index) => {
            const last = index === pathnames.length - 1
            const to = `/${pathnames.slice(0, index + 1).join('/')}`
            const breadcrumb = findBreadCrumbOrDefault(to, pathnames[pathnames.length - 1], t)
            return last ? (
              <Typography color='white' key={to}>
                {breadcrumb}
              </Typography>
            ) : (
              <Link style={{ color: 'white', textDecoration: 'none' }} to={to} key={to}>
                {breadcrumb}
              </Link>
            )
          })}
        </Breadcrumbs>
      </Stack>
      <Stack>
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton style={{ color: 'white' }} onClick={handleClick}>
            <LanguageOutlined fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
        <Menu
          anchorEl={anchorEl}
          id='language-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
          <MenuItem onClick={() => changeLanguage('gr')}>Ελληνικά</MenuItem>
        </Menu>
      </Stack>
    </Stack>
  )
}
