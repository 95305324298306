export const TimologioLianikhs = '2a8edca8-b2b3-4ee9-9343-19fb1883e9f5'

export const PaymentMethods = [
  {
    id: 5,
    valueEn: 'Credit',
    valueGr: 'Πίστωση'
  },
  {
    id: 3,
    valueEn: 'Cash',
    valueGr: 'Μετρητά'
  }
]
