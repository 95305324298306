import { useEffect, useState } from 'react'
import { useBaseService } from '../services/useBaseService'

export interface IPriceSuggestionLookup {
  id: string
  profitMarginToBeAdded: number
}
export interface IUseProduuseSuggestingPricesConfig {
  loading: boolean
  suggestingPricesConfig: IPriceSuggestionLookup[]
}

export function useSuggestingPricesConfig(): IUseProduuseSuggestingPricesConfig {
  const [loading, setLoading] = useState(true)
  const [suggestingPricesConfig, setSuggestingPricesConfig] = useState<IPriceSuggestionLookup[]>([])
  const service = useBaseService('ProductCategoryPriceSuggestion')

  useEffect(() => {
    if (!service) return

    async function fetchSuggestingPricesConfig() {
      setLoading(true)

      const response = await service.getAll<IPriceSuggestionLookup>(`lookup`)
      if (response) setSuggestingPricesConfig(response)
      setLoading(false)
    }

    fetchSuggestingPricesConfig()
  }, [service])

  return {
    loading,
    suggestingPricesConfig
  }
}
