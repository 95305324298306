import { useCallback, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'

import { ProtocolDto } from '../../models/product/Protocol'

export interface IProtocolProps {
  loader: boolean
  create: any
  update: any
}

export function useProtocol(): IProtocolProps {
  const [loader, setLoading] = useState(false)
  const service = useBaseService('protocol')

  const update = useCallback(
    async (entity: ProtocolDto) => {
      setLoading(true)
      const result = await service.update<ProtocolDto>(entity, entity.id)
      setLoading(false)
      return result
    },
    [service]
  )

  const create = useCallback(
    async (entity: ProtocolDto) => {
      setLoading(true)
      const result = await service.create(entity)
      setLoading(false)
      return result
    },
    [service]
  )

  return {
    loader,
    update,
    create
  }
}
