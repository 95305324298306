export interface IContactInfo {
  contactInfo: ContactDto[]
}

export class ContactDto {
  id: string
  contactValue: string
  type: ContactType
  details: string
}

export enum ContactType {
  Email,
  Phone,
  Mobile,
  Fax
}

export interface IPaginationResult {
  totalItems: number
  totalPages: number
  currentPage: number
  Items: []
}
