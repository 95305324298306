import { Card, CardContent, Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { makeStyles } from 'tss-react/mui'
import { Loader } from '../loader/loader'
import { EmptyTable } from '../emptyTable/EmptyTable'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      border: 'none'
    },
    overlayWrapper: { height: '300px' }
  }
})

export interface IMiDatagridProps {
  data: any[]
  columns: GridColDef[]
  loading: boolean
  title?: string | null
  actions?: any
  rowId?: string
  disableColumnMenu?: boolean
}

export const MiDatagrid = ({
  data,
  columns,
  loading,
  title,
  actions,
  rowId = 'id',
  disableColumnMenu = false
}: IMiDatagridProps) => {
  const { classes } = useStyles()
  columns = columns.map((x) => ({
    ...x,
    headerClassName: 'headerCell'
  }))
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        {title && (
          <Stack
            padding={1}
            direction='row'
            spacing={2}
            alignItems={'center'}
            alignContent={'space-between'}
          >
            <Stack flex={1}>
              <Typography variant='h5' component='div'>
                {title}
              </Typography>
            </Stack>
            <Stack>{actions}</Stack>
          </Stack>
        )}

        <DataGrid
          className={classes.root}
          rows={loading ? [] : data ?? []}
          loading={loading}
          slots={{
            loadingOverlay: () => <Loader type='circles' noText />,
            noRowsOverlay: () => <EmptyTable />
          }}
          getRowId={(row) => row[rowId]}
          columns={columns}
          disableRowSelectionOnClick
          pageSizeOptions={[10, 30, 100]}
          disableColumnMenu={disableColumnMenu}
        />
      </CardContent>
    </Card>
  )
}
