import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { fieldProps } from '../../../../utils/FormExtensions'
import { ContactDto } from '../../../models/Common'

export interface IContactModalProps {
  entity?: ContactDto
  onConfirm: any
  onCancel: any
  disableDetails?: boolean
}
export const ContactModal = ({
  onCancel,
  onConfirm,
  entity,
  disableDetails = false
}: IContactModalProps) => {
  const { t } = useTranslation()
  const title = entity?.id ? 'editRecord' : 'newRecord'

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ContactDto>({
    defaultValues: entity
  })

  const sumbitForm = (data: ContactDto) => {
    if (disableDetails) {
      data.details = ''
    }
    onConfirm(data)
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle id='alert-dialog-title'>{t('labels.' + title)}</DialogTitle>
      <DialogContent>
        <Controller
          control={control}
          name='type'
          defaultValue={0}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl size='small' fullWidth margin='normal'>
              <InputLabel id='addtype22'> {t('labels.type')}</InputLabel>
              <Select {...field} fullWidth error={!!errors.type} label={t('labels.type')}>
                <MenuItem key={1} value={0}>
                  Email
                </MenuItem>
                <MenuItem key={2} value={1}>
                  Phone
                </MenuItem>
                <MenuItem key={3} value={2}>
                  Mobile
                </MenuItem>
                <MenuItem key={4} value={3}>
                  Fax
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          defaultValue=''
          name='contactValue'
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...fieldProps(t('labels.contactValue'), ref, field)}
              error={!!errors.contactValue}
            />
          )}
        />
        {!disableDetails && (
          <Controller
            control={control}
            defaultValue=''
            name='details'
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...fieldProps(t('labels.details'), ref, field)}
                error={!!errors.details}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        <Button color='success' onClick={handleSubmit(sumbitForm)} autoFocus>
          {t('buttons.insert')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
