import { useState, useCallback } from 'react'
import axios from 'axios'

const externalAutocompleteUrl = `https://publicity.businessportal.gr/api/autocomplete/`
const externalDetailsUrl = `https://publicity.businessportal.gr/api/company/details`

interface IExternalCompanyDetails {
  loading: boolean
  getCompanyDetails: (taxId: string) => Promise<void>
  data: any
}

export const useExternalCompanyDetails = (): IExternalCompanyDetails => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const getCompanyDetails = useCallback(async (taxId: string) => {
    setLoading(true)
    try {
      const autocomplete = await axios.get<any>(`${externalAutocompleteUrl}${taxId}`)
      const payload = { query: { arGEMI: autocomplete.data?.payload?.autocomplete[0].arGemi } }
      const companydetails = await axios.post<any>(externalDetailsUrl, payload)
      setData(companydetails.data?.companyInfo)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    data,
    getCompanyDetails
  }
}
