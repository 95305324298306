import { useCallback, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { ManufacturerCodeDto } from '../../models/Manufacturer'
import { enqueueSnackbar } from 'notistack'

export interface IManufacturerCodeProps {
  manufacturerCodeLoader: boolean
  manufacturerCode?: ManufacturerCodeDto | null
  response: string | null
  createManufacturerCode: (id: string, code: string) => Promise<void>
  searchManufacturerCode: (id: string, code: string) => Promise<void>
  searchCodeLight: (id: string, code: string) => Promise<ManufacturerCodeDto | null | undefined>
  deleteManufacturerCode: (id: string, code: string) => Promise<boolean>
  clear: () => void
}

export function useManufacturerCode(): IManufacturerCodeProps {
  const [manufacturerCodeLoader, setLoading] = useState(false)
  const [manufacturerCode, setManufacturerCode] = useState<ManufacturerCodeDto | null>()
  const [response, setResponse] = useState<string | null>(null)
  const service = useBaseService('manufacturer')

  const searchManufacturerCode = useCallback(
    async (id: string, code: string) => {
      setLoading(true)
      const response = await service.getById<ManufacturerCodeDto>(
        id,
        `manufacturer/search/code/${id}/${code}`
      )
      setManufacturerCode(response)
      if (response == null) setResponse(`Ο κωδικός ${code} δεν βρέθηκε. Θέλετε να τον προσθέσετε?`)
      setLoading(false)
    },
    [service]
  )

  const searchCodeLight = useCallback(
    async (id: string, code: string) => {
      setLoading(true)
      const response = await service.getById<ManufacturerCodeDto>(
        id,
        `manufacturer/search/code/${id}/${code}`
      )
      setLoading(false)
      return response
    },
    [service]
  )

  const clear = () => {
    setManufacturerCode(null)
    setResponse(null)
  }
  const deleteManufacturerCode = useCallback(
    async (id: string, code: string) => {
      setLoading(true)
      const response = await service.remove(id, `manufacturer/code/${id}/${code}`)
      setManufacturerCode(null)
      setLoading(false)
      enqueueSnackbar('Επιτυχής Διαγραφή', {
        variant: 'info',
        autoHideDuration: 2000
      })
      return response === true
    },
    [service]
  )

  const createManufacturerCode = useCallback(
    async (id: string, code: string) => {
      setLoading(true)
      const response = await service.post<ManufacturerCodeDto>(
        {},
        `manufacturer/code/${id}/${code}`
      )
      if (response) {
        enqueueSnackbar('Επιτυχής Εισαγωγή', {
          variant: 'success',
          autoHideDuration: 2000
        })
        setManufacturerCode(response)
        setResponse(null)
      }
      setLoading(false)
    },
    [service]
  )

  return {
    manufacturerCodeLoader,
    response,
    clear,
    manufacturerCode,
    searchManufacturerCode,
    searchCodeLight,
    createManufacturerCode,
    deleteManufacturerCode
  }
}
