import { ImageListItem, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const EHomeContainer = () => {
  const { t } = useTranslation()

  return (
    <Stack direction={'column'}>
      <Stack
        flex={1}
        alignItems={'center'}
        alignSelf={'center'}
        paddingBottom={1}
        style={{
          background: '#ffffffa3',
          width: '100%',
          boxShadow: '0px 5px 20px 9px #aa775e47',
          zIndex: '999'
        }}
      >
        <ImageListItem>
          <img
            style={{ maxWidth: '150px', maxHeight: '100%', objectFit: 'cover' }}
            srcSet={require(`../../../images/logo.png`)}
            src={require(`../../../images/logo.png`)}
            alt={'logo'}
            loading='lazy'
          />
        </ImageListItem>
      </Stack>
      <Stack flex={1} style={{ position: 'relative' }}>
        <img
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
          srcSet={require(`../../../images/home.jpg`)}
          src={require(`../../../images/home.jpg`)}
          alt={'logo'}
          loading='lazy'
        />
        <div
          style={{
            background: '#0000006d',
            position: 'absolute',
            top: '0',
            left: '0',
            height: '100%',
            width: '100%',
            margin: 'auto'
          }}
        >
          <div
            style={{
              position: 'relative',
              width: '80%',
              color: 'whitesmoke',
              textAlign: 'center',
              margin: 'auto',
              top: '15%'
            }}
          >
            <Typography
              fontSize={{
                lg: 48,
                md: 32,
                sm: 22,
                xs: 15
              }}
            >
              Η ιστοσελίδα μας είναι υπό κατασκευή καθώς ετοιμάζουμε κάτι νέο για εσάς. Σύντομα θα
              είμαστε ξανά κοντά σας, μείνετε συντονισμένοι!
            </Typography>
          </div>
        </div>
      </Stack>
    </Stack>
  )
}
