import { Paper, Stack } from '@mui/material'

export interface ISideActionsProps {
  buttons: any[]
  isTransparent: boolean
  elevation?: number
}
export const SideActions = ({ buttons, elevation = 1, isTransparent }: any) => (
  <Paper sx={{ background: isTransparent ? 'transparent' : 'white' }} elevation={elevation}>
    <Stack
      spacing={2}
      direction='column'
      justifyContent='space-between'
      alignItems='start'
    >
      {buttons.map((_: any, i: number) => _)}
    </Stack>
  </Paper>
)
