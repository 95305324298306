import { Button, ButtonGroup, Chip, IconButton, Stack } from '@mui/material'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import { useTranslation } from 'react-i18next'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import moment from 'moment'
import { CustomerTranInvoiceDto } from '../../../models/Customer'
import { Print } from '@mui/icons-material'
import LaunchIcon from '@mui/icons-material/Launch'
import { DeleteOutline } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import usePrint from '../../../hooks/usePrint'
interface ICustomerInvoicesProps {
  invoices: any[]
  loading: boolean
  addTransaction: any
  deleteTransaction: any
  id: string
}

const sortInvoices = (invoices: CustomerTranInvoiceDto[]) => {
  if (!invoices) return []
  const invoicesCopy = [...invoices]
  invoicesCopy.sort((a, b) => {
    return new Date(b.created).getTime() - new Date(a.created).getTime()
  })
  return invoicesCopy
}

export const CustomerInvoices = ({
  invoices,
  loading,
  addTransaction,
  deleteTransaction,
  id
}: ICustomerInvoicesProps) => {
  const { printDocument, printLoader } = usePrint()
  const { t } = useTranslation()
  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.createdAt') as string,
      field: 'created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => moment(params.row?.created).format('DD/MM/YYYY')
    },
    {
      headerName: t('labels.type') as string,
      field: 'transactionCategory',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          color={params.row.transactionCategory === 0 ? 'secondary' : 'info'}
          style={{ width: '120px', color: 'whitesmoke' }}
          label={params.row.transactionCategory === 0 ? 'Απόδειξη' : 'Παραστατικό'}
        />
      )
    },
    {
      headerName: t('labels.details') as string,
      field: 'descriptionEn',
      flex: 1
    },
    {
      headerName: t('labels.paymentMethod') as string,
      field: 'transactionType',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        `${params.row.transactionType === 0 ? t('labels.credit') : t('labels.cash')}`
    },
    {
      headerName: t('labels.amount') as string,
      field: 'amount',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => `${params.row.amount} €`
    },
    {
      headerName: t('labels.protocol') as string,
      field: 'protocolNumber',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          variant='outlined'
          color='secondary'
          style={{ width: '90px' }}
          label={params.row.protocolNumber}
        />
      )
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      disableColumnMenu: true,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          {params.row.transactionCategory === 1 && (
            <IconButton
              color='primary'
              onClick={() =>
                window.open(
                  `/manager/invoices/sales/${params.row.id}`,
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              <LaunchIcon fontSize={'small'} />
            </IconButton>
          )}
          {params.row.transactionCategory === 0 && (
            <IconButton color='error' onClick={() => deleteTransaction(params.row.id)}>
              <DeleteOutline fontSize={'small'} />
            </IconButton>
          )}
        </ButtonGroup>
      )
    }
  ]
  return (
    <Stack>
      <MiDatagrid
        title={t('labels.transactions')}
        actions={
          <ButtonGroup>
            <Button
              key={0}
              variant='contained'
              color='secondary'
              size='medium'
              onClick={addTransaction}
            >
              {t('buttons.transaction')}
            </Button>
            <LoadingButton
              key={1}
              size='medium'
              startIcon={<Print />}
              loading={printLoader}
              variant='contained'
              onClick={() => printDocument(`customer/transactions/print/${id}`)}
            >
              {t('buttons.print')}
            </LoadingButton>
          </ButtonGroup>
        }
        data={sortInvoices(invoices)}
        columns={columns}
        loading={loading}
      />
    </Stack>
  )
}
