import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Loader } from '../../../components/loader/loader'
import { SideActions } from '../../../components/material/SideActions'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTaxes } from '../useTaxes'
import { TaxesDto } from '../../../models/Taxes'
import { TaxDetails } from './TaxDetails'

export const TaxContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const navigation = useNavigate()
  const { createTax, taxLoader, updateTax, tax } = useTaxes(id)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<TaxesDto>({
    values: { ...tax } as TaxesDto,
    reValidateMode: 'onChange',
    resetOptions: {
      keepDirtyValues: true // keep dirty fields unchanged, but update defaultValues
    }
  })

  useEffect(() => {
    reset({ ...tax })
  }, [reset, tax])

  function onSubmit(data: any) {
    if (data.id) updateTax(data)
    else createTax(data)
  }

  if (taxLoader) return <Loader isFullscreen type={'circles'} />

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={9}>
          <TaxDetails control={control} tax={tax} errors={errors} />
        </Grid>
        <Grid xs={3}>
          <SideActions
            isTransparent
            elevation={0}
            buttons={[
              <Button
                key={0}
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                {t('buttons.update')}
              </Button>,
              <Button
                key={1}
                variant='outlined'
                fullWidth
                color='primary'
                onClick={() => navigation(-1)}
              >
                {t('buttons.back')}
              </Button>
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
