import Grid from '@mui/material/Unstable_Grid2'
import { ISearchProps } from '../usePaginatedCustomer'
import { usePaginatedCustomers } from '../usePaginatedCustomer'
import { useEffect, useState } from 'react'
import { CustomerTable } from './CustomerTable'
import { CustomerSearch } from './CustomerSearch'

export const defaultReq: ISearchProps = {
  page: 1,
  size: 10,
  search: 'all',
  field: 'all'
}

export const CustomerTableContainer = () => {
  const { searchCustomers, customersState, removeEntity } = usePaginatedCustomers()

  const [filters, setFilters] = useState<ISearchProps>(defaultReq)

  const onPaginationChange = (e: any, t: any) => {
    const _filters = {
      ...filters,
      page: e.page + 1,
      size: e.pageSize
    }
    setFilters(_filters)
  }

  const handleEnter = (searchValue: string, searchProp: string) => {
    setFilters({ ...filters, field: searchProp, search: searchValue })
  }

  useEffect(() => {
    const getCustomers = async () => {
      searchCustomers(filters)
    }
    getCustomers()
  }, [filters, searchCustomers])

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <CustomerSearch handleEnter={handleEnter} />
      </Grid>
      <Grid xs={12}>
        <CustomerTable
          onPaginationModelChange={onPaginationChange}
          pageState={customersState}
          removeEntity={removeEntity}
        />
      </Grid>
    </Grid>
  )
}
