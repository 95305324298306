import { RemoveCircleOutline } from '@mui/icons-material'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton
} from '@mui/material'
import { t } from 'i18next'
import { ProductLightDto } from '../../../models/product/Product'

export interface IProductLightTableViewProps {
  data: ProductLightDto[]
  remove?: any
}

export const ProductLightTableView = ({ data, remove = null }: IProductLightTableViewProps) => {
  return data?.length > 0 ? (
    <TableContainer>
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>{t('labels.code')}</TableCell>
            <TableCell align='right'>{t('labels.stalid')}</TableCell>
            <TableCell align='right'>{t('labels.description')}</TableCell>
            <TableCell align='right'>{t('labels.type')}</TableCell>
            <TableCell align='right'>{t('labels.manufacturer')}</TableCell>
            <TableCell width={60}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any, index: number) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                <Button variant='text' href={`/manager/products/${row.id}`}>
                  {row.code}
                </Button>
              </TableCell>
              <TableCell align='right'>{row.stalId}</TableCell>
              <TableCell align='right'>{row.description}</TableCell>
              <TableCell align='right'>{row.productTypeGr}</TableCell>
              <TableCell align='right'>{row.manufacturerName}</TableCell>
              <TableCell>
                {remove && (
                  <IconButton color='error' onClick={() => remove(index)}>
                    <RemoveCircleOutline />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null
}
