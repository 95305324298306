import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { WarehouseDto } from '../../models/Warehouse'
import { useNavigate } from 'react-router-dom'

export interface IWarehouseProps {
  warehouseLoader: boolean
  warehouse?: WarehouseDto
  createWarehouse: any
  updateWarehouse: any
  deleteWarehouse: (id: string) => Promise<boolean>
}

export function useWarehouse(id?: string): IWarehouseProps {
  const [warehouseLoader, setLoading] = useState(false)
  const [warehouse, setWarehouse] = useState<WarehouseDto>()
  const service = useBaseService('warehouse')
  const navigate = useNavigate()

  const updateWarehouse = useCallback(
    async (warehouse: WarehouseDto) => {
      setLoading(true)
      const response = await service.update<WarehouseDto>(warehouse, warehouse.id)
      if (response) setWarehouse(response)
      setLoading(false)
    },
    [service]
  )

  const deleteWarehouse = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await service.remove(id)
      setLoading(false)
      return response === true
    },
    [service]
  )

  useEffect(() => {
    if (!id || id === 'new') {
      setWarehouse({
        name: '',
        description: '',
        address: '',
        floor: '',
        wareHouseType: 0
      } as any)
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<WarehouseDto>(id)
      if (response) setWarehouse(response)
      setLoading(false)
    }
    getById(id)
  }, [id, service, setLoading])

  const createWarehouse = useCallback(
    async (warehouse: WarehouseDto) => {
      setLoading(true)
      const response = await service.create(warehouse)
      if (response) {
        setWarehouse(response)
        navigate(`/manager/settings/warehouses/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    warehouseLoader,
    warehouse,
    updateWarehouse,
    createWarehouse,
    deleteWarehouse
  }
}
