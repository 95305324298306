import { Stack, TextField } from '@mui/material'
import { InvoiceEditDto } from '../../../../../models/invoice/InvoiceSale'
import { useTranslation } from 'react-i18next'

interface IInvoiceAadeDetailsProps {
  invoice: InvoiceEditDto | undefined
}

export const InvoiceAadeDetails = ({ invoice }: IInvoiceAadeDetailsProps) => {
  const { t } = useTranslation()
  return (
    <Stack
      padding={'1rem'}
      direction='column'
      justifyContent='space-between'
      alignItems='start'
      spacing={2}
    >
      <TextField
        disabled={true}
        fullWidth
        size='small'
        value={invoice?.aadeMark}
        label={t('labels.aadeMark')}
      />
      <TextField
        disabled={true}
        fullWidth
        size='small'
        value={invoice?.aadeQrUrl}
        label={t('labels.aadeQrUrl')}
      />
      <TextField
        disabled={true}
        fullWidth
        size='small'
        value={invoice?.aadeUid}
        label={t('labels.aadeUid')}
      />
    </Stack>
  )
}
