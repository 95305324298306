export const CopyToClipboardButton = async (text: string, notify?: any) => {
  try {
    await navigator.clipboard.writeText(text)
    if (notify) {
      notify('Copied!', {
        variant: 'info',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        autoHideDuration: 1000
      })
    }
  } catch (error) {
    console.error(error)
  }
}
