import { useParams } from 'react-router-dom'
import { useProductView } from '../useProductView'
import Grid from '@mui/material/Unstable_Grid2'
import { ProductOverviewDetails } from './ProductOverviewDetails'
import { Loader } from '../../../components/loader/loader'
import { Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { ProductOverviewAvailability } from './ProductOverviewAvailability'
import { ProductOverviewPriceTrend } from './PriceTrend/ProductOverviewPriceTrend'

export const ProductOverviewContainer = () => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState(0)
  const { stalId } = useParams<{ stalId: string }>()
  const { product, productLoader } = useProductView(stalId as string)
  if (product == null || productLoader) return <Loader type='circles' />

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <ProductOverviewDetails product={product} />
      </Grid>
      <Grid xs={12}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab value={0} label={t('labels.availability')}></Tab>
          <Tab value={1} label={t('labels.priceTrend')} />
        </Tabs>

        {tabValue === 0 && <ProductOverviewAvailability product={product} />}
        {tabValue === 1 && <ProductOverviewPriceTrend stalId={stalId as string} />}
      </Grid>
    </Grid>
  )
}
