import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Button, ButtonGroup, IconButton } from '@mui/material'
import { BorderColor, DeleteOutline } from '@mui/icons-material'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useManufacturer } from '../useManufacturer'
import { useManufacturers } from '../useManufacturers'
import { ManufacturerTableDto } from '../../../models/Manufacturer'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'

export const ManufacturerTable = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loading, manufacturers, removeManufacturer } = useManufacturers()
  const { manufacturerLoader = loading, deleteManufacturer } = useManufacturer()
  const [selectedManufacturer, setSelectedManufacturer] = useState<ManufacturerTableDto | null>(
    null
  )
  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Περιγραφη',
      field: 'description',
      flex: 1
    },
    {
      headerName: 'Site',
      field: 'site',
      flex: 1
    },
    {
      headerName: 'Ενέργειες',
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => navigate(params.row.id)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
          <IconButton color='error' onClick={() => openDialog(params.row)}>
            <DeleteOutline fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]

  const openDialog = (warehouse: ManufacturerTableDto) => {
    setSelectedManufacturer(warehouse)
  }

  const confirmDeletion = async () => {
    var success = await deleteManufacturer(selectedManufacturer?.id as string)
    if (success) {
      removeManufacturer(selectedManufacturer?.id as string)
      setSelectedManufacturer(null)
    }
  }

  return (
    <>
      <ConfirmationModal
        open={selectedManufacturer != null}
        title={t('messages.deleteEntity', {
          entityName: selectedManufacturer?.name
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setSelectedManufacturer(null)}
        onConfirm={confirmDeletion}
        loading={manufacturerLoader}
      />
      <MiDatagrid
        title={t('labels.manufacturers')}
        actions={
          <Button color={'primary'} size='small' variant='outlined' onClick={() => navigate('new')}>
            {t('buttons.add')}
          </Button>
        }
        data={manufacturers}
        columns={columns}
        loading={loading}
      />
    </>
  )
}
