import { Button, Stack } from '@mui/material'
import { useState } from 'react'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import { LoadingButton } from '@mui/lab'

export const ProductImageUpload = ({ id, uploadImage }: any) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0])
  }
  const handleUpload = async () => {
    setLoading(true)
    await uploadImage(selectedFile as any as Blob, id)
    setLoading(false)
    setSelectedFile(null)
  }
  return (
    <Stack direction={'row'} width={'100%'}>
      <Button fullWidth variant='outlined' component='label'>
        Select Img
        <input type='file' hidden onChange={handleFileChange} />
      </Button>

      <LoadingButton
        fullWidth
        loading={loading}
        color='primary'
        style={{ marginLeft: 20 }}
        variant='outlined'
        onClick={handleUpload}
        disabled={selectedFile == null}
      >
        <DriveFolderUploadIcon />
      </LoadingButton>
    </Stack>
  )
}
