import { FormControl, InputLabel, OutlinedInput, Paper, Typography } from '@mui/material'
import { mb } from '../../../../utils/cssExtensions'
import Grid from '@mui/material/Unstable_Grid2'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import { LookupSelect } from '../../../components/lookupSelect/LookupSelect'
import { Controller, useForm } from 'react-hook-form'
import { IProductFilters } from '../../../models/product/Product'
export interface IProductSearchProps {
  handleEnter: any
  isMinimal?: boolean
}

export const ProductTableFilters = ({ handleEnter }: IProductSearchProps) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<IProductFilters>({
    defaultValues: {} as IProductFilters,
    reValidateMode: 'onChange'
  })

  const onEnterPushed = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit((data) => handleEnter(data))()
    }
  }

  return (
    <Paper elevation={1} {...mb('20px')}>
      <Grid container gap={0}>
        <Grid xs={12} padding={2}>
          <Typography variant='h5'>{t('labels.search')}</Typography>
        </Grid>
        <Grid xs={4} padding={2} paddingTop={0}>
          <Controller
            control={control}
            defaultValue=''
            name='code'
            rules={{ required: false }}
            render={({ field: { ref, ...field } }) => (
              <FormControl fullWidth variant='outlined'>
                <OutlinedInput
                  inputRef={ref}
                  {...field}
                  type={'text'}
                  size='small'
                  onKeyDown={onEnterPushed}
                  startAdornment={<SearchIcon />}
                  label={t('messages.searchProduct')}
                />
                <InputLabel>{t('messages.searchProduct')}</InputLabel>
              </FormControl>
            )}
          />
        </Grid>
        <Grid xs={4} padding={2} paddingTop={0}>
          <Controller
            control={control}
            defaultValue=''
            name='description'
            rules={{ required: false }}
            render={({ field: { ref, ...field } }) => (
              <FormControl fullWidth variant='outlined'>
                <OutlinedInput
                  inputRef={ref}
                  {...field}
                  size='small'
                  type={'text'}
                  onKeyDown={onEnterPushed}
                  startAdornment={<SearchIcon />}
                  label={t('labels.description')}
                />
                <InputLabel>{t('labels.description')}</InputLabel>
              </FormControl>
            )}
          />
        </Grid>
        <Grid xs={4} padding={2} paddingTop={0}>
          <LookupSelect
            control={control}
            path={'producttype'}
            lookupUrl={'lookup/view'}
            label={t('labels.productType')}
            name='productTypeId'
            errors={errors}
            defaultValue='0'
            additionalOption={{
              id: '0',
              valueEn: t('labels.all'),
              valueGr: t('labels.all')
            }}
          />
        </Grid>
        {/* <Box
          component='img'
          sx={{
            objectFit: 'cover',
            maxHeight: { xs: 80 },
            maxWidth: { xs: 80 }
          }}
          src={image}
        /> */}
      </Grid>
    </Paper>
  )
}
