import { useTranslation } from 'react-i18next'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Source } from '@mui/icons-material'
import { Button, ButtonGroup, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { InvoiceTableDto } from '../../../models/invoice/InvoiceTable'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import moment from 'moment'
import Link from '@mui/material/Link'
import { IPageState } from '../../../components/material/IMiPaginatedDataGridProps'

export interface IInvoiceTableProps {
  invoiceState: IPageState<InvoiceTableDto>
  loading: boolean
}

export const InvoiceTable = ({ invoiceState, loading }: IInvoiceTableProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.date') as string,
      field: 'invoiceDate',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.invoiceDate).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.invoiceType') as string,
      field: 'invoiceTypeEn',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return i18n.language === 'en-US' ? params.row.invoiceTypeEn : params.row.invoiceTypeGr
      }
    },
    {
      headerName: t('labels.invoiceNumber2') as string,
      field: 'protocolNumber',
      flex: 0
    },
    {
      headerName: t('labels.fullname') as string,
      field: 'fullname',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link href={`/manager/customers/overview/${params.row.customerId}`} underline='hover'>
            {params.row.fullname}
          </Link>
        )
      }
    },
    {
      headerName: t('labels.mobile') as string,
      field: 'contactNumber',
      flex: 1
    },
    {
      headerName: t('labels.taxId') as string,
      field: 'taxId',
      flex: 0
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 80,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small'>
          <IconButton
            color='primary'
            onClick={() => navigate(`/manager/invoices/sales/${params.row.id}`)}
          >
            <Source fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]
  return (
    <MiDatagrid
      title={t('labels.invoices')}
      actions={
        <Button
          color={'primary'}
          size='small'
          variant='outlined'
          onClick={() => navigate('/manager/invoices/sales/new')}
        >
          {t('buttons.add')}
        </Button>
      }
      data={invoiceState.data}
      columns={columns}
      loading={loading}
    />
  )
}
