import { useForm } from 'react-hook-form'
import { WarehouseDto } from '../../../models/Warehouse'
import { useWarehouse } from '../useWarehouse'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Loader } from '../../../components/loader/loader'
import { WarehouseDetails } from './WarehouseDetails'
import { SideActions } from '../../../components/material/SideActions'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'

export const WarehouseContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const { warehouseLoader, createWarehouse, updateWarehouse, warehouse, deleteWarehouse } =
    useWarehouse(id)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<WarehouseDto>({
    values: { ...warehouse } as WarehouseDto,
    reValidateMode: 'onChange',
    resetOptions: {
      keepDirtyValues: true // keep dirty fields unchanged, but update defaultValues
    }
  })
  useEffect(() => {
    reset({ ...warehouse })
  }, [reset, warehouse])

  async function handleRemove() {
    if (warehouse?.id) {
      setConfirmationModal(true)
    }
  }

  const confirmDeletion = async () => {
    var success = await deleteWarehouse(warehouse?.id as string)
    if (success) {
      navigation('/manager/warehouses')
    }
  }
  function onSubmit(data: any) {
    if (data.id) updateWarehouse(data)
    else createWarehouse(data)
  }

  if (warehouseLoader) return <Loader isFullscreen type={'circles'} />

  return (
    <>
      <ConfirmationModal
        open={confirmationModal}
        title={t('messages.deleteEntity', {
          entityName: warehouse?.name
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setConfirmationModal(false)}
        onConfirm={confirmDeletion}
      />
      <Grid container spacing={3}>
        <Grid xs={9}>
          <WarehouseDetails control={control} warehouse={warehouse} errors={errors} />
        </Grid>
        <Grid xs={3}>
          <SideActions
            isTransparent
            elevation={0}
            buttons={[
              <Button
                key={0}
                fullWidth
                variant='contained'
                color='primary'
                onClick={handleSubmit(onSubmit)}
              >
                {t('buttons.update')}
              </Button>,
              <Button
                key={1}
                variant='outlined'
                fullWidth
                color='primary'
                onClick={() => navigation('/manager/settings/warehouses')}
              >
                {t('buttons.back')}
              </Button>,
              <Button key={2} fullWidth variant='contained' color='error' onClick={handleRemove}>
                {t('buttons.remove')}
              </Button>
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
}
