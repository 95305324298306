import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Button, ButtonGroup, IconButton } from '@mui/material'
import { BorderColor, DeleteOutline } from '@mui/icons-material'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'
import { useData } from '../../../services/useData'

import moment from 'moment'
import { useSupplier } from '../useSupplier'
import { SupplierDto } from '../../../models/Supplier'

export const SupplierTable = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loading, data, removeEntity } = useData<SupplierDto>('supplier')
  const { deleteSupplier, supplierLoader } = useSupplier()
  const [selectedEntity, setSelectedEntity] = useState<SupplierDto | null>(null)
  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.createdAt') as string,
      field: 'created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.created).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.name') as string,
      field: 'name',
      flex: 1
    },
    {
      headerName: t('labels.taxId') as string,
      field: 'taxId',
      flex: 1
    },
    {
      headerName: t('labels.contanctPerson') as string,
      field: 'contanctPerson',
      flex: 1
    },
    {
      headerName: t('labels.taxValue') as string,
      field: 'taxValue',
      flex: 1
    },
    {
      headerName: t('labels.country') as string,
      field: 'country',
      flex: 1
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => navigate(params.row.id)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
          <IconButton color='error' onClick={() => openDialog(params.row)}>
            <DeleteOutline fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]

  const openDialog = (entity: SupplierDto) => {
    setSelectedEntity(entity)
  }

  const confirmDeletion = async () => {
    var success = await deleteSupplier(selectedEntity?.id as string)
    if (success) {
      removeEntity(selectedEntity?.id as string)
      setSelectedEntity(null)
    }
  }

  return (
    <>
      <ConfirmationModal
        open={selectedEntity != null}
        title={t('messages.deleteEntity', {
          entityName: selectedEntity?.name
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setSelectedEntity(null)}
        onConfirm={confirmDeletion}
        loading={supplierLoader}
      />
      <MiDatagrid
        title={t('labels.suppliers')}
        actions={
          <Button color={'primary'} size='small' variant='outlined' onClick={() => navigate('new')}>
            {t('buttons.add')}
          </Button>
        }
        data={data}
        columns={columns}
        loading={loading}
      />
    </>
  )
}
