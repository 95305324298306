import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ProductTableDto } from '../../../models/product/Product'
import { useProduct } from '../useProduct'
import { useProducts } from '../useProducts'
import { ConfirmationModal } from '../../../components/modals/ConfirmationModal'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { BorderColor, DeleteOutline, Source } from '@mui/icons-material'
import { ButtonGroup, IconButton } from '@mui/material'
import { ProductTableFilters } from './ProductTableFilters'

export const ProductTable = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { loading, products, removeProduct, searchWithFilters } = useProducts()
  const { productLoader, deleteProduct } = useProduct()
  const [selectedEntity, setSelectedEntity] = useState<ProductTableDto | null>(null)

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.code') as string,
      field: 'code',
      flex: 1
    },
    {
      headerName: t('labels.stalid') as string,
      field: 'stalId',
      flex: 1
    },
    {
      headerName: t('labels.description') as string,
      field: 'descriptionEn',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div>{i18n.language === 'en' ? params.row.descriptionEn : params.row.descriptionGr}</div>
      )
    },
    {
      headerName: t('labels.type') as string,
      field: 'productTypeEn',
      flex: 1
    },
    {
      headerName: t('labels.price') as string,
      field: 'initialPrice',
      flex: 1
    },
    {
      headerName: t('labels.piece') as string,
      field: 'totalItems',
      flex: 1
    },
    {
      headerName: t('labels.manufacturer') as string,
      field: 'manufacturerName',
      flex: 1
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='primary' onClick={() => navigate(`overview/${params.row.stalId}`)}>
            <Source fontSize={'small'} />
          </IconButton>
          <IconButton color='default' onClick={() => navigate(params.row.id)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
          <IconButton color='error' onClick={() => openDialog(params.row)}>
            <DeleteOutline fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]

  const openDialog = (entity: ProductTableDto) => {
    setSelectedEntity(entity)
  }

  const confirmDeletion = async () => {
    var success = await deleteProduct(selectedEntity?.id as string)
    if (success) {
      removeProduct(selectedEntity?.id as string)
      setSelectedEntity(null)
    }
  }

  return (
    <>
      <ConfirmationModal
        open={selectedEntity != null}
        title={t('messages.deleteEntity', {
          entityName: selectedEntity?.stalId
        })}
        description={t('messages.deleteEntityVerification')}
        onCancel={() => setSelectedEntity(null)}
        onConfirm={confirmDeletion}
        loading={productLoader}
      />
      <ProductTableFilters handleEnter={searchWithFilters} />
      <MiDatagrid
        title={t('labels.searchResult')}
        data={products}
        columns={columns}
        loading={loading}
      />
    </>
  )
}
