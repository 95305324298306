import { useEffect, useState } from 'react'
import { useBaseService } from './useBaseService'

export interface ISimpleLookup {
  id: string
  valueEn: string
  valueGr: string
}
export interface IUseLookUp<T> {
  loading: boolean
  lookup: T[]
}

export function useLookup<T>(path: string, url: string): IUseLookUp<T> {
  const [loading, setLoading] = useState(true)
  const [lookup, setLookup] = useState<T[]>([])
  const service = useBaseService(path)

  useEffect(() => {
    async function fetchLookup() {
      setLoading(true)

      const response = await service.getLookupView<T>(url)
      if (response) setLookup(response ?? [])
      setLoading(false)
    }
    if (service) fetchLookup()
  }, [service, url])

  return {
    loading,
    lookup
  }
}
