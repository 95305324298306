import { Control, Controller, FieldErrors } from 'react-hook-form'
import { Paper, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { PaperHeader } from '../../../components/paperHeader/PaperHeader'
import { fieldProps } from '../../../../utils/FormExtensions'
import { TaxesDto } from '../../../models/Taxes'

export interface ITaxDetailsProps {
  tax?: TaxesDto
  errors: FieldErrors<TaxesDto>
  control: Control<TaxesDto, any>
}
export const TaxDetails = ({ control, tax, errors }: ITaxDetailsProps) => {
  const { t } = useTranslation()
  const taxesFields: Array<keyof TaxesDto> = ['name', 'amount']
  const taxesFieldsRequired = ['name', 'amount']
  return (
    <Paper elevation={1}>
      <Stack padding={2}>
        <PaperHeader
          label={tax?.id ? t('labels.editRecord') : t('labels.newRecord')}
          rightChild={
            tax?.id ? (
              <Typography variant='body2' color={'grey'}>
                {t('labels.createdAt')}
                {moment(tax?.created).format('DD/MM/YYYY , HH:mm A')}
              </Typography>
            ) : null
          }
        />
        {taxesFields.map((fieldName: keyof TaxesDto) => (
          <Controller
            key={fieldName}
            control={control}
            defaultValue=''
            name={fieldName}
            rules={{ required: taxesFieldsRequired.includes(fieldName) }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...fieldProps(t(`labels.${fieldName}`), ref, field)}
                error={!!errors[fieldName]}
              />
            )}
          />
        ))}
      </Stack>
    </Paper>
  )
}
