import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import { InvoiceEditDto } from '../../models/invoice/InvoiceSale'
import dayjs from 'dayjs'

export interface IInvoiceProps {
  invoiceLoader: boolean
  invoice?: InvoiceEditDto
  createInvoice: any
  disableForm: boolean
}

export function useInvoice(id?: string): IInvoiceProps {
  const [invoiceLoader, setLoading] = useState(false)
  const [invoice, setInvoice] = useState<InvoiceEditDto>()
  const [disableForm, setDisableForm] = useState<boolean>(true)
  const service = useBaseService('invoice')
  const navigate = useNavigate()

  useEffect(() => {
    if (!id || id === 'new') {
      setInvoice({
        invoiceDate: dayjs(Date.now()),
        taxApplied: 24,
        paymentMethod: 3,
        locationFrom: 'Λ. ΔΗΜΟΚΡΑΤΙΑΣ 212, ΑΧΑΡΝΑΙ',
        purpose: 'ΠΩΛΗΣΗ',
        items: []
      } as any)
      setDisableForm(false)
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<InvoiceEditDto>(id)
      if (response) {
        setInvoice(response)
        setDisableForm(true)
      } else {
        navigate(`/manager`)
      }
      setLoading(false)
    }
    getById(id)
  }, [id, navigate, service, setLoading])

  const createInvoice = useCallback(
    async (entity: InvoiceEditDto) => {
      setLoading(true)
      const response = await service.create(entity)
      if (response) {
        setInvoice(response)
        navigate(`/manager/invoices/sales/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    invoiceLoader,
    disableForm,
    invoice,
    createInvoice
  }
}
