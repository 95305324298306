import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Unstable_Grid2'
import { Link } from 'react-router-dom'
import { commonColors } from '../../../utils/useManagerTheme'
interface ISettingItem {
  icon: string
  title: string
  description: string[]
  path: string
}

const settingsItems: ISettingItem[] = [
  {
    icon: 'money.png',
    title: 'labels.taxes',
    description: ['descr.availableInfoTable', 'labels.taxes'],
    path: '/manager/settings/taxes'
  },
  {
    icon: 'manufacture.png',
    title: 'labels.manufacturers',
    description: ['descr.availableInfoTable', 'labels.manufacturers'],
    path: '/manager/settings/manufacturers'
  },
  {
    icon: 'supplier.png',
    title: 'labels.suppliers',
    description: ['descr.availableInfoTable', 'labels.suppliers'],
    path: '/manager/settings/suppliers'
  },
  {
    icon: 'warehouse.png',
    title: 'labels.warehouses',
    description: ['descr.availableInfoTable', 'labels.warehouses'],
    path: '/manager/settings/warehouses'
  },
  {
    icon: 'starter.png',
    title: 'labels.productKeys',
    description: ['descr.availableInfoTable', 'labels.productKeys'],
    path: '/manager/settings/productKeys'
  },
  {
    icon: 'dynamo.png',
    title: 'labels.productTypes',
    description: ['descr.availableInfoTable', 'labels.productTypes'],
    path: '/manager/settings/productTypes'
  },
  {
    icon: 'protocol.png',
    title: 'labels.protocol',
    description: ['descr.availableInfoTable', 'labels.protocol'],
    path: '/manager/settings/protocol'
  },
  {
    icon: 'profitMargin.png',
    title: 'labels.priceSuggestions',
    description: ['descr.availableInfoTable', 'labels.priceSuggestions'],
    path: '/manager/settings/profitability'
  },
  {
    icon: 'aade.png',
    title: 'labels.aade',
    description: ['descr.availableInfoTable', 'labels.aade'],
    path: '/manager/settings/aade'
  }
]

const SettingItemCard = ({ item }: { item: ISettingItem }) => {
  const { t } = useTranslation()
  return (
    <Card sx={{ display: 'flex', height: '120px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component='div' variant='h5'>
            {t(item.title)}
          </Typography>

          <Link to={item.path} style={{ textDecoration: 'none' }}>
            <Typography
              variant='subtitle1'
              style={{ color: commonColors.statusChipsMessagesBlueNeutral }}
              fontWeight={500}
              component='div'
            >
              {t(item.description[0], { propName: t(item.description[1]) })}
            </Typography>
          </Link>
        </CardContent>
      </Box>
      <CardMedia
        component='img'
        sx={{
          width: 80,
          objectFit: 'contain',
          paddingRight: 2,
          background: 'linear-gradient(-50deg, #1d4464 0%, #1d4464 50%, transparent 25%)'
        }}
        image={require(`../../../images/settingsIcons/${item.icon}`)}
        alt='Set your Taxes'
      />
    </Card>
  )
}

export const SettingCards = () => {
  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {settingsItems.map((item, i: number) => (
          <Grid key={i} xs={4}>
            <SettingItemCard key={i} item={item} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
