import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../services/useBaseService'
import { useNavigate } from 'react-router-dom'
import { ManufacturerDto } from '../../models/Manufacturer'

export interface IManufacturerProps {
  manufacturerLoader: boolean
  manufacturer?: ManufacturerDto
  createManufacturer: any
  updateManufacturer: any
  deleteManufacturer: (id: string) => Promise<boolean>
}

export function useManufacturer(id?: string): IManufacturerProps {
  const [manufacturerLoader, setLoading] = useState(false)
  const [manufacturer, setManufacturer] = useState<ManufacturerDto>()
  const service = useBaseService('manufacturer')
  const navigate = useNavigate()

  const updateManufacturer = useCallback(
    async (manufacturer: ManufacturerDto) => {
      setLoading(true)
      const response = await service.update<ManufacturerDto>(manufacturer, manufacturer.id)
      if (response) setManufacturer(response)
      setLoading(false)
    },
    [service]
  )

  const deleteManufacturer = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await service.remove(id)
      setLoading(false)
      return response === true
    },
    [service]
  )

  useEffect(() => {
    if (!id || id === 'new') {
      setManufacturer(new ManufacturerDto())
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<ManufacturerDto>(id)
      if (response) setManufacturer(response)
      setLoading(false)
    }
    getById(id)
  }, [id, service, setLoading])

  const createManufacturer = useCallback(
    async (manufacturer: ManufacturerDto) => {
      setLoading(true)
      const response = await service.create(manufacturer)
      if (response) {
        setManufacturer(response)
        navigate(`/manager/settings/manufacturers/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service]
  )

  return {
    manufacturerLoader,
    manufacturer,
    updateManufacturer,
    createManufacturer,
    deleteManufacturer
  }
}
