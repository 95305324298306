import { useTranslation } from 'react-i18next'
import { useData } from '../../services/useData'
import { MiDatagrid } from '../../components/material/MiDatagrid'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import moment from 'moment'
import { Chip, IconButton } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'

interface IXmlRecordItem {
  id: string
  aadeMark: string
  invoiceId: string
  transactionId: string
  type: string
  created: Date
}

export const XmlInvoicesContainer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loading, data } = useData<IXmlRecordItem>('invoice', 'xml')

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.createdAt') as string,
      field: 'created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.created).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.name') as string,
      field: 'customer',
      flex: 1
    },
    {
      headerName: t('labels.invoice') as string,
      field: 'invoiceType',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.invoiceId ? (
          <Link
            href={`/manager/invoices/sales/${params.row.invoiceId}`}
            color={'blue'}
            underline='hover'
          >
            {params.row.invoiceType}
          </Link>
        ) : (
          params.row.invoiceType
        )
    },
    {
      headerName: t('labels.aadeMark') as string,
      field: 'aadeMark',
      flex: 1
    },
    {
      headerName: t('labels.code') as string,
      field: 'transactionId'
    },
    {
      headerName: t('labels.type') as string,
      field: 'type',
      renderCell: (params: GridRenderCellParams) =>
        params.row.type === 0 ? (
          <Chip variant='outlined' color='info' label='Request' />
        ) : (
          <Chip variant='outlined' color='secondary' label='Response' />
        )
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          color='default'
          onClick={() => navigate(`/manager/settings/aade/${params.row.id}`)}
        >
          <LaunchIcon fontSize={'small'} />
        </IconButton>
      )
    }
  ]
  return <MiDatagrid title={t('labels.aade')} data={data} columns={columns} loading={loading} />
}
