import { makeStyles } from 'tss-react/mui'
import { sidebarItems, profileItems } from './SidebarItems'
import { Fragment, useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Drawer,
  List,
  ListItem,
  Avatar,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  Typography,
  Stack
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { commonColors } from '../../../utils/useManagerTheme'
import { useTranslation } from 'react-i18next'
import { useToken } from '../../services/auth/useToken'

const drawerWidth = 240
const useStyles = makeStyles()((theme) => {
  return {
    drawerPaper: {
      position: 'relative',
      width: drawerWidth,
      height: 'calc(100vh)',
      border: 'none',
      background: commonColors.greysWhite,
      borderRadius: 0,
      borderRight: '1px solid #1d446426'
    },
    avatarRoot: {
      textAlign: 'center',
      background: commonColors.bluesPrimaryBlue,
      color: commonColors.white,
      borderBottomRightRadius: '140px',
      borderBottomLeftRadius: '0px',
      boxShadow: '0px 5px 5px 0px #1d446478',
      paddingBottom: '60px',
      paddingTop: '20px'
    },
    avatarContainer: {
      textAlign: 'center',
      color: commonColors.white
    },
    logoUl: {
      padding: 0,
      height: '64px',
      background: commonColors.bluesPrimaryBlue
    },
    logoLi: {
      height: '64px',
      justifyContent: 'center'
    }
  }
})

export const ManagerSidebar = () => {
  const [itemsOpened, setOpen] = useState<number[]>([])
  const { t } = useTranslation()
  const [profileOpened, setProfileOpened] = useState(false)
  const navigate = useNavigate()
  const userInfo = useToken()?.getUserInfo()
  const handleClick = (index: number) => {
    if (itemsOpened.some((x) => x === index)) {
      setOpen(itemsOpened.filter((x) => x !== index))
    } else {
      setOpen([...itemsOpened, index])
    }
  }
  const { classes } = useStyles()
  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper
      }}
      variant='permanent'
      anchor='left'
      hideBackdrop={true}
      open={true}
    >
      <List className={classes.logoUl}>
        <ListItem className={classes.logoLi} key={'logo'}>
          <Typography color={'whitesmoke'} variant='h5'>
            Stal
          </Typography>
          <Typography variant='h5' color='orange' marginTop={0.4}>
            _parts
          </Typography>
        </ListItem>
      </List>
      <div className={classes.avatarRoot}>
        <div className={classes.avatarContainer}>
          <Avatar
            sx={{ width: 64, height: 64, margin: '0 auto 10px' }}
            alt='Remy Sharp'
            src={require('../../../images/avatar.jpg')}
          />
          <Stack
            direction={'row'}
            style={{ cursor: 'pointer' }}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={() => setProfileOpened(!profileOpened)}
          >
            <Typography variant='subtitle1'>{userInfo?.given_name}</Typography>
            {profileOpened ? <ExpandLess /> : <ExpandMore />}
          </Stack>

          <Typography variant='subtitle2' color={'#cbcbcb'}>
            {userInfo?.email}
          </Typography>
        </div>
        <List style={{ padding: '0 20px', marginTop: '20px' }}>
          {profileItems.map((item, index) => (
            <Collapse key={index} in={profileOpened} timeout='auto' unmountOnExit>
              <ListItem key={index} disablePadding>
                <ListItemButton
                  style={{ paddingTop: 0, paddingBottom: '10px' }}
                  onClick={() => navigate(item.path)}
                >
                  {item.icon && <ListItemIcon color='white'>{item.icon}</ListItemIcon>}
                  <ListItemText primary={t(item.label)} />
                </ListItemButton>
              </ListItem>
            </Collapse>
          ))}
        </List>
      </div>

      <List>
        {sidebarItems.map((item, index) => (
          <Fragment key={index}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleClick(index)}>
                <ListItemText
                  primaryTypographyProps={{ fontWeight: 700 }}
                  primary={t(item.label)}
                />
                {itemsOpened.some((x) => x === index) ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            {item.subMenu.map((subItem, subIndex) => (
              <Collapse
                key={subIndex}
                in={itemsOpened.some((x) => x === index)}
                timeout='auto'
                unmountOnExit
              >
                <List key={subIndex} component='div' disablePadding>
                  <ListItem key={subIndex} disablePadding>
                    <ListItemButton onClick={() => navigate(subItem.path)}>
                      {subItem.icon && <ListItemIcon>{subItem.icon}</ListItemIcon>}
                      <ListItemText primary={t(subItem.label)} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
            ))}
          </Fragment>
        ))}
      </List>
    </Drawer>
  )
}
