import { useState } from 'react'
import { ProductCategoryPriceSuggestion } from '../../models/product/ProductCategoryPriceSuggestions'
import { ProductProfitSuggerstionsTable } from './ProductProfitSuggerstionsTable'
import { ProductProfitSuggerstionsModal } from './ProductProfitSuggerstionsModal'
import { BorderColor } from '@mui/icons-material'
import { ButtonGroup, IconButton } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

import { useTranslation } from 'react-i18next'
import moment from 'moment'

export const ProductProfitSuggestionsContainer = () => {
  const [selectedEntity, setSelectedEntity] = useState<ProductCategoryPriceSuggestion | null>(null)
  const { t, i18n } = useTranslation()
  const onConfirm = () => {
    setSelectedEntity(null)
  }

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.createdAt') as string,
      field: 'created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.created).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.description') as string,
      field: 'description',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        i18n.language === 'en' ? params.row.productTypeNameEn : params.row.productTypeNameGr
    },
    {
      headerName: t('labels.profitMargin') as string,
      field: 'profitMarginToBeAdded',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => `${params.row.profitMarginToBeAdded} %`
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => setSelectedEntity(params.row)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]
  return (
    <>
      {selectedEntity && (
        <ProductProfitSuggerstionsModal
          entity={selectedEntity as ProductCategoryPriceSuggestion}
          onConfirm={() => onConfirm()}
          onCancel={() => setSelectedEntity(null)}
        />
      )}
      <ProductProfitSuggerstionsTable columns={columns} setSelectedEntity={setSelectedEntity} />
    </>
  )
}
