import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { ISimpleLookup, useLookup } from '../../services/useLookup'
import { commonColors } from '../../../utils/useManagerTheme'
import { useTranslation } from 'react-i18next'

export interface ILookupSelectProps {
  errors: FieldErrors<any>
  lookupUrl: string
  control: Control<any, any>
  label: string
  path: string
  name: string
  margin?: string
  flex?: string
  disabled?: boolean
  defaultValue?: string
  additionalOption?: ISimpleLookup
}

const sortLookup = (data: ISimpleLookup[], lang: string): ISimpleLookup[] => {
  if (lang === 'en') return data.sort((a, b) => a.valueEn.localeCompare(b.valueEn))
  return data.sort((a, b) => a.valueGr.localeCompare(b.valueGr))
}

export const LookupSelect: React.FC<ILookupSelectProps> = ({
  control,
  errors,
  path,
  name,
  margin = 'none',
  label,
  lookupUrl,
  flex = 'unset',
  defaultValue = '',
  disabled = false,
  additionalOption = undefined
}: any) => {
  const { loading, lookup } = useLookup<ISimpleLookup>(path, lookupUrl)
  const { i18n } = useTranslation()
  let data = sortLookup(lookup, i18n.language)
  if (additionalOption) {
    data = [additionalOption, ...lookup]
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{ required: true }}
      render={({ field }) => (
        <FormControl size='small' style={{ flex: flex }} fullWidth margin={margin}>
          <InputLabel id={label + '_id'}>{label}</InputLabel>
          {data && data.length > 0 && (
            <Select
              {...field}
              fullWidth
              error={!!errors[name]}
              label={label}
              disabled={loading || disabled}
            >
              {data.map((l, index) => (
                <MenuItem key={index} value={l.id}>
                  {i18n.language === 'en' ? l.valueEn : l.valueGr}
                </MenuItem>
              ))}
            </Select>
          )}
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: commonColors.bluesPrimaryBlue,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px'
              }}
            />
          )}
        </FormControl>
      )}
    />
  )
}
