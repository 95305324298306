import axios from 'axios'
import { useSnackbar } from 'notistack'
import { BaseResult } from '../models/BaseResult'
import { useCallback, useMemo } from 'react'
import { useConfig } from './useConfig'

export const useBaseService = (controller: string) => {
  const { config } = useConfig()

  const { enqueueSnackbar } = useSnackbar()

  const hasErrors = useCallback(
    (errors: string[]) => {
      if (errors != null && errors.length > 0) {
        enqueueSnackbar(errors[0], {
          variant: 'error',
          autoHideDuration: 5000
        })
        return true
      }
      return false
    },
    [enqueueSnackbar]
  )

  const catchError = useCallback(
    (error: any) => {
      console.debug(error)
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 5000
      })
    },
    [enqueueSnackbar]
  )

  const getAll = useCallback(
    async <T>(urlToOverride?: string) => {
      try {
        const _url = urlToOverride ? `${controller}/${urlToOverride}` : controller
        const response = await axios.get<BaseResult<T[]>>(`${config.apiUrl}${_url}`)
        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (e: any) {
        catchError(e)
      }
    },
    [catchError, config.apiUrl, hasErrors, controller]
  )

  const getLookupView = useCallback(
    async <T>(url: string) => {
      try {
        const response = await axios.get<BaseResult<T[]>>(`${config.apiUrl}${controller}/${url}`)
        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (e: any) {
        catchError(e)
      }
    },
    [catchError, config.apiUrl, hasErrors, controller]
  )

  const getById = useCallback(
    async <T>(id: string, urlToOverride?: string) => {
      const _url = urlToOverride ? `${controller}/${urlToOverride}/${id}` : `${controller}/${id}`
      try {
        const response = await axios.get<BaseResult<T>>(`${config.apiUrl}${_url}`)
        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (e: any) {
        catchError(e)
      }
    },
    [catchError, config.apiUrl, hasErrors, controller]
  )

  const remove = useCallback(
    async (id: string, urlToOverride?: string) => {
      const _url = urlToOverride ? urlToOverride : `${controller}/${id}`
      try {
        const response = await axios.delete<BaseResult<boolean>>(`${config.apiUrl}${_url}`)
        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (e: any) {
        catchError(e)
      }
    },
    [catchError, config.apiUrl, hasErrors, controller]
  )

  const create = useCallback(
    async <T>(entity: T, endpoint: string = '') => {
      try {
        const response = await axios.post<BaseResult<T>>(
          `${config.apiUrl}${controller}${endpoint}`,
          entity
        )
        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (e: any) {
        catchError(e)
      }
    },
    [catchError, config.apiUrl, hasErrors, controller]
  )

  const post = useCallback(
    async <T>(entity: any, endpoint: string) => {
      try {
        const response = await axios.post<BaseResult<T>>(
          `${config.apiUrl}${controller}${endpoint}`,
          entity
        )
        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (e: any) {
        catchError(e)
        return null
      }
    },
    [catchError, config.apiUrl, hasErrors, controller]
  )

  const update = useCallback(
    async <T>(entity: T, id: string) => {
      try {
        const response = await axios.put<BaseResult<T>>(
          `${config.apiUrl}${controller}/${id}`,
          entity
        )
        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (e: any) {
        catchError(e)
      }
    },
    [catchError, config.apiUrl, hasErrors, controller]
  )

  return useMemo(() => {
    return {
      getAll,
      getById,
      remove,
      create,
      update,
      post,
      getLookupView
    }
  }, [getAll, getById, remove, post, create, update, getLookupView])
}
