const isGuid = (guid: string) => {
  const guidRegex = /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/
  return guidRegex.test(guid)
}

export interface IUseValidator {
  isGuid: (guid: string) => boolean
}
export const useValidator = (): IUseValidator => {
  return {
    isGuid
  }
}

export function generateGuid() {
  var result, i, j
  result = ''
  for (j = 0; j < 32; j++) {
    if (j === 8 || j === 12 || j === 16 || j === 20) result = result + '-'
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase()
    result = result + i
  }
  return result
}
