const development = {
  apiUrl: 'https://localhost:7116/',
  tokenPrefix: 'stal_dev_'
}
const production = {
  apiUrl: 'http://nkperperidis-001-site1.ftempurl.com/',
  tokenPrefix: 'stal_production_'
}

interface IUseConfig {
  config: any
  isDevelopment: boolean
}

export function useConfig(): IUseConfig {
  let config
  if (process.env.NODE_ENV === 'production') {
    config = production
  } else {
    config = development
  }

  return {
    isDevelopment: process.env.NODE_ENV === 'development',
    config
  }
}
