import { Button, Container, Typography, Box, ThemeProvider } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useBaseTheme } from '../utils/useManagerTheme'

const NotFoundPage = () => {
  const navigation = useNavigate()
  const { t } = useTranslation()
  const theme = useBaseTheme()
  return (
    <ThemeProvider theme={theme}>
      <Container
        component='main'
        maxWidth='sm'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <Box
          marginTop={3}
          sx={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <Box>
            <Typography variant='h1' color={'primary'}>
              404
            </Typography>
          </Box>
          <Typography variant='h5' color={'darkgray'} marginTop={5} marginBottom={10}>
            {t('messages.notFoundMsg')} 🚗🛠️
          </Typography>
          <Button
            variant='contained'
            color='primary'
            startIcon={<ArrowBackIcon />}
            onClick={() => navigation('/manager')}
            sx={{ textTransform: 'none' }}
          >
            {t('buttons.notFoundButton')}
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
export default NotFoundPage
