import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { CardMedia, IconButton, ImageList, ImageListItem, Stack } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useEffect, useState } from 'react'
import { ProductImageDto } from '../../models/product/ProductImage'

interface ICarouselProps {
  images: any[]
  height?: number
}

const useStyles = makeStyles()(() => {
  return {
    root: {
      border: '1px solid #d9d9d9',
      width: '100px',
      height: '80px!important',
      userSelect: 'none'
    }
  }
})

export const Carousel = ({ images, height = 250 }: ICarouselProps) => {
  const { classes } = useStyles()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedId, setSelectedId] = useState()
  const [startIndex, setStartIndex] = useState(0)

  const handleNext = () => {
    setSelectedIndex(selectedIndex + 1)
    if (selectedIndex === startIndex + 2 && startIndex + 3 < images?.length) {
      setStartIndex((prevIndex) => prevIndex + 1)
    }
  }

  useEffect(() => {
    setSelectedId(images[selectedIndex]?.id)
  }, [images, selectedIndex])

  const handlePrevious = () => {
    setSelectedIndex(selectedIndex - 1)
    if (selectedIndex === startIndex && startIndex - 1 >= 0) {
      setStartIndex((prevIndex) => prevIndex - 1)
    }
  }
  if (images == null || images.length === 0) return null
  return (
    <Stack>
      <CardMedia
        component='img'
        width={'auto'}
        height={height + 'px'}
        style={{ objectFit: 'scale-down', userSelect: 'none' }}
        image={`data:image/jpeg;base64,${images[selectedIndex].imageData}`}
        alt='Paella dish'
      />
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <Stack>
          <IconButton color='primary' onClick={handlePrevious} disabled={selectedIndex === 0}>
            <ArrowBackIos fontSize={'small'} />
          </IconButton>
        </Stack>
        <Stack flex={0}>
          <ImageList
            sx={{
              overflow: 'hidden',
              padding: '0 1rem',
              gridAutoFlow: 'column',
              gridTemplateColumns: 'repeat(auto-fill,minmax(100px,1fr)) !important',
              gridAutoColumns: 'minmax(100px, 1fr)'
            }}
          >
            {images.slice(startIndex, startIndex + 3).map((item: ProductImageDto) => (
              <ImageListItem key={item.imageData} style={{ userSelect: 'none' }}>
                <img
                  src={`data:image/jpeg;base64,${item.imageData}`}
                  alt={'noimage'}
                  className={item.id === selectedId ? classes.root : ''}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Stack>

        <Stack>
          <IconButton
            color='primary'
            onClick={handleNext}
            disabled={selectedIndex === images.length - 1}
          >
            <ArrowForwardIos fontSize={'small'} />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  )
}
