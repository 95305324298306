import { InvoicePurchaseItem } from '../../../../../models/invoice/InvoicePurchase'

export function getTotalNoTax(items: InvoicePurchaseItem[]): number {
  return items?.reduce((a, b) => a + b.price * b.quantity, 0) ?? 0
}

export function getTotalTaxAmount(items: InvoicePurchaseItem[]): number {
  let total = 0
  items?.forEach((item) => {
    total += (item.price * item.quantity * item.taxValue) / 100
  })
  return total ?? 0
}
