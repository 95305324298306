import { useEffect, useState } from 'react'
import { useBaseService } from './useBaseService'

export interface IUseData<T> {
  loading: boolean
  data: T[]
  url?: string
  removeEntity: (id: string) => void
}

export function useData<T extends { id?: string }>(path: string, url?: string): IUseData<T> {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<T[]>([])
  const service = useBaseService(path)

  const removeEntity = (id: string) => setData(data?.filter((x) => x.id !== id))

  useEffect(() => {
    if (!service) return

    async function fetchData() {
      setLoading(true)
      const _url = url ? `${url}` : undefined
      const response = await service.getAll<T>(_url)
      if (response) setData(response)
      setLoading(false)
    }

    fetchData()
  }, [path, service, url])

  return {
    loading,
    data,
    removeEntity
  }
}
