import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Button, ButtonGroup, IconButton } from '@mui/material'
import { BorderColor } from '@mui/icons-material'
import { MiDatagrid } from '../../../components/material/MiDatagrid'
import { useTranslation } from 'react-i18next'
import { useData } from '../../../services/useData'
import moment from 'moment'
import { TaxesDto } from '../../../models/Taxes'

export const TaxesTable = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loading, data } = useData<TaxesDto>('tax')
  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.createdAt') as string,
      field: 'created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.created).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.name') as string,
      field: 'name',
      flex: 1
    },
    {
      headerName: t('labels.contactValue') as string,
      field: 'amount',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => `${params.row.amount} %`
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => navigate(params.row.id)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]

  return (
    <>
      <MiDatagrid
        title={t('labels.taxes')}
        actions={
          <Button color={'primary'} size='small' variant='outlined' onClick={() => navigate('new')}>
            {t('buttons.add')}
          </Button>
        }
        data={data}
        columns={columns}
        loading={loading}
      />
    </>
  )
}
