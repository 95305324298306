import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useTranslation } from 'react-i18next'
import { SearchCompatibleProducts } from './SearchCompatibleProducts'
import { useProducts } from '../useProducts'
import { ProductLightDto, ProductTableDto } from '../../../models/product/Product'
import { useEffect, useState } from 'react'
import { Add, RemoveCircleOutline } from '@mui/icons-material'
import { mr } from '../../../../utils/cssExtensions'

export interface IProductPartModalProps {
  onConfirm: any
  onCancel: any
}
export const ProductPartModal = ({ onConfirm, onCancel }: IProductPartModalProps) => {
  const { t } = useTranslation()
  const { awaitSearchByCode } = useProducts()
  const [products, setProducts] = useState<ProductTableDto[]>([])
  const [selectedProducts, setSelectedProducts] = useState<ProductLightDto[]>([])
  const handleEnter = async (code: string) => {
    var result = await awaitSearchByCode(code, 'table')
    setProducts(result as any)
  }
  useEffect(() => {
    setSelectedProducts((prev) => [
      ...prev,
      ...products.filter((x) => !prev.some((s) => s.id === x.id)).map((x) => new ProductLightDto(x))
    ])
  }, [products])

  const addProduct = (part: ProductLightDto) => {
    if (selectedProducts.findIndex((x) => x.id === part.id && x.selected) === -1) {
      part.isSelected()
      setSelectedProducts((prev) => [part, ...prev.filter((x) => x.id !== part.id)])
    }
  }

  const removeProduct = (part: ProductLightDto) => {
    if (selectedProducts.findIndex((x) => x.id === part.id && x.selected) > -1) {
      part.isUnSelected()
      setSelectedProducts((prev) => [...prev.filter((x) => x.id !== part.id), part])
    }
  }

  return (
    <Dialog open={true} fullWidth maxWidth='md'>
      <DialogTitle id='alert-dialog-title'>{t('labels.suitableFor')}</DialogTitle>
      <DialogContent>
        <SearchCompatibleProducts handleEnter={handleEnter} />
        <TableContainer>
          <Table size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>{t('labels.code')}</TableCell>
                <TableCell align='right'>{t('labels.stalid')}</TableCell>
                <TableCell align='right'>{t('labels.type')}</TableCell>
                <TableCell align='right'>{t('labels.manufacturer')}</TableCell>
                <TableCell width={60}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts?.map((row: any) => (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    {row.selected && (
                      <Chip
                        {...mr('1rem')}
                        icon={<CheckCircleIcon color='success' />}
                        label='Selected'
                      />
                    )}
                    {row.code}
                  </TableCell>
                  <TableCell align='right'>{row.stalId}</TableCell>
                  <TableCell align='right'>{row.productTypeGr}</TableCell>
                  <TableCell align='right'>{row.manufacturerName}</TableCell>
                  <TableCell>
                    {row.selected ? (
                      <IconButton color='error' onClick={() => removeProduct(row)}>
                        <RemoveCircleOutline />
                      </IconButton>
                    ) : (
                      <IconButton color='primary' onClick={() => addProduct(row)}>
                        <Add />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        <Button
          color='success'
          onClick={() => onConfirm(selectedProducts.filter((s) => s.selected))}
          autoFocus
        >
          {t('buttons.insert')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
